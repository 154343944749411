import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { IDocumentSearchResultItem } from '../shared/models/document-search-result-item';
import { Subscription } from 'rxjs';
import { IUser } from '../../../shared/models/user';
import { AuthService } from '../../../shared/services/auth.service';
import { UserUtility } from '../../../shared/utilities/user-utility';
import { appConstants } from '../../../shared/constants/app-constants';
import { SearchResultLayout } from '../shared/constants/search-results-layout';


@Component({
  selector: 'app-document-search-result-card',
  templateUrl: './document-search-result-card.component.html',
  styleUrl: './document-search-result-card.component.scss'
})
export class DocumentSearchResultCardComponent implements OnChanges, OnDestroy {
  @Input() searchResultItem: IDocumentSearchResultItem | null = null;
  @Input() index: number | null = null;
  @Input() layout: SearchResultLayout = SearchResultLayout.Grid;
  
  readonly searchResultLayoutEnum = SearchResultLayout;
  documentType: string = '';
  documentStatus: string = '';
  isDownloadButtonVisible: boolean = false;
  subscriptions: Subscription = new Subscription();
  currentUser: IUser | null = null;

  constructor(private _authService: AuthService) { 
    const userSubscription = this._authService.currentUserSubject.subscribe(user => {
      this.currentUser = user;
      this.setValues();
    });
    this.subscriptions.add(userSubscription);
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  ngOnChanges(changes : SimpleChanges): void {
    if (changes['searchResultItem']) {
      const newItem = changes['searchResultItem'].currentValue;
      this.searchResultItem = newItem;
      this.setValues();
    }
  }

  setValues() {
    this.documentType = this.searchResultItem?.docType ? this.searchResultItem.docType : '';
    this.documentStatus = this.searchResultItem?.status ? this.searchResultItem.status : '';

    this.isDownloadButtonVisible = this.canUserDownloadDocument(this.currentUser, this.searchResultItem);
  }

  canUserDownloadDocument(user: IUser | null, resultItem: IDocumentSearchResultItem | null) : boolean{
    if (!user || !resultItem) {
      return false;
    }
    
    const isTwPmPgmAdmin = UserUtility.isTwPmPgmAdmin(user);
    const isParliamentarianUser = UserUtility.hasRoles(user, appConstants.userRole.parliamentaryMemberAccess);
    const isCommitteeMemberOnCommittee = 
      UserUtility.isCommitteeMember(user) &&
      UserUtility.getCommitteeCodes(user).includes(resultItem.committee);
    
    return isTwPmPgmAdmin || isParliamentarianUser || isCommitteeMemberOnCommittee;
  }
  
  onDownloadClick(e: MouseEvent) {
    e.stopPropagation();
    const uri = this.extractIdFromUri(this.searchResultItem as IDocumentSearchResultItem);
    console.log(`Download button clicked. uri: {uri}`);
  }

  extractIdFromUri(item: IDocumentSearchResultItem): string {
    const xmlName = item.uri.split('/').pop();
    const id = xmlName?.split('.').shift();

    return id ? id : '';
  }
}
