import { Component, Input, OnDestroy } from '@angular/core';
import { IDocumentSearchResultItem } from '../shared/models/document-search-result-item';
import { SearchResultLayout } from '../shared/constants/search-results-layout';
import { IDocumentSearchResult } from '../shared/models/document-search-result';

@Component({
  selector: 'app-document-search-results',
  templateUrl: './document-search-results.component.html',
  styleUrl: './document-search-results.component.scss'
})
export class DocumentSearchResultsComponent {

  readonly searchResultLayoutEnum = SearchResultLayout;

  @Input() searchResults: IDocumentSearchResult | null = null;
  @Input() loading: boolean = true;

  searchResultLayout: SearchResultLayout = SearchResultLayout.List;
  
  constructor() { }

}
