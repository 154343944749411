import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { appConstants } from '../../../../shared/constants/app-constants';
import { IIecBallotDetails } from '../../shared/models/iec-ballot-details';
import { IIecBallotVote } from '../../shared/models/iec-ballot-vote';
import { IBallotReportVoteResultsBreakdownPercentage } from '../../shared/models/reports/ballot-report';
import { IecBallotReport } from '../../shared/models/reports/iec-ballot-report';

@Component({
  selector: 'app-iec-report',
  templateUrl: './iec-report.component.html'
})
export class IecReportComponent implements OnChanges {
  @Input() ballot: IIecBallotDetails | undefined = undefined;

  ballotReport: IecBallotReport | undefined = undefined;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['ballot'] && changes['ballot'].currentValue && this.ballot) {

      this.ballotReport = new IecBallotReport();
      this.ballotReport.designation = this.ballot.ballotSummary.designation;
      this.ballotReport.documentHeader = `IEC Ballot - ${this.ballotReport.designation}`;
      this.ballotReport.documentFilename = `${this.ballotReport.designation} IEC Ballot Report.pdf`;
      this.ballotReport.title = this.ballot.ballotSummary.title;
      this.ballotReport.committee = this.ballot.ballotSummary.committee;
      this.ballotReport.projectManager = this.ballot.ballotSummary.projectManagerName;
      this.ballotReport.associatedDocuments = this.ballot.documents.map(x => x.metadata.filename);
      this.ballotReport.startDate = this.ballot.ballotSummary.startDate;
      this.ballotReport.endDate = this.ballot.ballotSummary.endDate;
      this.ballotReport.status = this.ballot.ballotSummary.statusDisplayName;
      this.ballotReport.notes = this.ballot.ballotSummary.notes;

      this.setBallotResultBreakdown();
      this.setCommitteeVotes();
      this.setQuestions();
    }
  }

  mapVotesToBallotReportVoteResultsBreakdownPercentages(votes: IIecBallotVote[]): IBallotReportVoteResultsBreakdownPercentage[] {
    let agree = votes.filter(x => x.iecVoteOptionName === appConstants.iecBallots.voteOptions.inFavourOf.name);
    let disgree = votes.filter(x => x.iecVoteOptionName === appConstants.iecBallots.voteOptions.against.name);
    let abstain = votes.filter(x => x.iecVoteOptionName === appConstants.iecBallots.voteOptions.abstain.name);
    let noResponse = votes.filter(x => x.iecVoteOptionName === appConstants.iecBallots.voteOptions.noResponse.name);

    return [
      {
        label: appConstants.iecBallots.voteOptions.inFavourOf.displayName,
        fillColor: appConstants.iecBallots.voteOptions.inFavourOf.color,
        value: agree.length,
        percentage: (agree.length / votes.length) * 100
      },
      {
        label: appConstants.iecBallots.voteOptions.against.displayName,
        fillColor: appConstants.iecBallots.voteOptions.abstain.color,
        value: disgree.length,
        percentage: (disgree.length / votes.length) * 100
      },
      {
        label: appConstants.iecBallots.voteOptions.abstain.displayName,
        fillColor: appConstants.iecBallots.voteOptions.abstain.color,
        value: abstain.length,
        percentage: (abstain.length / votes.length) * 100
      },
      {
        label: appConstants.iecBallots.voteOptions.noResponse.displayName,
        fillColor: appConstants.iecBallots.voteOptions.noResponse.color,
        value: noResponse.length,
        percentage: (noResponse.length / votes.length) * 100
      }
    ];
  }

  setBallotResultBreakdown(): void {
    let eligibleVoters = this.ballot?.iecBallotVotes.filter((vote) => vote.isEligible);

    if (!this.ballotReport || !eligibleVoters) return;

    this.ballotReport.voteResultBreakdown = [
      {
        country: 'Overall',
        totalNumberOfVotes: eligibleVoters.length,
        votePercentages: this.mapVotesToBallotReportVoteResultsBreakdownPercentages(eligibleVoters)
      }
    ];
  }

  setCommitteeVotes(): void {
    if (!this.ballot || !this.ballotReport) return;

    this.ballotReport.committeeVotes = this.ballot.iecBallotVotes.map(vote => ({
      committeeMember: vote.committeeMember,
      organisation: vote.organisation,
      role: vote.role,
      country: vote.country,
      votingRights: vote.votingRights,
      answers: [
        vote.iecVoteOptionDisplayName
      ]
    }));
  }

  setQuestions(): void {
    if (!this.ballot || !this.ballotReport) return;

    const voteOptions = Object.keys(appConstants.iecBallots.voteOptions);

    this.ballotReport.questions = [
      {
        questionNumber: 'Q1',
        question: 'What action should be taken with this standard?',
        answerOptions: voteOptions.map(x => (appConstants.iecBallots.voteOptions as any)[x].displayName),
        comments: this.ballot.iecBallotVotes
          .filter((vote) => vote.isEligible)
          .map(x => ({
            commenter: x.committeeMember,
            comment: x.remarks
          })) ?? undefined
      }
    ];
  }
}
