import { Injectable } from '@angular/core';
import { IDocumentSearchResult } from '../models/document-search-result';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { IDocumentSearchRequest } from '../models/document-search-request';
import { map, Observable } from 'rxjs';
import { IApiResponse } from '../../../../shared/models/api-response';
import { convertStringDateToJSDate } from '../../../../shared/utilities/date-utility';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  baseUrl: string = `${environment.servicesUrl.document}api/document`;
  
  constructor(
    private http: HttpClient
  ) { }

  public documentSearch(request : IDocumentSearchRequest) : Observable<IApiResponse<IDocumentSearchResult>> {
    const searchResult = this.http.post<IApiResponse<IDocumentSearchResult>>(`${this.baseUrl}/document-search`, request)
    .pipe(
      map((rawResponse) => {
        let response = { ...rawResponse };
        if (rawResponse.success && rawResponse.content) {
          response.content.documents.forEach(d => {
            d.pubDate = convertStringDateToJSDate(d.pubDate as any as string) as any as Date;
          });
        }

        return response;
      })
    );

    return searchResult;
  }
}
