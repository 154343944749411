import { Component } from '@angular/core';
import { getCurrentDateForAustraliaSydney } from '../../../shared/utilities/date-utility';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
  year = getCurrentDateForAustraliaSydney().getFullYear();
}
