import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BallotRoutingModule } from './ballot-routing.module';
import { BallotsComponent } from './ballots.component';
import { IecDetailsComponent } from './iec/iec-details/iec-details.component';
import { IecComponent } from './iec/iec.component';
import { IecBallotService } from './shared/services/iec-ballot.service';
import { GenerateReportButtonComponent } from './generate-report-button/generate-report-button.component';
import { IecReportComponent } from './iec/iec-report/iec-report.component';



@NgModule({
  declarations: [
    BallotsComponent,
    IecComponent,
    IecDetailsComponent,
    GenerateReportButtonComponent,
    IecReportComponent,
  ],
  imports: [
    CommonModule,
    BallotRoutingModule
  ],
  providers: [
    IecBallotService
  ]
})
export class BallotsModule { }
