import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommitteesComponent } from './committees.component';
import { authGuard } from '../../shared/guards/auth.guard';
import { CommitteeDetailsComponent } from './committee-details/committee-details.component';

const routes: Routes = [
  {
    path: 'committees',
    component: CommitteesComponent,
    canActivate: [authGuard]
  },
  {
    path: 'committees/:code',
    component: CommitteeDetailsComponent,
    canActivate: [authGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CommitteesRoutingModule { }
