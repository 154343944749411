<div class="rounded-lg bg-white shadow-cardShadow h-36 p-3 card-size min-w-80">
    <div class="flex flex-col justify-between">
        <div class="flex justify-between items-center pb-3">
            <ng-content select="[headerLeft]"></ng-content>
            <ng-content select="[headerRight]"></ng-content>
        </div>
        <div class="text-brand-darkBlue">
            <h6 [title]="title" class="font-semibold text-ellipsis whitespace-nowrap overflow-hidden mb-1" name="title">
              {{title}}
            </h6>
        </div>
        <p [title]="description" class="overflow-hidden max-h-14 pt-2" name="description">
            {{description}}
        </p>
    </div>
</div>
