import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SaLibraryComponent } from './sa-library.component';
import { MatIconModule } from '@angular/material/icon';
import { SaLibraryRoutingModule } from './sa-library-routing.module';
import { DocumentSearchResultsComponent } from './document-search-results/document-search-results.component';
import { DocumentSearchResultCardComponent } from './document-search-result-card/document-search-result-card.component';
import { DocumentDetailsComponent } from './document-details/document-details.component';
import { ControlsModule } from '../controls/controls.module';
import { DocumentTypeBadgeComponent } from './document-type-badge/document-type-badge.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';



@NgModule({
  declarations: [
    SaLibraryComponent,
    DocumentSearchResultsComponent,
    DocumentSearchResultCardComponent,
    DocumentDetailsComponent,
    DocumentTypeBadgeComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    FontAwesomeModule,
    SaLibraryRoutingModule,
    ControlsModule
  ]
})
export class SaLibraryModule { }
