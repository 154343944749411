import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { DashboardRoutingModule } from './dashboard-routing.module';

import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { LinksGridComponent } from './shared/components/links-grid/links-grid.component';
import { ConnectLinksComponent } from './connect-links/connect-links.component';
import { ReportsDashboardComponent } from './reports/reports-dashboard/reports-dashboard.component';
import { GuidesAndVideosComponent } from './guides-and-videos/guides-and-videos.component';
import { ReportsEmbededComponent } from './reports/reports-embeded/reports-embeded.component';
import { ReportsItemComponent } from './reports/reports-item/reports-item.component';
import { ProjectsModule } from "../projects/projects.module";

@NgModule({
  declarations: [
    DashboardComponent,
    ConnectLinksComponent,
    ReportsDashboardComponent,
    GuidesAndVideosComponent,
    ReportsEmbededComponent,
    ReportsItemComponent,
    LinksGridComponent
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    MatSlideToggleModule,
    ProjectsModule
]
})
export class DashboardModule { }
