import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { appConstants } from '../../../../shared/constants/app-constants';
import { IProjectDetails } from '../../shared/models/project-details';

interface ITimelinePhase {
  phase: string;
  startDate?: Date;
  endDate?: Date;
  statusForCompletion?: string[];
  statusForCurrent?: string[];

  completed?: boolean;
  currentStep?: boolean;
}

@Component({
  selector: 'app-project-details-timeline',
  templateUrl: './project-details-timeline.component.html',
  styleUrl: './project-details-timeline.component.scss'
})
export class ProjectDetailsTimelineComponent implements OnChanges {

  @Input() projectDetails: IProjectDetails | null = null;

  phases: ITimelinePhase[] = [
    {
      phase: "Project Approval",
      completed: true
    },
    {
      phase: "Project Setup",
      completed: true
    },
    {
      phase: "Drafting",
      statusForCompletion: [
        appConstants.publicationStatus.commenting,
        appConstants.publicationStatus.commentingAndBalloting,
        appConstants.publicationStatus.commentingCompleted,
        appConstants.publicationStatus.balloting,
        appConstants.publicationStatus.ballotingCompleted,
        appConstants.publicationStatus.published
      ],
      statusForCurrent: [
        appConstants.publicationStatus.drafting
      ]
    },
    {
      phase: "Public Comment",
      statusForCompletion: [
        appConstants.publicationStatus.balloting,
        appConstants.publicationStatus.ballotingCompleted,
        appConstants.publicationStatus.published
      ],
      statusForCurrent: [
        appConstants.publicationStatus.commenting,
        appConstants.publicationStatus.commentingAndBalloting,
        appConstants.publicationStatus.commentingCompleted
      ]
    },
    {
      phase: "Ballot",
      statusForCompletion: [
        appConstants.publicationStatus.published
      ],
      statusForCurrent: [
        appConstants.publicationStatus.balloting,
        appConstants.publicationStatus.commentingAndBalloting,
        appConstants.publicationStatus.ballotingCompleted
      ]
    },
    {
      phase: "Publication",
      statusForCompletion: [
        appConstants.publicationStatus.published
      ]
    }
  ];

  ngOnChanges(changes: SimpleChanges): void {
    const projectDetails = changes['projectDetails'].currentValue;
    if (projectDetails) {
      this.phases
        .forEach((phase, index) => {
          phase.completed = (!phase.statusForCompletion || phase.statusForCompletion.length === 0) 
            || phase.statusForCompletion.findIndex(x => x === projectDetails.phaseId) >= 0;

          phase.currentStep = phase.completed ? 
                              false : 
                              (!phase.statusForCurrent || phase.statusForCurrent.length === 0 ? false : phase.statusForCurrent.findIndex(x => x === projectDetails.phaseId) >= 0);
        });
    }
  }
}
