<div class="bg-grey-200 page-padding-y pb-6">
  <ng-container *ngIf="projectDetails && !loading">
    <!-- breadcrumbs... -->

    <div class="page-padding-x mb-3 w-1/2">
      <h5 id="h5-designation" class="text-darkBlue-500">{{ projectDetails.designation }}</h5>
      <h5 id="h5-title">{{ projectDetails.projectTitle }}</h5>
    </div>

    <div class="page-padding-x pt-12">
      <app-project-details-timeline
        [projectDetails]="projectDetails"
      ></app-project-details-timeline>
    </div>
  </ng-container>
</div>

<div class="flex-1 pt-6 bg-grey-100 page-padding-x page-padding-b">
  <ng-container *ngIf="!loading">
    <ng-container *ngIf="projectDetails">
      <app-tab
        #appTab
        [tabs]="[
          { id: 'overview', label: 'Overview' },
          { id: 'proposalDetails', label: 'Proposal Details' }
        ]"
        id="projectsTab"
        (onTabChange)="onTabChange($event)"
      >
      </app-tab>

      <div [class.hidden]="selectedTab !== 'overview'">
        <app-project-details-overview
          [projectDetails]="projectDetails"
        ></app-project-details-overview>
      </div>
      <div [class.hidden]="selectedTab !== 'proposalDetails'"></div>
    </ng-container>
    <ng-container *ngIf="!projectDetails">
      <div class="flex justify-center items-center h-full">
        <p>Unable to get project details</p>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="loading">
    <div class="flex justify-center items-center h-full">
      <app-loading-indicator [size]="32"></app-loading-indicator>
    </div>
  </ng-container>
</div>
