import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { IApiResponse } from '../../../../shared/models/api-response';
import { convertStringDateToJSDate } from '../../../../shared/utilities/date-utility';
import { IRecentlyViewedCommittee } from '../models/recently-viewed-committee';

@Injectable({
  providedIn: 'root'
})
export class CommitteeUserService {

  baseUrl: string = `${environment.servicesUrl.committee}api/user`;

  constructor(
    private http: HttpClient,
  ) { }

  getRecentlyViewedCommittees(): Observable<IApiResponse<IRecentlyViewedCommittee[]>> {
    return this.http
      .get<IApiResponse<IRecentlyViewedCommittee[]>>(`${this.baseUrl}/committees/view-histories`)
      .pipe(
        map((rawResponse) => {
          let response = { ...rawResponse };
          if (rawResponse.success && rawResponse.content) {
            response.content.forEach(p => {
              p.accessDate = convertStringDateToJSDate(p.accessDate as any as string) as any as Date;
            });
          }

          return response;
        })
      );
  }
}
