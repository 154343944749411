import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { IApiResponse } from '../../../../shared/models/api-response';
import { convertStringDateToJSDate } from '../../../../shared/utilities/date-utility';
import { IProjectPhase } from '../models/project-phase';
import { IProjectSearchFilter } from '../models/project-search-filter';
import { IProjectSearchResult } from '../models/project-search-result';
import { IRecentlyViewedProject } from '../models/recently-viewed-project';
import { IProjectDetails } from '../models/project-details';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  baseUrl: string = `${environment.servicesUrl.project}api/projects`;

  constructor(
    private http: HttpClient,
  ) { }

  getPhases(): Observable<IApiResponse<IProjectPhase[]>> {
    return this.http.get<IApiResponse<IProjectPhase[]>>(`${this.baseUrl}/phases`);
  }

  searchProjects(filter?: IProjectSearchFilter): Observable<IApiResponse<IProjectSearchResult[]>> {
    return this.http
      .post<IApiResponse<IProjectSearchResult[]>>(`${this.baseUrl}/search`, filter)
      .pipe(
        map((rawResponse) => {
          let response = { ...rawResponse };
          if (rawResponse.success && rawResponse.content) {
            response.content.forEach(p => {
              p.phaseEnd = convertStringDateToJSDate(p.phaseEnd as any as string);
            });
          }

          return response;
        })
      );
  }

  getRecentlyViewedProjects(): Observable<IApiResponse<IRecentlyViewedProject[]>> {
    return this.http
      .get<IApiResponse<IRecentlyViewedProject[]>>(`${this.baseUrl}/recently-viewed`)
      .pipe(
        map((rawResponse) => {
          let response = { ...rawResponse };
          if (rawResponse.success && rawResponse.content) {
            response.content.forEach(p => {
              p.accessDate = convertStringDateToJSDate(p.accessDate as any as string) as any as Date;
            });
          }

          return response;
        })
      );
  }

  getProjectDetails(projectId: number): Observable<IApiResponse<IProjectDetails>> {
    return this.http
      .get<IApiResponse<IProjectDetails>>(`${this.baseUrl}/${projectId}`)
      .pipe(
        map((rawResponse) => {
          let response = { ...rawResponse };
          if (rawResponse.success && rawResponse.content) {
            response.content.commentStartDate = convertStringDateToJSDate(response.content.commentStartDate as any as string);
            response.content.commentEndDate = convertStringDateToJSDate(response.content.commentEndDate as any as string);
            response.content.ballotStartDate = convertStringDateToJSDate(response.content.ballotStartDate as any as string);
            response.content.ballotEndDate = convertStringDateToJSDate(response.content.ballotEndDate as any as string);            
          }

          return response;
        })
      );
  }
}
