import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AuthorisationRoutingModule } from './authorisation-routing.module';
import { AuthorisationComponent } from './authorisation.component';



@NgModule({
  declarations: [
    AuthorisationComponent
  ],
  imports: [
    CommonModule,
    AuthorisationRoutingModule
  ]
})
export class AuthorisationModule { }
