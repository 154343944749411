export const iecBallotVoteOptions = {	
	against: {
		name: "AGAINST",
		displayName: "Against",
		color: "#2727df"
	},
	abstain: {
		name: "ABSTAIN",
		displayName: "Abstain",
		color: "#808080"
	},
	inFavourOf: {
		name: "IN_FAVOUR_OF",
		displayName: "In Favour Of",
		color: "#00c257"
	},
	noResponse: {
		name: "NO_RESPONSE",
		displayName: "No Response",
		color: "#fdb414"
	}
}