import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IOption } from '../../controls/models/option';

@Component({
  selector: 'app-select-multiple-project-status',
  templateUrl: './select-multiple-project-status.component.html',
  styleUrl: './select-multiple-project-status.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectMultipleProjectStatusComponent),
      multi: true
    }
  ]
})
export class SelectMultipleProjectStatusComponent implements ControlValueAccessor {

  @Input() id: string = 'select-project-status';
  @Input() label: string = 'Project Status';

  onChange: any = () => { };
  onTouched: any = () => { };

  private _values: any[] | undefined;
  get values() {
    return this._values;
  }
  set values(values) {
    this._values = values;
    this.onChange(values);
    this.onTouched();
  }

  options: IOption<string>[] = [
    { value: 'Active', label: 'Active' },
    { value: 'On hold', label: 'On hold' },
  ];

  constructor(
  ) {

  }

  writeValue(values: any[]): void {
    this.values = values;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
