import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { appConstants } from '../constants/app-constants';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor(
    private _authService: AuthService,
  ) {

  }

  canAccessNomOrgPage(): Observable<boolean> {
    return this._authService.hasRoles(
      appConstants.userRole.admin,
      appConstants.userRole.itSupport,
      appConstants.userRole.generalManagerOperations,
      appConstants.userRole.nominatingOrganisation
    );
  }

  canAccessStandardsLibraryPage(): Observable<boolean> {
    return this._authService.hasRoles(
      appConstants.userRole.committeeMember,
      appConstants.userRole.nominatingOrganisation,
      appConstants.userRole.parliamentaryMemberAccess
    );
  }

  canAccessBallotsPage(): Observable<boolean> {
    return this._authService.hasRoles(
      appConstants.userRole.admin,
      appConstants.userRole.committeeMember,
      appConstants.userRole.generalManagerOperations,
      appConstants.userRole.itSupport,
      appConstants.userRole.moderator,
      appConstants.userRole.programManager,
      appConstants.userRole.projectManager,
      appConstants.userRole.staff
    );
  }


}
