<div class="flex-grow flex flex-col justify-between gap-4 relative">
  <div *ngIf="!showComponent" class="absolute flex justify-center items-center h-full w-full bg-white">
    <app-loading-indicator [size]="32"></app-loading-indicator>
  </div>

  <div class="grid grid-cols-2 gap-4">
    <label for="comment-period-status" class="font-normal"
      >Comment Period Status &nbsp;&nbsp;
      <app-public-commenting-status-badge
        id="comment-period-status"
        [pcmsDraftStatus]="pcmsDraftStatus"
      ></app-public-commenting-status-badge
    ></label>
    <div class="flex flex-col">
      <label class="font-normal" for="comment-period-status-start">
        Commenting Start Date:
        <span id="comment-period-status-start">{{
          commentingStartDate | date : "dd/MM/yyyy"
        }}</span>
      </label>
      <label class="font-normal" for="comment-period-status-end">
        Commenting Finish Date:
        <span id="comment-period-status-end">{{
          commentingEndDate | date : "dd/MM/yyyy"
        }}</span>
      </label>
    </div>
  </div>

  <div class="flex-grow grid grid-cols-2 gap-4 border-b border-gray-200 py-4">
    <div class="flex flex-grow max-h-64" id="div-pcms-pieChart">
      <canvas id="chart-public-commenting" #chartpubliccommenting></canvas>
    </div>

    <div class="flex flex-col justify-center pr-4" id="div-pcms-legend">
      <div class="border-b border-gray-200 flex flex-row justify-between py-4">
        <label
          ><span [style.color]="editorialColour">&#9632;</span> Editorial</label
        >
        <label>{{ editorialCount }}</label>
      </div>
      <div class="border-b border-gray-200 flex flex-row justify-between py-4">
        <label
          ><span [style.color]="generalColour">&#9632;</span> General</label
        >
        <label>{{ generalCount }}</label>
      </div>
      <div class="flex flex-row justify-between py-4">
        <label
          ><span [style.color]="technicalColour">&#9632;</span> Technical</label
        >
        <label>{{ technicalCount }}</label>
      </div>
    </div>
  </div>

  <div *ngIf="isViewPublicCommentingButtonVisible" class="flex flex-row">
    <a
      id="a-view-in-public-commenting"
      class="btn btn-primary"
      href="{{ commentUrl }}"
      target="_blank"
    >
      View in Public Comment
    </a>
  </div>
</div>
