import { Component } from '@angular/core';
import { IReportItem } from '../shared/models/report-item';

@Component({
  selector: 'app-reports-dashboard',
  templateUrl: './reports-dashboard.component.html',
  styleUrl: './reports-dashboard.component.scss'
})
export class ReportsDashboardComponent {

  reports: IReportItem[] = [
    {
      id: 'report-committee',
      cardTitle: 'Committee Report',
      cardDescription: 'A high-level dashboard of Standards Development Portfolio',
      routerLink: '/report/0'
    },
    {
      id: 'report-relationship-between-standards',
      cardTitle: 'Relationship Between Standards Report',
      cardDescription: 'A high-level dashboard to identify inter-relationships between standards',
      routerLink: '/report/1'
    },
  ]

}
