import { Component, OnDestroy, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { faSearch, faX } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { appConstants } from '../../shared/constants/app-constants';
import { IUser } from '../../shared/models/user';
import { AuthService } from '../../shared/services/auth.service';
import { UserUtility } from '../../shared/utilities/user-utility';
import { IExportSchema } from '../controls/models/export-to-excel';
import { IOption } from '../controls/models/option';
import { ITableColumn, TableColumnType } from '../controls/table/table-column';
import { ITableFilter } from '../controls/table/table-filter';
import { TableComponent } from '../controls/table/table.component';
import { IProjectSearchResult } from './shared/models/project-search-result';
import { ProjectService } from './shared/services/project.service';
import { SelectMultipleCommitteeComponent } from '../committees/select-multiple-committee/select-multiple-committee.component';
import { ActivatedRoute } from '@angular/router';

interface IProjectsComponentTableData extends IProjectSearchResult {
  myProject: boolean;
}

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrl: './projects.component.scss',
})
export class ProjectsComponent implements OnDestroy {
  @ViewChild(TableComponent) tableComponent!: TableComponent<IProjectsComponentTableData>;
  @ViewChild('committeeCodesFilter') committeeCodesFilterComponent!: SelectMultipleCommitteeComponent;

  faX = faX;
  faSearch = faSearch;

  currentUser!: IUser;
  isInternalUser: boolean = false;
  allowExport: boolean = false;

  loading: boolean = true;
  subscriptions: Subscription = new Subscription();
  localStorageFilterKey = 'projects-filter';

  tableData: IProjectsComponentTableData[] = [];
  tableColumns: ITableColumn<IProjectsComponentTableData>[] = [
    {
      name: 'designation',
      displayName: 'Designation',
      type: TableColumnType.routerLink,
      isSortable: true,
      getLink: (row: IProjectsComponentTableData) => `/projects/${row.projectId}`,
      headerWidth: '250px'
    },
    {
      name: 'projectTitle',
      displayName: 'Project Title',
      isSortable: true,
      headerWidth: '35%'
    },
    {
      name: 'status',
      displayName: 'Project Status',
      isSortable: true
    },
    {
      name: 'committeeCode',
      displayName: 'Committee',
      type: TableColumnType.routerLink,
      isSortable: true,
      getLink: (row: IProjectsComponentTableData) => `/committees/${row.committeeCode}`
    },
    {
      name: 'phase',
      displayName: 'Phase',
      isSortable: true
    },
    {
      name: 'phaseEnd',
      displayName: 'Public Comment Closure Date',
      type: TableColumnType.date,
      isSortable: true,
      isHidden: true
    },
    {
      name: 'myProject',
      displayName: 'myProject',
      isHidden: true
    },
  ];
  tableFilters: ITableFilter[] = [];

  exportSchema: IExportSchema<IProjectsComponentTableData>[] = [
    {
      propertyName: 'designation',
      header: 'Designation',
    },
    {
      propertyName: 'projectTitle',
      header: 'Project Title'
    },
    {
      propertyName: 'status',
      header: 'Project Status'
    },
    {
      propertyName: 'committeeCode',
      header: 'Committee'
    },
    {
      propertyName: 'phase',
      header: 'Phase'
    },
  ];

  viewType = {
    allProjects: 'All Projects',
    myProjects: appConstants.projects.viewTypes.myProjects,
    inPublicComment: 'In Public Comment',
  };
  viewTypeOptions: IOption<any>[] = [];

  searchForm = new FormGroup({
    searchTerm: new FormControl(''),
    viewType: new FormControl(this.viewType.allProjects),

    committeeCodes: new FormControl<string[]>([]),
    phases: new FormControl<number[]>([]),
    projectStatuses: new FormControl<string[]>([]),
  });


  constructor(
    private _authService: AuthService,
    private _projectService: ProjectService,
    private _activatedRoute: ActivatedRoute
  ) {
    const userSubscription = this._authService.currentUserSubject.subscribe(user => {
      if (user) {
        this.currentUser = user;
        this.initialize();
      }
    });

    this.subscriptions.add(userSubscription);
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  initialize() {
    this.searchProjects();
    this.isInternalUser = UserUtility.isInternalUser(this.currentUser);

    const formValueChangesSubscription = this.searchForm.valueChanges.subscribe(change => {
      this.onFilterChangeEvent(change);
    });
    this.subscriptions.add(formValueChangesSubscription);

    this.initializeDefaultFilters();
    this.initializeViewTypeOptions();
    this.processQueryParams();
    this.initializeAllowExport();    
  }

  initializeDefaultFilters() {
    const savedFilters = localStorage.getItem(this.localStorageFilterKey);
    if (savedFilters) {
      const filters = JSON.parse(savedFilters);
      this.searchForm.patchValue(filters);
    }
    else {
      this.searchForm.patchValue({ viewType: this.getDefaultViewType() });
    }
  }

  initializeViewTypeOptions() {
    if (this.isInternalUser || UserUtility.hasRoles(this.currentUser, appConstants.userRole.committeeMember)) {
      this.viewTypeOptions = [
        { value: this.viewType.allProjects, label: this.viewType.allProjects },
        { value: this.viewType.myProjects, label: this.viewType.myProjects },
        { value: this.viewType.inPublicComment, label: this.viewType.inPublicComment }
      ];

      return;
    }

    this.viewTypeOptions = [
      { value: this.viewType.allProjects, label: this.viewType.allProjects },
      { value: this.viewType.inPublicComment, label: this.viewType.inPublicComment }
    ];
  }

  initializeAllowExport() {
    this.allowExport = !UserUtility.isMemberOfPublicOnly(this.currentUser);
  }

  processQueryParams() {
    const view = this._activatedRoute.snapshot.queryParamMap.get(appConstants.queryParams.view);
    if(view && this.viewTypeOptions.find(x => x.value === view)) {
      this.searchForm.patchValue({ viewType: view });
    }
  }

  searchProjects() {
    const searchProjects = this._projectService.searchProjects({ page: 1, limit: 100000 }).subscribe(response => {
      if (response.success) {
        const committeeCodes = UserUtility.getCommitteeCodes(this.currentUser);
        this.tableData = response.content.map(x => {
          return {
            ...x,
            myProject: committeeCodes.includes(x.committeeCode)
          };
        });
        this.loading = false;
      }
    });

    this.subscriptions.add(searchProjects);
  }

  onResetFilterClickEvent() {
    this.searchForm.patchValue({
      searchTerm: '',
      committeeCodes: [],
      phases: [],
      projectStatuses: [],
      viewType: this.getDefaultViewType()
    });

    this.committeeCodesFilterComponent?.selectMultipleComponent?.clearFilterText();
  }

  getDefaultViewType() {
    return this.isInternalUser || UserUtility.hasRoles(this.currentUser, appConstants.userRole.committeeMember)
      ? this.viewType.myProjects
      : this.viewType.allProjects;
  }

  onFilterChangeEvent(values: typeof this.searchForm.value) {
    if (!values) {
      return;
    }

    const filters: ITableFilter[] = [];

    if (values.viewType === this.viewType.myProjects) {
      filters.push({
        properties: ['myProject'],
        value: true
      });

      filters.push({
        properties: ['isTechnicalCommittee'],
        value: true
      });
    }
    
    if (values.committeeCodes && values.committeeCodes.length > 0) {
      filters.push({
        properties: ['committeeCode'],
        value: values.committeeCodes
      });
    }

    if (values.viewType === this.viewType.inPublicComment) {
      filters.push({
        properties: ['inPublicComment'],
        value: true
      });
    }

    if (values.phases && values.phases.length > 0) {
      filters.push({
        properties: ['phase'],
        value: values.phases
      });
    }

    if (values.projectStatuses && values.projectStatuses.length > 0) {
      filters.push({
        properties: ['status'],
        value: values.projectStatuses
      });
    }

    if (values.searchTerm && values.searchTerm.trim().length > 0) {
      const searchTerm = values.searchTerm.trim();
      filters.push({
        properties: ['projectTitle', 'designation'],
        value: searchTerm
      });
    }

    this.tableFilters = filters;


    const phaseEndColumnDetails = this.tableColumns.findIndex(x => x.name === 'phaseEnd');
    if (phaseEndColumnDetails) {
      const columns = [...this.tableColumns];
      columns[phaseEndColumnDetails].isHidden = values.viewType !== this.viewType.inPublicComment;
      this.tableColumns = columns;
    }


    localStorage.setItem(this.localStorageFilterKey, JSON.stringify(values));
  }
}