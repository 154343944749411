<div class="w-full flex flex-col mb-4">
    <div *ngIf="loading" class="h-full w-full flex justify-center items-center">
        <app-loading-indicator></app-loading-indicator>
    </div>

    <ng-container *ngIf="!loading">
        <label *ngIf="label" class="mb-2 block text-sm font-medium leading-6 text-gray-900">
            {{label}}
        </label>        
        <mat-radio-group [(ngModel)]="value" class="flex gap-8" [attr.id]="id">
            <mat-radio-button 
                *ngFor="let option of options"                
                [name]="name"
                [value]="option.value">{{option.label}}</mat-radio-button>
        </mat-radio-group>
    </ng-container>
</div>