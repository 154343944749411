import { Component, Input } from '@angular/core';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import writeXlsxFile, { Schema } from 'write-excel-file';
import { IExportSchema } from '../models/export-to-excel';

@Component({
  selector: 'app-button-export-to-excel',
  templateUrl: './button-export-to-excel.component.html',
  styleUrl: './button-export-to-excel.component.scss'
})
export class ButtonExportToExcelComponent<TData> {
  faDownload = faDownload;

  @Input() fileName: string = 'connect';
  @Input() schema: IExportSchema<TData>[] = [];
  @Input() data: TData[] = [];
  @Input() id?: string = 'btn-export';
  @Input() label: string = 'Export';

  generateSchema(): Schema<TData> {
    const schema: Schema<TData> = this.schema.map(x => {
      const widths = this.data.map((d: any) => {
        const value = d[x.propertyName];
        return typeof value === 'string' ? value.length : x.header.length;
      });

      return {
        column: x.header,
        type: x.type || String,
        value: x.valueRenderer ?? ((row: any) => row[x.propertyName]),

        // defaults - add props to IExportSchema and override in the future if needed
        width: Math.max(x.header.length, ...widths)
      }
    });

    return schema;
  }

  generateExcelFile() {
    const schema = this.generateSchema();

    writeXlsxFile(
      this.data as any,
      {
        schema,
        headerStyle: {
          fontWeight: 'bold',
          backgroundColor: '#BFBFBF'
        },
        fileName: `${this.fileName}.xlsx`
      }
    )
  }

  onExportClickEvent() {
    this.generateExcelFile();
  }
}