<div>
    <form [formGroup]="searchForm" *ngIf="canFilterRoles">
        <div class="flex">
            <div class="w-1/3">
                <app-select-multiple 
                    formControlName="roles" 
                    id="select-roles" 
                    [label]="'Roles'" 
                    [options]="roles"
                    [loading]="loading"
                ></app-select-multiple>
            </div>
        </div>
    </form>
    <div class="py-6">
        <app-table    
            [loading]="loading"         
            [data]="tableData" 
            [columns]="tableColumns" 
            [filters]="tableFilters"
            [pageSize]="50"
            [internalScrolling]="true"
            id="table-committeeMembershipTable" />
    </div>
</div>