import { Component, Input } from '@angular/core';
import { IProjectDetails } from '../../shared/models/project-details';

@Component({
  selector: 'app-project-details-overview',
  templateUrl: './project-details-overview.component.html',
  styleUrl: './project-details-overview.component.scss'
})
export class ProjectDetailsOverviewComponent {
  @Input() projectDetails: IProjectDetails | null = null;
  selectedTab = '';
}
