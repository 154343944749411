import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { IUser } from '../../../shared/models/user';
import { AuthService } from '../../../shared/services/auth.service';
import { UserUtility } from '../../../shared/utilities/user-utility';
import { IExportSchema } from '../../controls/models/export-to-excel';
import { ICommittee } from '../shared/models/committee';

interface IExportCommitteeDetailsData {
  nomOrg: string;
  name: string;
  email: string;
  role: string;
}

@Component({
  selector: 'app-button-export-committee-members',
  templateUrl: './button-export-committee-members.component.html',
  styleUrl: './button-export-committee-members.component.scss'
})
export class ButtonExportCommitteeMembersComponent implements OnChanges, OnDestroy {

  faDownload = faDownload;

  @Input() committee!: ICommittee;

  loading: boolean = true;
  subscriptions: Subscription = new Subscription();
  authorized: boolean = false;

  currentUser: IUser | null = null;
  data: IExportCommitteeDetailsData[] = [];
  exportSchema: IExportSchema<IExportCommitteeDetailsData>[] = [
    {
      propertyName: 'nomOrg',
      header: 'Nominating Organisation',
    },
    {
      propertyName: 'name',
      header: 'Name'
    },
    {
      propertyName: 'email',
      header: 'Email'
    },
    {
      propertyName: 'role',
      header: 'Role'
    },
  ];

  constructor(
    private _authService: AuthService,
  ) {
    const userSubscription = this._authService.currentUserSubject.subscribe(user => {
      if (user) {
        this.currentUser = user;
      }
    });

    this.subscriptions.add(userSubscription);
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const committee: ICommittee = changes['committee']?.currentValue;
    if (!committee || !this.currentUser) {
      return;
    }

    this.loading = true;
    this.authorized = UserUtility.isInternalUser(this.currentUser) || UserUtility.userIsOnCommittee(this.currentUser, committee.committeeCode);

    let data: IExportCommitteeDetailsData[] = [];
    committee.membershipDetails?.forEach(member => {
      data.push({
        nomOrg: member.membershipNomOrg,
        name: member.memberFullName,
        email: member.memberEmail,
        role: member.membershipType
      })
    });

    this.data = data;
    this.loading = false;
  }
}
