import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { IApiResponse } from '../../../../shared/models/api-response';
import { IIecBallotDetails } from '../models/iec-ballot-details';
import { environment } from '../../../../../environments/environment';
import { convertStringDateToJSDate } from '../../../../shared/utilities/date-utility';

@Injectable({
  providedIn: 'root'
})
export class IecBallotService {

  baseUrl: string = `${environment.servicesUrl.ballot}api/ballots/iec`;

  constructor(
    private http: HttpClient,
  ) { }

  public getBallot(id: string): Observable<IApiResponse<IIecBallotDetails>> {
    return this.http
      .get<IApiResponse<IIecBallotDetails>>(`${this.baseUrl}/${id}`)
      .pipe(
        // transfer to somewhere reusable if needed...
        map((rawResponse) => {
          let response = {...rawResponse};
          if(rawResponse.success && rawResponse.content){
            if(response.content.ballotSummary){
              response.content.ballotSummary.startDate = convertStringDateToJSDate(response.content.ballotSummary.startDate as any as string) as Date;
              response.content.ballotSummary.endDate = convertStringDateToJSDate(response.content.ballotSummary.endDate as any as string) as Date;
            }

            if(response.content.documents?.length > 0){
              response.content.documents.forEach(x => {
                x.createdOn = convertStringDateToJSDate(x.createdOn as any as string) as Date;
              });
            }

            if(response.content.iecBallotVotes?.length > 0){
              response.content.iecBallotVotes.forEach(x => {
                x.createDate = convertStringDateToJSDate(x.createDate as any as string);
                x.lastUpdated = convertStringDateToJSDate(x.lastUpdated as any as string)
              });
            }
          }

          return response;
        })
      );
  }
}
