export enum TableColumnType {
    string,
    number,
    date,
    boolean,
    link,
    arrayString,

    // add other types - could be general or data specific
}

export interface ITableColumn<T> {
    name: string;
    displayName: string;      
      
    type?: TableColumnType;
    isSortable?: boolean;
    isHidden?: boolean;

    getLink?: (row: T) => string;
    getDisplayValue?: (row: T) => any;

    headerWidth?: string;
}