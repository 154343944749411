function _createForOfIteratorHelperLoose(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (it) return (it = it.call(o)).next.bind(it); if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; return function () { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import $text from '../xml/sanitizeText.js';
export default function initSharedStrings() {
  var sharedStrings = [];
  var sharedStringsIndex = {};
  return {
    getSharedStringsXml: function getSharedStringsXml() {
      return generateXml(sharedStrings);
    },
    getSharedString: function getSharedString(string) {
      var id = sharedStringsIndex[string];

      if (id === undefined) {
        id = String(sharedStrings.length);
        sharedStringsIndex[string] = id;
        sharedStrings.push(string);
      }

      return id;
    }
  };
}

function generateXml(sharedStrings) {
  var xml = '<?xml version="1.0"?>';
  xml += '<sst xmlns="http://schemas.openxmlformats.org/spreadsheetml/2006/main">';

  for (var _iterator = _createForOfIteratorHelperLoose(sharedStrings), _step; !(_step = _iterator()).done;) {
    var string = _step.value;
    var attributes = string.trim().length === string.length ? '' : ' xml:space="preserve"';
    xml += "<si><t".concat(attributes, ">");
    xml += $text(string);
    xml += '</t></si>';
  }

  xml += '</sst>';
  return xml;
}
