import { iecBallotVoteOptions } from "./iec-ballot.constants";

// declare all constants here to make it more readable in code
// if constants are too big, then create a separate file for it ex: ballot-types.constants.ts
export const appConstants = {
    localStorageKey: {
        token: 'token',
    },
    iecBallots: {
        voteOptions: iecBallotVoteOptions
    },
    liasonTypes: {
        to: "To",
        from: "From"
    },
    publicationStatus: {
        balloting: "PS_BALLOTING",
        ballotingCompleted: "PS_BALLOTING_COMPLETED",
        commenting: "PS_COMMENTING",
        commentingAndBalloting: "PS_COMMENTINGANDBALLOTING",
        commentingCompleted: "PS_COMMENTING_COMPLETED",
        drafting: "PS_DRAFTING",
        published: "PS_PUBLISHED",
    },
    projects: {
        viewTypes: {
            myProjects: 'My Projects',
        }
    },
    queryParams: {
        view: 'view',
    },
    sdBallots: {
        status: {
            notOpen: 'PBS_NOT_OPEN',    // Scheduled
            open: 'PBS_OPEN',
            closed: 'PBS_CLOSED',
            accepted: 'PBS_ACCEPTED',   // Finalised
        },
        voteOptions: {
            abstain: 'VT_ABSTAIN',
            agree: 'VT_AGREE',
            disagree: 'VT_DISAGREE',
            notCast: 'VT_NONE',
        }
    },
    userRole: {
        admin: "admin",
        committeeMember: "committeemember",
        generalManagerOperations: "GMO",
        itSupport: "IT-SUPPORT",
        moderator: "moderator",
        nominatingOrganisation: "nomorg",
        parliamentaryMemberAccess: "PMA",
        programManager: "pgmgr",
        projectManager: "pm",
        public: "public",
        staff: "staff",
        technicalWriter: "tw",
    },
    userType: {
        internal: 'Internal',
        external: 'External'
    },
    votingRights: {
        eligible: 'Eligible',
    },
    committeeStatus: {
        active: "active"
    },
    committeeTable: {
        id: "table-committeeSearchTable"
    },

    SIMPublicationStatus: {
      Current: "Current",
      PendingRevision: "Pending Revision"
    }


}
