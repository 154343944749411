import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NomOrgComponent } from './nom-org.component';
import { NomOrgsRoutingModule } from './nom-orgs-routing.module';



@NgModule({
  declarations: [
    NomOrgComponent
  ],
  imports: [
    CommonModule,
    NomOrgsRoutingModule
  ]
})
export class NomOrgsModule { }
