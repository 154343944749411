/**
* does not handle child objects
*/
export const objectToQueryString = (obj: any): string => {
   var queryString: string = '';

   if (obj) {
       Object.keys(obj).forEach((key) => {
           var val = obj[key];
           if (val === undefined || val === null) {

           }
           else if (Array.isArray(val)) {
               for (let i = 0; i < val.length; i++) {
                   queryString += `&${key}=${val[i]}`;
               }
           }
           else {
               queryString += `&${key}=${val}`;
           }
       });
   }

   return queryString.replace('&', '?');
}


export default {
    objectToQueryString
}