<div
  [id]="'card-' + index"
  class="rounded-lg bg-white shadow-cardLightShadow p-4 flex flex-col gap-4"
  [class.grid-card-size]="layout === searchResultLayoutEnum.Grid"
  [class.list-card-size]="layout === searchResultLayoutEnum.List"
>
  <div
    [id]="'card-header-' + index"
    class="grow-0 flex flex-row justify-start items-center gap-2"
  >
    <app-document-type-badge
      [documentType]="documentType"
      [name]="'document-type-' + index"
    ></app-document-type-badge>
    <span>&#x2219;</span>
    <span
      [id]="'document-status-' + index"
      class="body-small-regular text-disabled"
    >
      {{ documentStatus }}
    </span>
  </div>

  <div [id]="'card-body-' + index" class="grow flex flex-col justify-between">
    <a
      [id]="'card-title-' + index"
      class="body-large-regular text-tertiary truncate"
      [routerLink]="['/sa-library', searchResultItem?.committee]"
    >
      {{ searchResultItem?.designation }}
    </a>

    <div class="flex flex-col gap-4">
      <div class="flex flex-col gap-1">
        <span
          class="body-small-regular"
          [class.grid-line-clamp]="layout === searchResultLayoutEnum.Grid"
          [class.list-line-clamp]="layout === searchResultLayoutEnum.List"
        >
          {{ searchResultItem?.title }}
        </span>
        <span class="body-small-regular text-disabled">
          Committee: {{ searchResultItem?.committee }} <br />
          Published: {{ searchResultItem?.pubDate | date : "dd/MM/yyyy" }}
        </span>
      </div>

      <div class="flex flex-row justify-between items-end">
        <a
          [id]="'a-view-information-' + index"
          class="body-default-regular text-link"
          [routerLink]="['/sa-library', searchResultItem?.committee]"
        >
          View Information
        </a>
        <button
          *ngIf="isDownloadButtonVisible"
          (click)="onDownloadClick($event)"
          class="btn-xs btn-primary text-primary flex flex-row items-center"
          [id]="'btn-download-' + index"
        >
          <span class="material-symbols-rounded icon-16 mr-1">download</span>
          Download&nbsp;
        </button>
      </div>
    </div>
  </div>
</div>
