import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-reports-item',
  templateUrl: './reports-item.component.html',
  styleUrl: './reports-item.component.scss'
})
export class ReportsItemComponent {
  @Input() id: string = '';
  @Input() cardTitle: string = '';
  @Input() cardDescription: string = '';
  @Input() routerLink: string | null = null;
}
