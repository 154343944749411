import { Component, Input } from '@angular/core';
import { BallotReport } from '../shared/models/reports/ballot-report';

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-generate-report-button',
  templateUrl: './generate-report-button.component.html'
})
export class GenerateReportButtonComponent {
  @Input() ballotReport: BallotReport | undefined = undefined;

  generateReport() {
    if (this.ballotReport) {
      this.ballotReport.generateReport();
    }
  }
}
