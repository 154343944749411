import { Component } from '@angular/core';

@Component({
  selector: 'app-sd-details',
  templateUrl: './sd-details.component.html',
  styleUrl: './sd-details.component.scss'
})
export class SdDetailsComponent {

}
