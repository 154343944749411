import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { map, Observable } from 'rxjs';
import { PermissionService } from '../services/permission.service';

export const roleGuard = (checkRoleFn: () => Observable<boolean>) => {
  return () => {
    const router = inject(Router);

    return checkRoleFn().pipe(
      map((result) => {
        if(result){
          return result;
        }
  
        return router.parseUrl('/dashboard');
      })
    );
  }
}

export const nomOrgPageGuard = roleGuard(() => inject(PermissionService).canAccessNomOrgPage());
export const ballotsPageGuard = roleGuard(() => inject(PermissionService).canAccessBallotsPage());