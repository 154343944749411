import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { faAngleLeft, faAngleRight, faCaretDown, faCaretUp, faSort } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import { ITableColumn, TableColumnType } from './table-column';
import { ITableFilter } from './table-filter';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrl: './table.component.scss',
})
export class TableComponent<TData> implements OnInit, OnChanges {
  faAngleLeft = faAngleLeft;
  faAngleRight = faAngleRight;
  faSort = faSort;
  faCaretDown = faCaretDown;
  faCaretUp = faCaretUp;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  @Input() columns!: ITableColumn<TData>[];
  @Input() loading: boolean = false;
  @Input() pageSize: number = 20;
  @Input() filters: ITableFilter[] = [];  
  @Input() id: string = 'table';

  @Input() pageSizeOptions: number[] = [20, 50, 100];

  // implement once needed
  // @Input() pagination: boolean = true;
  // @Input() sorting: boolean = true;

  dataSource = new MatTableDataSource<TData>();
  @Input() set data(data: TData[]) {
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = this.filterPredicate;

    this.applyFilter(this.filters);
  }

  displayColumns: string[] = [];
  columnType = TableColumnType;

  ngOnInit(): void {
    this.setupDisplayColumns(this.columns);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const columns = changes['columns'];
    if (columns) {
      this.setupDisplayColumns(columns.currentValue);
    }

    const filters = changes['filters'];
    if (filters?.currentValue) {
      this.applyFilter(filters.currentValue);
    }
  }

  setupDisplayColumns(columns: ITableColumn<TData>[]): void {
    if (columns) {
      this.displayColumns = columns
        .filter(c => c.isHidden !== true)
        .map(c => c.name as string);
    }
  }

  getDisplayValue(data: TData, column: ITableColumn<TData>): any {
    if (column.getDisplayValue) {
      return column.getDisplayValue(data);
    }

    const value = (data as any)[column.name];

    if (column.type === this.columnType.date && value) {
      return format(value, 'dd/MM/yyyy')
    }

    return value;
  }

  applyFilter(filters: ITableFilter[]) {
    if(!filters || filters.length === 0) {
      this.dataSource.filter = '';
      return;
    }

    this.dataSource.filter = JSON.stringify(filters);

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  filterPredicate(record: TData, filterString: string) {
    var tableFilters = JSON.parse(filterString) as ITableFilter[];
    
    for (const filter of tableFilters) {
      let values: any[] = [];
      for(const property of filter.properties) {
        const recordValue = record[property as keyof TData];
        
        if(recordValue != undefined && Array.isArray(recordValue)){
          values = [...values, ...recordValue];
        }
        else if(recordValue){
          values.push(recordValue);
        }        
      }
      
      if(values.length === 0) {
        return false;      
      }

      if (Array.isArray(filter.value)) {   
        if(filter.value.filter(x => values.includes(x)).length === 0) {
          return false;
        }
      }
      else{
        let match = false;
        const typeOfFilterValue = typeof filter.value;
        for(let i = 0; i < values.length; i++) {
          if (typeof values[i] === 'string' && typeOfFilterValue === 'string') {
            if (values[i].toLowerCase().indexOf(filter.value.toLowerCase()) !== -1) {
              match = true;
              break;
            }
          } else if (values[i] == filter.value) {
            match = true;
            break;
          }
        }
        
        if(!match){
          return false;
        }
      }
    }

    return true;
  }
}
