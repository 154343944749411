import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AuthorisationModule } from './core/authorisation/authorisation.module';
import { BallotsModule } from './core/ballots/ballots.module';
import { CommitteesModule } from './core/committees/committees.module';
import { DashboardModule } from './core/dashboard/dashboard.module';
import { LayoutsModule } from './core/layouts/layouts.module';
import { ProjectsModule } from './core/projects/projects.module';

import { AuthInterceptor } from './shared/interceptors/auth.interceptor';

import { ControlsModule } from './core/controls/controls.module';
import { NomOrgsModule } from './core/nom-orgs/nom-orgs.module';
import { SaLibraryModule } from './core/sa-library/sa-library.module';
import { SettingsModule } from './core/settings/settings.module';
import { AuthService } from './shared/services/auth.service';
import { SamlService } from './shared/services/saml.service';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,

    AuthorisationModule,
    BallotsModule,
    CommitteesModule,
    ControlsModule,
    DashboardModule,
    LayoutsModule,
    NomOrgsModule,
    ProjectsModule,
    SaLibraryModule,
    SettingsModule,
  ],
  providers: [
    AuthService,
    SamlService,

    provideHttpClient(
      withInterceptorsFromDi()
    ),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },

    provideAnimationsAsync()
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
