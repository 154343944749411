import { Component } from '@angular/core';
import { AuthService } from '../../shared/services/auth.service';
import { IUser } from '../../shared/models/user';
import { UserUtility } from '../../shared/utilities/user-utility';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent {

  currentUser: IUser | null = null;
  canViewProjectUpdates: boolean = false;

  constructor(
    private _authService: AuthService
  ) {
    this._authService.currentUserSubject.subscribe((user) => {
      this.currentUser = user;
      this.initialize();
    });
  }

  initialize() {
    if(!this.currentUser) return;
    this.canViewProjectUpdates = UserUtility.canViewProjectUpdates(this.currentUser);
  }
}
