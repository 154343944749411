import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IOption } from '../../controls/models/option';

@Component({
  selector: 'app-select-project-status',
  templateUrl: './select-project-status.component.html',
  styleUrl: './select-project-status.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectProjectStatusComponent),
      multi: true
    }
  ]
})
export class SelectProjectStatusComponent implements ControlValueAccessor {

  @Input('value') _value: string | undefined;  
  @Input() id: string = 'select-project-status';
  @Input() label: string = 'Project Status';

  onChange: any = () => { };
  onTouched: any = () => { };

  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;
    this.onChange(val);
    this.onTouched();
  }

  options: IOption<string>[] = [
    { value: '', label: 'All' },
    { value: 'Active', label: 'Active' },
    { value: 'On hold', label: 'On hold' },
  ];

  constructor(
  ) {
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}