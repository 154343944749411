import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { IUser } from '../../../../shared/models/user';
import { AuthService } from '../../../../shared/services/auth.service';
import { UserUtility } from '../../../../shared/utilities/user-utility';
import { IProjectDetails } from '../../shared/models/project-details';

@Component({
  selector: 'app-project-details-overview',
  templateUrl: './project-details-overview.component.html',
  styleUrl: './project-details-overview.component.scss'
})
export class ProjectDetailsOverviewComponent implements OnInit, OnDestroy {
  @Input() projectDetails: IProjectDetails | null = null;

  currentUser: IUser | null = null;
  hasAccessToBallotsTab = false;
  selectedTab = '';
  subscriptions: Subscription = new Subscription();

  constructor(
    private _authService: AuthService
  ) { }

  ngOnInit(): void {
    const userSubscription = this._authService.currentUserSubject.subscribe(user => {
      if (user) {
        this.currentUser = user;
        this.initialise();
      }
    });

    this.subscriptions.add(userSubscription);
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  initialise(): void {
    if (this.currentUser && this.projectDetails) {
      this.hasAccessToBallotsTab = UserUtility.canViewSdBallotDetails(this.currentUser, this.projectDetails.committeeCode);
    }
  }

  onTabChange(tabId: string) {
    this.selectedTab = tabId;
  }
}
