<ng-container *ngIf="!loading && projects && projects.length > 0">
  <app-update-scroller
    [title]="'Recent Projects'"
    [updateCount]="projects.length"
  >
    <a
      *ngFor="let project of projects"
      [routerLink]="['/projects', project.projectId]"
    >
      <app-update-scroller-card
        [title]="project.title"
        [description]="project.description"
        [id]="'card-recent-project-' + project.projectId"
      >
        <div headerLeft name="date">
          {{ project.accessDate | date : "dd/MM/yyyy" }}
        </div>
        <app-status-badge
          [colourScheme]="statusBadgeColourSchemeEnum.Green"
          headerRight
          name="phase"
        >
          {{ project.phase }}
        </app-status-badge>
      </app-update-scroller-card>
    </a>
  </app-update-scroller>
</ng-container>
