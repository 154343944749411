import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { StatusBadgeColourScheme } from './status-badge-constants';

@Component({
  selector: 'app-status-badge',
  templateUrl: './status-badge.component.html',
  styleUrl: './status-badge.component.scss'
})
export class StatusBadgeComponent implements OnChanges {
  @Input() name: string = '';
  @Input() colourScheme: StatusBadgeColourScheme = StatusBadgeColourScheme.Green;
  @Input() iconName: string | null = null;
  cssClass: string = '';


  ngOnChanges(changes: SimpleChanges): void {
    if (changes['colourScheme']) {
      this.cssClass = this.getCssClass();
    }
  }

  getCssClass(): string {
    switch (this.colourScheme) {
      case StatusBadgeColourScheme.Green:
        return 'badge green';
      case StatusBadgeColourScheme.Red:
        return 'badge red';
      case StatusBadgeColourScheme.Blue2:
        return 'badge blue2';
      default:
        return 'badge';
    }
  }
}
