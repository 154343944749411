import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CommitteesModule } from '../committees/committees.module';
import { ControlsModule } from '../controls/controls.module';
import { ProjectDetailsComponent } from './project-details/project-details.component';
import { ProjectsRoutingModule } from './projects-routing.module';
import { ProjectsComponent } from './projects.component';
import { SelectCommitteeComponent } from './select-committee/select-committee.component';
import { SelectMultipleProjectPhaseComponent } from './select-multiple-project-phase/select-multiple-project-phase.component';
import { SelectMultipleProjectStatusComponent } from './select-multiple-project-status/select-multiple-project-status.component';
import { SelectProjectPhaseComponent } from './select-project-phase/select-project-phase.component';
import { SelectProjectStatusComponent } from './select-project-status/select-project-status.component';
import { RecentlyViewedProjectsComponent } from './recently-viewed-projects/recently-viewed-projects.component';
import { ProjectDetailsOverviewComponent } from './project-details/project-details-overview/project-details-overview.component';
import { ProjectDetailsOverviewProjectDetailsComponent } from './project-details/project-details-overview/project-details-overview-project-details/project-details-overview-project-details.component';
import { ProjectDetailsTimelineComponent } from './project-details/project-details-timeline/project-details-timeline.component';
import { ProjectDetailsOverviewPublicCommentsComponent } from './project-details/project-details-overview/project-details-overview-public-comments/project-details-overview-public-comments.component';
import { PublicCommentingStatusBadgeComponent } from './shared/components/public-commenting-status-badge/public-commenting-status-badge.component';
import { ProjectUpdatesComponent } from './project-updates/project-updates.component';
import { ProjectDetailsOverviewBallotComponent } from './project-details/project-details-overview/project-details-overview-ballot/project-details-overview-ballot.component';

@NgModule({
  declarations: [
    ProjectsComponent,
    SelectProjectPhaseComponent,
    SelectCommitteeComponent,
    SelectProjectStatusComponent,
    ProjectDetailsComponent,
    SelectMultipleProjectPhaseComponent,
    SelectMultipleProjectStatusComponent,
    RecentlyViewedProjectsComponent,
    ProjectDetailsOverviewComponent,
    ProjectDetailsOverviewProjectDetailsComponent,
    ProjectDetailsTimelineComponent,
    ProjectDetailsOverviewPublicCommentsComponent,
    PublicCommentingStatusBadgeComponent,
    ProjectUpdatesComponent,
    ProjectDetailsOverviewBallotComponent,
  ],
  imports: [
    CommonModule,
    ProjectsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ControlsModule,
    FontAwesomeModule,
    CommitteesModule,

    MatButtonModule,
  ],
  exports: [
    ProjectUpdatesComponent
  ]
})
export class ProjectsModule { }
