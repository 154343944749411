import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../../environments/environment';
import { Observable } from 'rxjs';
import { IApiResponse } from '../../../../../shared/models/api-response';
import { ReportConfigResponse } from '../models/report-model';
import { ReportType } from '../models/report-type';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  baseUrl: string = `${environment.servicesUrl.committee}api/powerBiEmbed`;

  constructor(
    private http: HttpClient,
  ) { }

  getPowerBiEmbed(reportType: ReportType): Observable<IApiResponse<ReportConfigResponse>>{
    return this.http.get<IApiResponse<ReportConfigResponse>>(`${this.baseUrl}/${reportType}`);
  }

}
