import { Component, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { appConstants } from '../../../../../shared/constants/app-constants';
import { PcmsDraftStatus } from '../../../../../shared/constants/pcms-constants';
import { IUser } from '../../../../../shared/models/user';
import { AuthService } from '../../../../../shared/services/auth.service';
import { UserUtility } from '../../../../../shared/utilities/user-utility';
import { IPieChartDataItem } from '../../../shared/models/pie-chart-data-item';
import { IProjectDetails, IProjectDetailsCategory } from '../../../shared/models/project-details';
import { ChartjsService } from '../../../shared/services/chartjs.service';


@Component({
  selector: 'app-project-details-overview-public-comments',
  templateUrl: './project-details-overview-public-comments.component.html',
  styleUrl: './project-details-overview-public-comments.component.scss'
})
export class ProjectDetailsOverviewPublicCommentsComponent implements OnDestroy, OnChanges {

  editorialComments: string = "Editorial";
  generalComments: string = "General";
  technicalComments: string = "Technical";
  editorialColour: string = '#6589F7';
  generalColour: string = '#BDCBFC';
  technicalColour: string = '#ABABAB';

  @Input() projectDetails: IProjectDetails | null = null;
  @ViewChild('chartpubliccommenting') canvas: ElementRef<HTMLCanvasElement> = {} as ElementRef<HTMLCanvasElement>;
  chartCanvasId = 'chart-public-commenting';

  currentUser: IUser | null = null;
  loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  showComponent: boolean = false;
  isViewPublicCommentingButtonVisible: boolean = false;
  subscriptions: Subscription = new Subscription();

  commentingStartDate: Date | null = null;
  commentingEndDate: Date | null = null;
  commentPeriodStatus: string = '';

  editorialCount: number = 0;
  generalCount: number = 0;
  technicalCount: number = 0;
  commentsBreakdown: IProjectDetailsCategory[] = [];
  commentUrl: string = '';
  pcmsDraftStatus: string = '';

  constructor(
    private chartjsService: ChartjsService,
    private _authService: AuthService
  ) {
    const loadingSubscription = this.loading.subscribe(loading => {
      if (!loading) {
        this.showComponent = true;
        this.createPublicCommentingChart();
      }
    });

    this.subscriptions.add(loadingSubscription);
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['projectDetails']) {
      this.projectDetails = changes['projectDetails'].currentValue;

      if (this.currentUser) {
        this.initialise();
        return;
      }

      const userSubscription = this._authService.currentUserSubject.subscribe(user => {
        if (user) {
          this.currentUser = user;
          this.initialise();
        }
      });

      this.subscriptions.add(userSubscription);
    }
  }

  initialise() {
    this.initialisePublicCommentData();
    this.setViewPublicCommentingButtonVisibility();

    this.loading.next(false);
  }

  initialisePublicCommentData(): void {

    this.commentingStartDate = this.projectDetails?.commentStartDate ?? null;
    this.commentingEndDate = this.projectDetails?.commentEndDate ?? null;
    this.commentPeriodStatus = this.projectDetails?.commentPeriodStatus ?? '';

    this.commentsBreakdown = this.projectDetails?.commentsSet ?? [];

    this.editorialCount = this.commentsBreakdown.find(row => row.categoryId.toLowerCase() === this.editorialComments.toLowerCase())?.noOfComments ?? 0;
    this.generalCount = this.commentsBreakdown.find(row => row.categoryId.toLowerCase() === this.generalComments.toLowerCase())?.noOfComments ?? 0;
    this.technicalCount = this.commentsBreakdown.find(row => row.categoryId.toLowerCase() === this.technicalComments.toLowerCase())?.noOfComments ?? 0;

    this.commentUrl = this.projectDetails?.commentUrl ?? '';
    if (this.commentUrl && this.commentUrl.length > 0) {
      this.commentUrl += UserUtility.isInternalUser(this.currentUser) ? '?usertype=Intuser' : '?usertype=Extuser';
    }
    this.pcmsDraftStatus = this.projectDetails?.commentPeriodStatus ?? '';
  }

  createPublicCommentingChart() {
    let chartData: IPieChartDataItem[] = [{
      label: 'None',
      count: 0,
      color: 'transparent'
    }];

    if (this.commentsBreakdown && this.commentsBreakdown.length > 0) {
      chartData = this.commentsBreakdown.map(row => {
        return {
          label: row.categoryId,
          count: row.noOfComments,
          color: this.getChartColour(row.categoryId)
        };
      });
    }

    const canvasElement = this.canvas?.nativeElement ?? document.querySelector(`#${this.chartCanvasId}`);
    if (canvasElement) {
      this.chartjsService.renderDoughnutChart(canvasElement, chartData);
    }
  }

  getChartColour(categoryId: string): string {

    switch (categoryId.toLowerCase()) {
      case this.editorialComments.toLowerCase():
        return this.editorialColour;
      case this.generalComments.toLowerCase():
        return this.generalColour;
      case this.technicalComments.toLowerCase():
        return this.technicalColour;
      default:
        return this.technicalColour;
    }
  }

  setViewPublicCommentingButtonVisibility() {
    if (!this.projectDetails || !this.currentUser) {
      this.isViewPublicCommentingButtonVisible = false;
      return;
    }

    this.isViewPublicCommentingButtonVisible =
      this.projectDetails.commentPeriodStatus === PcmsDraftStatus.Open
      || UserUtility.isInternalUser(this.currentUser)
      || (UserUtility.hasRoles(this.currentUser, appConstants.userRole.committeeMember) && UserUtility.userIsOnCommittee(this.currentUser, this.projectDetails.committeeCode));
  }
}
