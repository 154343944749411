import { Component, forwardRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { orderBy, uniqBy } from 'lodash';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../shared/services/auth.service';
import { IOption } from '../../controls/models/option';
import { SelectMultipleComponent } from '../../controls/select-multiple/select-multiple.component';
import { CommitteeService } from '../shared/services/committee.service';

@Component({
  selector: 'app-select-multiple-committee',
  templateUrl: './select-multiple-committee.component.html',
  styleUrl: './select-multiple-committee.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectMultipleCommitteeComponent),
      multi: true
    }
  ]
})
export class SelectMultipleCommitteeComponent implements ControlValueAccessor, OnDestroy {

  @ViewChild('selectMultiple') selectMultipleComponent!: SelectMultipleComponent;

  @Input() id: string = 'select-committee';
  @Input() label: string = 'Committee';

  onChange: any = () => { };
  onTouched: any = () => { };

  private _values: any[] | undefined;
  get values() {
    return this._values;
  }
  set values(values) {
    this._values = values;
    this.onChange(values);
    this.onTouched();
  }

  loading: boolean = true;
  subscriptions: Subscription = new Subscription();
  options: IOption<string>[] = [];

  constructor(
    private _authService: AuthService,
    private _committeeService: CommitteeService,
  ) {
    const userSubscription = this._authService.currentUserSubject.subscribe(user => {
      if (user) {
        this.getCommittees();
      }
    });

    this.subscriptions.add(userSubscription);
  }

  writeValue(values: any[]): void {
    this.values = values;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  getCommittees() {
    const searchCommittees = this._committeeService.getCommittees().subscribe(response => {
      if (response.success) {
        const uniqueCommittees = uniqBy(response.content, x => x.committeeCode);
        this.options = orderBy(uniqueCommittees, x => x.committeeCode)
          .map(x => ({ value: x.committeeCode, label: `${x.committeeCode} ${x.committeeName ?? ''}` }));
      }

      this.loading = false;
    });

    this.subscriptions.add(searchCommittees);
  }
}
