import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, switchMap, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;

  constructor(
    private authService: AuthService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({
      setHeaders: {
        Authorization: `Bearer ${this.authService.authToken}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': window.location.origin,
      }
      //withCredentials: true, // for cookies to be sent - needed for refresh tokens
    });

    return next.handle(req).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handle401Error(req, next);
        }

        return throwError(() => error);
      })
    );
  }

  // TODO: change conditions based on refresh token response made in .NET auth service
  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;

      if (this.authService.isLoggedIn) {
        return this.authService
          .refreshAccessToken()
          .pipe(
            switchMap(() => {
              this.isRefreshing = false;

              // reset auth headers to get new token
              const clonedRequest = request.clone({
                setHeaders: {
                  Authorization: `Bearer ${this.authService.authToken}`
                }
              });

              return next.handle(clonedRequest);
            }),
            catchError((error) => {
              this.authService.logout();
              return throwError(() => error);
            })
          );
      }
    }

    return next.handle(request);
  }
};
