import { format } from 'date-fns';
import * as pdfMake from "pdfmake/build/pdfmake";
import { Content, ContentTable, TableCell, TDocumentDefinitions } from "pdfmake/interfaces";
import { appConstants } from "../../../../../shared/constants/app-constants";
import { reportLayoutNames, reportLayouts } from "./report-layouts";
import { reportStyleNames, reportStyles } from "./report-styles";

export class BallotReport {
    defaultDateFormat: string = 'dd/MM/yyyy';
    contentSpacer = { text: `\n` };
    committeeVoteColumns: string[] = [];
    ballotVoteResultColumns: string[] = [];

    documentHeader: string = '';
    documentFilename: string = '';

    designation?: string;
    reference?: string;

    question?: string;

    designationTitle: string = '';
    title: string = '';

    publishedDate?: Date | string;
    publicationCreateDate?: Date | string;

    committee: string = '';
    committeeTitle?: string;

    projectManager: string = '';

    associatedDocuments: string[] = [];

    startDate: Date | undefined = undefined;
    endDate: Date | undefined = undefined;
    status: string = '';

    notes: string = '';

    committeeVotes: IBallotReportCommitteeVote[] = [];
    voteResultBreakdown: IBallotReportVoteResultsBreakdown[] = [];
    questions: IBallotReportQuestion[] = [];

    public generateBallotSummary(): Content {
        let tableBody: TableCell[][] = [
            [
                { text: 'BALLOT SUMMARY', style: { bold: true }, borderColor: ['white', 'white', 'white', '#FFB500'], border: [0, 0, 0, 3], padding: [0, 0, 0, 0] },
                { text: '', border: [0, 0, 0, 3] }
            ]
        ];

        if (this.designation) {
            tableBody.push([
                { text: 'Designation', style: { bold: true } },
                { text: this.designation, color: 'blue', decoration: 'underline' }
            ]);
        }

        if (this.reference) {
            tableBody.push([
                { text: 'Reference', style: { bold: true } },
                { text: this.reference }
            ]);
        }

        if (this.question) {
            tableBody.push([
                { text: 'Question', style: { bold: true } },
                { text: this.question }
            ]);
        }

        if (this.designationTitle) {
            tableBody.push([
                { text: 'Designation Title', style: { bold: true } },
                { text: this.designationTitle }
            ]);
        }

        if (this.title) {
            tableBody.push([
                { text: 'Title', style: { bold: true } },
                { text: this.title }
            ]);
        }

        if (this.publicationCreateDate) {
            tableBody.push([
                { text: 'Publication Create Date', style: { bold: true } },
                { text: format(this.publicationCreateDate, this.defaultDateFormat) }
            ]);
        }

        if (this.publishedDate) {
            tableBody.push([
                { text: 'Published Date', style: { bold: true } },
                { text: format(this.publishedDate, this.defaultDateFormat) }
            ]);
        }

        return {
            width: '45%',
            table: {
                widths: ['40%', '*'],
                body: [
                    ...tableBody,
                    ...[
                        [
                            { text: 'Committee', style: { bold: true } },
                            this.committeeTitle ? `${this.committee}, ${this.committeeTitle}` : this.committee
                        ],
                        [
                            { text: 'Project Manager', style: { bold: true } },
                            this.projectManager],
                        [
                            { text: 'Associated Documents', style: { bold: true } },
                            this.associatedDocuments.map(x => {
                                return {
                                    text: x,
                                    color: 'blue',
                                    decoration: 'underline'
                                }
                            })
                        ],
                        [
                            { text: 'Ballot Start Date', style: { bold: true } },
                            this.startDate ? format(this.startDate, this.defaultDateFormat) : ''
                        ],
                        [
                            { text: 'Ballot End Date', style: { bold: true } },
                            this.endDate ? format(this.endDate, this.defaultDateFormat) : ''
                        ],
                        [
                            { text: 'Status', style: { bold: true } },
                            this.status
                        ]
                    ]
                ]
            },
            layout: reportLayoutNames.ballotSummaryTable
        } as any;
    }

    public generateNotes(): ContentTable {
        return {
            table: {
                body: [
                    [{ text: 'Notes', style: 'tableHeader' }],
                    [{ text: this.notes ?? ' ' }],
                ],
                widths: ['*']
            },
            layout: reportLayoutNames.defaultTable
        }
    }

    public generateCommitteeVotesTable(): ContentTable | null {
        if ((this.committeeVoteColumns?.length ?? 0) < 1 || (this.committeeVotes?.length ?? 0) < 1) {
            return null;
        }

        return {
            table: {
                body: [
                    this.committeeVoteColumns.map(x => { return { text: x, style: reportStyleNames.tableHeader } }),
                    ...this.committeeVotes
                        .sort((a, b) => {
                            let orgA = a.organisation?.toLowerCase() ?? '';
                            let orgB = b.organisation?.toLowerCase() ?? '';
                            let orgCompareResult = orgA.localeCompare(orgB);
                            if (orgCompareResult !== 0) return orgCompareResult;

                            let committeeMemberA = a.committeeMember ?? '';
                            let committeeMemberB = b.committeeMember ?? '';
                            return committeeMemberA.localeCompare(committeeMemberB);
                        })
                        .map(x => {
                            return [
                                { text: x.committeeMember },
                                { text: x.organisation },
                                { text: x.role },
                                { text: x.country },
                                { text: x.votingRights },
                                ...x.answers.map(answer => ({ text: answer }))
                            ]
                        })
                ],
                widths: ['20%', '30%', '15%', '10%', '9%', '8%', '8%']
            },
            layout: reportLayoutNames.defaultTable
        };
    }

    public generateBallotVoteResultBreakdownTable(): ContentTable | null {
        if (!this.ballotVoteResultColumns || (this.voteResultBreakdown?.length ?? 0) < 1) {
            return null;
        }

        return {
            table: {
                body: [
                    this.ballotVoteResultColumns.map(x => { return { text: x, style: reportStyleNames.tableHeader } }),
                    ...this.voteResultBreakdown.map(x => {
                        return [
                            { text: x.country },
                            { text: x.totalNumberOfVotes },
                            ...x.votePercentages.map(v => ({ text: v.value, fillColor: v.fillColor }))
                        ]
                    })
                ],
                widths: ['20%', '10%', '17.5%', '17.5%', '17.5%', '17.5%']
            },
            layout: reportLayoutNames.outerBordersTable
        };
    }

    public generateConsensusCriteriaBreakdownTable(): ContentTable | null {
        return null;
    }

    public generateQuestionTable(): ContentTable | null {
        if ((this.questions?.length ?? 0) < 1) {
            return null;
        }

        return {
            table: {
                body: [
                    [{ text: 'Ballot Questions', colSpan: 2, style: reportStyleNames.tableHeader }, ''],
                    ...this.questions.map(x => {
                        return [
                            { text: x.questionNumber },
                            { text: x.question }
                        ]
                    })
                ],
                widths: ['20%', '*']
            },
            layout: reportLayoutNames.defaultTable
        };
    }

    public generateQuestionDetailsTables(): Content[] | null {
        if ((this.questions?.length ?? 0) < 1) {
            return null;
        }

        let tables: Content[] = [];
        this.questions.forEach((q, qIndex) => {
            let answers = this.committeeVotes.map(c => c.answers[qIndex]);
            let uniqueAnswers = q.answerOptions ?? answers.filter((answer, index) => answers.indexOf(answer) === index);

            tables.push({
                table: {
                    body: [
                        [
                            { text: q.question, colSpan: 2, style: reportStyleNames.tableHeader },
                            ''
                        ],
                        ...uniqueAnswers.map(answer => {
                            let votes = this.committeeVotes.filter(c => c.votingRights === appConstants.votingRights.eligible && c.answers[qIndex] === answer);
                            return [
                                { text: `${votes.length} X ${answer}` },
                                { text: votes.map(v => v.committeeMember).join(',') }
                            ]
                        })
                    ],
                    widths: ['20%', '*']
                },
                layout: reportLayoutNames.defaultTable
            });

            if (q.comments) {
                tables.push({ ...this.contentSpacer });
                tables.push({
                    table: {
                        body: [
                            [{ text: 'Comments', colSpan: 2, style: reportStyleNames.tableHeader }, ''],
                            ...q.comments.map(x => {
                                return [
                                    { text: x.commenter },
                                    { text: x.comment }
                                ]
                            })
                        ],
                        widths: ['20%', '*']
                    },
                    layout: reportLayoutNames.defaultTable
                },)
            }

            tables.push({ ...this.contentSpacer });
        });

        return tables;
    }

    public generateReport() {
        let voteResultBreakdownTable = this.generateBallotVoteResultBreakdownTable();
        let consensusCriteriaTable = this.generateConsensusCriteriaBreakdownTable();
        let questionsTable = this.generateQuestionTable();
        let committeeVotesTable = this.generateCommitteeVotesTable();
        let questionDetailsTable = this.generateQuestionDetailsTables();

        let docDefinition: TDocumentDefinitions = {
            pageSize: 'A4',
            pageMargins: [20, 30, 20, 30],
            defaultStyle: {
                fontSize: 7
            },
            styles: reportStyles,
            content: [
                {
                    text: this.documentHeader,
                    style: reportStyleNames.header
                },
                { ...this.contentSpacer },
                {
                    columns: [
                        this.generateBallotSummary(),
                        [
                            ...(voteResultBreakdownTable ? [voteResultBreakdownTable, { ...this.contentSpacer }] : []),
                            ...(consensusCriteriaTable ? [consensusCriteriaTable, { ...this.contentSpacer }] : []),
                            this.generateNotes()
                        ]
                    ],
                    columnGap: 10
                },
                { ...this.contentSpacer },
                ...(questionsTable ? [questionsTable, { ...this.contentSpacer }] : []),
                ...(committeeVotesTable ? [committeeVotesTable, { ...this.contentSpacer }] : []),
                ...(questionDetailsTable ? [questionDetailsTable, { ...this.contentSpacer }] : []),
            ]
        }

        pdfMake
            .createPdf(docDefinition as any, reportLayouts)
            .download(this.documentFilename);
    }
}

export interface IBallotReportCommitteeVote {
    committeeMember: string;
    organisation: string;
    role: string;
    country: string;
    votingRights: string;
    answers: string[];
}

export interface IBallotReportVoteResultsBreakdownPercentage {
    label: string;
    fillColor: string;
    value: number;
    percentage: number;
}

export interface IBallotReportVoteResultsBreakdown {
    country: string;
    totalNumberOfVotes: number;
    votePercentages: IBallotReportVoteResultsBreakdownPercentage[];
}

export interface IBallotReportQuestion {
    questionNumber: string;
    question: string;
    answerOptions?: string[];
    comments?: IBallotReportQuestionComments[];
}

export interface IBallotReportQuestionComments {
    commenter: string;
    comment: string;
}