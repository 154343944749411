import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { TableComponent } from './table/table.component';
import { LoadingIndicatorComponent } from './loading-indicator/loading-indicator.component';
import { SelectComponent } from './select/select.component';
import { InputComponent } from './input/input.component';
import { RadioGroupComponent } from './radio-group/radio-group.component';
import { MatRadioModule } from '@angular/material/radio';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ButtonExportToExcelComponent } from './button-export-to-excel/button-export-to-excel.component';
import { SelectMultipleComponent } from './select-multiple/select-multiple.component';
import { UpdateScrollerComponent } from './update-scroller/update-scroller.component';
import { UpdateScrollerCardComponent } from './update-scroller-card/update-scroller-card.component';
import { TabComponent } from './tab/tab.component';
import { CardComponent } from './card/card.component';
import { BadgeComponent } from './badge/badge.component';
import { StatusBadgeComponent } from './status-badge/status-badge.component';


@NgModule({
  declarations: [
    InputComponent,
    LoadingIndicatorComponent,
    SelectComponent,
    TableComponent,
    RadioGroupComponent,
    ButtonExportToExcelComponent,
    SelectMultipleComponent,
    UpdateScrollerComponent,
    UpdateScrollerCardComponent,
    TabComponent,
    CardComponent,
    BadgeComponent,
    StatusBadgeComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,

    MatFormFieldModule,
    MatPaginatorModule,
    MatProgressSpinner,
    MatRadioModule,
    MatSortModule,
    MatTableModule,
  ],
  exports: [
    InputComponent,
    LoadingIndicatorComponent,
    SelectComponent,
    TableComponent,
    RadioGroupComponent,
    ButtonExportToExcelComponent,
    SelectMultipleComponent,
    UpdateScrollerComponent,
    UpdateScrollerCardComponent,
    TabComponent,
    CardComponent,
    StatusBadgeComponent
  ]
})
export class ControlsModule { }
