import { Component, OnDestroy, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { faSearch, faX } from '@fortawesome/free-solid-svg-icons';
import orderBy from 'lodash/orderBy';
import uniq from 'lodash/uniq';
import { Subscription } from 'rxjs';
import { appConstants } from '../../shared/constants/app-constants';
import { IUser } from '../../shared/models/user';
import { AuthService } from '../../shared/services/auth.service';
import { UserUtility } from '../../shared/utilities/user-utility';
import { IExportSchema } from '../controls/models/export-to-excel';
import { IOption } from '../controls/models/option';
import { ITableColumn, TableColumnType } from '../controls/table/table-column';
import { ITableFilter } from '../controls/table/table-filter';
import { TableComponent } from '../controls/table/table.component';
import { SelectMultipleCommitteeComponent } from './select-multiple-committee/select-multiple-committee.component';
import { ICommitteeSearchResult } from './shared/models/committee-search-result';
import { CommitteeService } from './shared/services/committee.service';

interface ICommitteeSearchResultTableData extends ICommitteeSearchResult {
  internationalParticipations?: string[];
  myCommittee: boolean;
}

@Component({
  selector: 'app-committees',
  templateUrl: './committees.component.html',
  styleUrl: './committees.component.scss'
})
export class CommitteesComponent implements OnDestroy {
  @ViewChild(TableComponent) tableComponent!: TableComponent<ICommitteeSearchResultTableData>;

  faX = faX;
  faSearch = faSearch;

  currentUser!: IUser;
  canExport: boolean = false;

  loading: boolean = true;
  subscriptions: Subscription = new Subscription();
  localStorageFilterKey = 'committees-filter';
  tableId = appConstants.committeeTable.id;
  tableData: ICommitteeSearchResultTableData[] = [];
  tableColumns: ITableColumn<ICommitteeSearchResultTableData>[] = [
    {
      name: 'committeeCode',
      displayName: 'Committee Code',
      type: TableColumnType.routerLink,
      isSortable: true,
      getLink: (row: ICommitteeSearchResultTableData) => `/committees/${row.committeeCode}`,
      headerWidth: '220px'
    },
    {
      name: 'committeeName',
      displayName: 'Committee Title',
      isSortable: true,
    },
    {
      name: 'committeeStatus',
      displayName: 'Committee Status',
      isSortable: true
    },
    {
      name: 'internationalParticipations',
      displayName: 'International Participation',
      type: TableColumnType.arrayString,
      isSortable: true,
      headerWidth: '30%',
    },
    {
      name: 'sectorName',
      displayName: 'Sector',
      isSortable: true
    }
  ];
  tableFilters: ITableFilter[] = [];

  exportSchema: IExportSchema<ICommitteeSearchResultTableData>[] = [
    {
      propertyName: 'committeeCode',
      header: 'Designation',
    },
    {
      propertyName: 'committeeName',
      header: 'Committee Title'
    },
    {
      propertyName: 'committeeStatus',
      header: 'Committee Status'
    },
    {
      propertyName: 'internationalParticipations',
      header: 'International Participation',
      valueRenderer(row) {
        return row.internationalParticipations?.join(', ');
      },
    },
    {
      propertyName: 'sectorName',
      header: 'Sector'
    },
  ];

  viewType = {
    allCommittees: 'All Committees',
    myCommittees: 'My Committees'
  };
  viewTypeOptions: IOption<any>[] = [];

  searchForm = new FormGroup({
    searchTerm: new FormControl(''),
    viewType: new FormControl(this.viewType.allCommittees),

    internationalParticipations: new FormControl<string[]>([]),
    sectors: new FormControl<string[]>([]),
    statuses: new FormControl<string[]>([]),
  });

  internationalParticipationOptions: IOption<string>[] = [];

  constructor(
    private _authService: AuthService,
    private _committeeService: CommitteeService
  ) {
    const userSubscription = this._authService.currentUserSubject.subscribe(user => {
      if (user) {
        this.currentUser = user;
        this.initialize();
      }
    });

    this.subscriptions.add(userSubscription);
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  initialize() {
    this.initilizeCanExport();
    this.searchCommittees();

    const formValueChangesSubscription = this.searchForm.valueChanges.subscribe(change => {
      this.onFilterChangeEvent(change);
    });
    this.subscriptions.add(formValueChangesSubscription);

    this.initializeDefaultFilters();
    this.initializeViewTypeOptions();
  }

  initializeDefaultFilters() {
    const savedFilters = localStorage.getItem(this.localStorageFilterKey);
    if (savedFilters) {
      const filters = JSON.parse(savedFilters);
      this.searchForm.patchValue(filters);
    }
    else {
      this.searchForm.patchValue({ viewType: this.getDefaultViewType() });
    }
  }

  initializeViewTypeOptions() {
    if (UserUtility.isInternalUser(this.currentUser) || UserUtility.isCommitteeMemberWithCommittee(this.currentUser) || UserUtility.isNomOrgOnly(this.currentUser)) {
      this.viewTypeOptions = [
        { value: this.viewType.allCommittees, label: this.viewType.allCommittees },
        { value: this.viewType.myCommittees, label: this.viewType.myCommittees }
      ];

      return;
    }

    this.viewTypeOptions = [
      { value: this.viewType.allCommittees, label: this.viewType.allCommittees }
    ];
  }

  initilizeCanExport() {
    this.canExport = !(UserUtility.isMemberOfPublicOnly(this.currentUser) || (UserUtility.isCommitteeMember(this.currentUser) && this.currentUser.committees?.length < 1));
  }

  searchCommittees() {
    const searchProjects = this._committeeService.getCommittees().subscribe(response => {
      if (response.success) {
        const committeeCodes = UserUtility.getCommitteeCodes(this.currentUser);
        this.tableData = response.content.map(x => {
          return {
            ...x,
            internationalParticipations: x.internationalCommittees?.map(y => y.internationalCommitteeFullName),
            myCommittee: committeeCodes.includes(x.committeeCode)
          } as ICommitteeSearchResultTableData;
        });

        this.setInternationalParticipationsOptions();

        this.loading = false;
      }
    });

    this.subscriptions.add(searchProjects);
  }

  setInternationalParticipationsOptions() {
    const options: string[] = [];
    this.tableData.forEach(x => {
      if (x.internationalParticipations) {
        x.internationalParticipations
          .filter(p => p && p.length > 0)
          .forEach(p => options.push(p));
      }
    });

    const uniqueOptions = uniq(options);
    this.internationalParticipationOptions = orderBy(uniqueOptions).map(x => ({ value: x, label: x }));
  }

  onResetFilterClickEvent() {
    this.searchForm.patchValue({
      searchTerm: '',
      internationalParticipations: [],
      sectors: [],
      statuses: [],
      viewType: this.getDefaultViewType()
    });
  }

  getDefaultViewType() {
    return UserUtility.isInternalUser(this.currentUser) || UserUtility.isCommitteeMemberWithCommittee(this.currentUser)
      ? this.viewType.myCommittees
      : this.viewType.allCommittees;
  }

  onFilterChangeEvent(values: typeof this.searchForm.value) {
    if (!values) {
      return;
    }

    const filters: ITableFilter[] = [];

    if (values.viewType === this.viewType.myCommittees) {
      filters.push({
        properties: ['myCommittee'],
        value: true
      });
    }

    if (values.internationalParticipations && values.internationalParticipations.length > 0) {
      filters.push({
        properties: ['internationalParticipations'],
        value: values.internationalParticipations
      });
    }

    if (values.sectors && values.sectors.length > 0) {
      filters.push({
        properties: ['sectorName'],
        value: values.sectors
      });
    }

    if (values.statuses && values.statuses.length > 0) {
      filters.push({
        properties: ['committeeStatus'],
        value: values.statuses
      });
    }

    if (values.searchTerm && values.searchTerm.trim().length > 0) {
      const searchTerm = values.searchTerm.trim();
      filters.push({
        properties: ['committeeCode', 'committeeName'],
        value: searchTerm
      });
    }

    this.tableFilters = filters;

    localStorage.setItem(this.localStorageFilterKey, JSON.stringify(values));
  }
}
