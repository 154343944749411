import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { IApiResponse } from '../../../shared/models/api-response';
import { IUser } from '../../../shared/models/user';
import { AuthService } from '../../../shared/services/auth.service';
import { ICommittee } from '../shared/models/committee';
import { CommitteeService } from '../shared/services/committee.service';

@Component({
  selector: 'app-committee-details',
  templateUrl: './committee-details.component.html',
  styleUrl: './committee-details.component.scss'
})
export class CommitteeDetailsComponent implements OnDestroy {

  currentUser!: IUser;

  loading: boolean = true;
  subscriptions: Subscription = new Subscription();

  committee: ICommittee | null = null;

  constructor(
    private _authService: AuthService,
    private _committeeService: CommitteeService,
    private _activatedRoute: ActivatedRoute
  ) {
    const userSubscription = this._authService.currentUserSubject.subscribe(user => {
      if (user) {
        this.currentUser = user;
        this.initialize();
      }
    });

    this.subscriptions.add(userSubscription);
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  initialize() {
    this.getCommittee();
  }

  getCommittee() {
    const committeeCode = this._activatedRoute.snapshot.paramMap.get('code');
    if (!committeeCode){
      this.loading = false;
      return;
    }

    const committeeSubscription = this._committeeService
      .getCommittee(committeeCode, true)
      .subscribe({
        next: (response: IApiResponse<ICommittee>) => {
          if (response.success) {
            this.committee = response.content;
          }

          this.loading = false;
        },
        error: _ => {
          this.loading = false;
        },
      });

    this.subscriptions.add(committeeSubscription);
  }
}
