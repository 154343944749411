<ng-container *ngIf="!loading">
  <ng-container
    *ngIf="
      !searchResults ||
      !searchResults.documents ||
      !(searchResults.documents.length > 0)
    "
  >
    <div class="flex justify-center items-center h-full">
      <p>Found 0 matching documents.</p>
    </div>
  </ng-container>

  <ng-container
    *ngIf="
      searchResults &&
      searchResults.documents &&
      searchResults.documents.length > 0
    "
  >
    <div
      [class.grid-layout]="searchResultLayout === searchResultLayoutEnum.Grid"
      [class.list-layout]="searchResultLayout === searchResultLayoutEnum.List"
    >
      <app-document-search-result-card
        *ngFor="let item of searchResults.documents; index as i"
        [searchResultItem]="item"
        [index]="i"
        [layout]="searchResultLayout"
      ></app-document-search-result-card>
    </div>
  </ng-container>
</ng-container>

<ng-container *ngIf="loading">
  <div class="flex justify-center items-center h-full">
    <app-loading-indicator [size]="32"></app-loading-indicator>
  </div>
</ng-container>
