<ng-container *ngIf="canViewProjectUpdates">
  <div class="page-padding-x mb-6">
    <div class="flex flex-row justify-between items-center">
      <div class="flex items-center">
        <h2>Project Updates</h2>
      </div>
      <div
        class="flex flex-row justify-end items-center gap-6 [&>div:not(:first-child)]:border-l-2"
      >
        <div
          *ngFor="let phase of expectedPhases"
          class="flex flex-row items-center gap-3 px-3 border-solid border-black"
          [id]="'phase-count-' + phase.replaceAll(' ', '')"
        >
          <ng-container *ngIf="getProjectsByPhase(phase) > 0">
            <div class="text-5xl font-bold" name="count">
              {{ getProjectsByPhase(phase) }}
            </div>
            <div class="text-gray-400" name="label">
              Publications in <br />
              {{ phase }}
            </div>
          </ng-container>
        </div>
        <a
          class="btn btn-primary"
          [routerLink]="['/projects']"
          [queryParams]="viewMyProjectsParams"
          id="btn-view-projects"
        >
          {{ viewProjectsButtonLabel }}
        </a>
      </div>
    </div>
  </div>

  <ng-container *ngIf="!loading && projects && projects.length > 0">
    <app-update-scroller
      [title]="'New Updates'"
      [updateCount]="projects.length"
      id="scroller-project-updates"
    >
      <a
        *ngFor="let project of projects"
        [routerLink]="['/projects', project.projectId]"
        [id]="'card-project-updates-' + project.projectId"
      >
        <app-update-scroller-card
          [title]="project.title"
          [description]="project.description"
        >
          <div headerLeft name="date">
            {{ project.date | date : "dd/MM/yyyy" }}
          </div>
          <app-status-badge
            [colourScheme]="statusBadgeColourSchemeEnum.Green"
            headerRight
            name="phase-{{ project.projectId }}"
          >
            {{ project.phase }}
          </app-status-badge>
        </app-update-scroller-card>
      </a>
    </app-update-scroller>
  </ng-container>
</ng-container>
