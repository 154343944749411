import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IOption } from '../models/option';

interface ITab {
  label: string;
  id: string;
}

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrl: './tab.component.scss'
})
export class TabComponent implements OnInit {
  @Input() tabs: ITab[] = [];
  @Input() initialTabId: string | null = null;
  @Input() id: string = 'app-tab';
  @Output() onTabChange = new EventEmitter<string>();

  selectedTab: string = '';

  ngOnInit(): void {
    if (this.initialTabId && this.initialTabId.trim().length > 0) {
      const tab = this.tabs.find(tab => tab.id === this.initialTabId);
      if (tab) {
        this.onSelectTabClickEvent(tab);
        return;
      }
    }

    if (this.tabs.length > 0) {
      this.onSelectTabClickEvent(this.tabs[0]);
    }
  }

  get tabAsOptions(): IOption<string>[] {
    return this.tabs?.map(tab => ({ label: tab.label, value: tab.id })) ?? [];
  }

  onSelectTabClickEvent(tab: ITab): void {
    this.selectedTab = tab.id;
    this.onTabChange.emit(tab.id);
  }
}
