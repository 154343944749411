import { Component } from '@angular/core';
import { ConfigurationService } from '../../shared/services/configuration.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.scss'
})
export class SettingsComponent {

  constructor(
    private _configService: ConfigurationService
  ) {
    this._configService.getAppUrls()
    .subscribe({
      next: (appUrls) => {
        if (appUrls?.oldConnectUiUrl) {
          window.open(`${appUrls.oldConnectUiUrl}/#/usersetting`);
        }
      },
      error: (error) => {
        // TODO: Log to SumoLogic;
      }
    });
  }
}
