<div *ngIf="currentUser">
  <h1 class="mb-1 page-padding-x page-padding-y" id="h-dashboard-page">
    Welcome back {{ currentUser.firstName }}
  </h1>
  <p class="text-gray-400 page-padding-x">Connect Workspace</p>
  
  <div *ngIf="canViewProjectUpdates" class="dashboard-section-grey pt-6">
    <app-project-updates></app-project-updates>
  </div>

  <div class="dashboard-section-grey page-padding-x">    
    <app-connect-links id="connect-links-section"></app-connect-links>
  </div>

  <hr />
  <br />
</div>
