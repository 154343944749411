<div *ngIf="currentUser">
  <div
    id="title-user-guides-and-videos"
    class="page-title-section page-padding-x page-padding-y"
  >
    <h2 class="underline-yellow">User Guides and Videos</h2>
  </div>

  <div
    id="committee-workspace-guides-and-videos"
    class="bg-grey-100 page-padding-x"
  >
    <h3 class="section-title">Committee Workspace</h3>
    <div class="section-content">
      <app-links-grid
        id="committee-workspace-links"
        [links]="committeeWorkspaceLinks"
        (actionClicked)="onActionClicked($event)"
      ></app-links-grid>
    </div>
  </div>
  <div id="systematic-review-guides-and-videos" class="page-padding-x">
    <h3 class="section-title">Systematic Review</h3>
    <div class="section-content">
      <app-links-grid
        id="systematic-review-links"
        [links]="systematicReviewLinks"
        (actionClicked)="onActionClicked($event)"
      ></app-links-grid>
    </div>
  </div>
</div>
