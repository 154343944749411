import { StyleDictionary } from "pdfmake/interfaces";

export const reportStyleNames = {
  header: 'header',
  tableHeader: 'tableHeader'
}

export const reportStyles: StyleDictionary = {
  [reportStyleNames.header]: {
    fontSize: 11,
    color: '#366A9E'
  },
  [reportStyleNames.tableHeader]: {
    bold: true,
    fillColor: 'lightgray'
  }
}