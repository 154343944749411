import { ContentTable } from "pdfmake/interfaces";
import { BallotReport } from "./ballot-report";
import { appConstants } from "../../../../../shared/constants/app-constants";

export class IecBallotReport extends BallotReport {
  override committeeVoteColumns: string[] = [
    'Committee Member',
    'Organisation',
    'Role',
    'Country',
    'Voting Rights',
    'Vote'
  ];

  override ballotVoteResultColumns: string[] = [
    'Ballot Result',
    'Total',
    appConstants.iecBallots.voteOptions.inFavourOf.displayName,
    appConstants.iecBallots.voteOptions.against.displayName,
    appConstants.iecBallots.voteOptions.abstain.displayName,
    appConstants.iecBallots.voteOptions.noResponse.displayName,
  ];

  constructor() {
    super();
  }

  override generateBallotVoteResultBreakdownTable(): ContentTable | null {
    let content = super.generateBallotVoteResultBreakdownTable();
    if (content) {
      content.table.widths = ['20%', '10%', '17.5%', '17.5%', '17.5%', '17.5%'];
    }

    return content;
  }

  override generateCommitteeVotesTable(): ContentTable | null {
    let content = super.generateCommitteeVotesTable();
    if (content) {
      content.table.widths = ['20%', '30%', '15%', '10%', '9%', '16%'];
    }

    return content;
  }

  override generateQuestionTable(): ContentTable | null {
    return null;
  }
}
