<div class="flex flex-col">
  <h5 id="h5-ProjectDetails" class="mt-4">Project Details</h5>
  <div *ngIf="committee" class="mt-4">
    <div id="div-committee">
      <label>Committee</label>
      <p>
        <a
          class="text-link"
          id="a-committee"
          [routerLink]="['/committees', committee.committeeCode]"
        >
          {{ committee.committeeCode }} {{ committee.committeeName }}
        </a>
      </p>
    </div>
    <div id="div-committeeChair" *ngIf="canViewAllCommitteeDetails">
      <label>Committee Chair</label>
      <p>
        <a
          class="text-link"
          id="a-committeeChair"
          href="mailto:{{ committee.committeeChairEmail }}"
        >
          {{ committee.committeeChair }}
        </a>
      </p>
    </div>
    <div id="div-engagementManager">
      <label>Engagment Manager</label>
      <p>
        <a
          class="text-link"
          id="a-engagementManager"
          href="mailto:{{ committee.stakeholderEngagementManagerEmail }}"
        >
          {{ committee.stakeholderEngagementManagerName }}
        </a>
      </p>
    </div>
    <div id="div-projectManager" *ngIf="canViewAllCommitteeDetails">
      <label>Project Manager</label>
      <p>
        <a
          class="text-link"
          id="a-projectManager"
          href="mailto:{{ committee.committeeProjectManagerEmail }}"
        >
          {{ committee.committeeProjectManagerName }}
        </a>
      </p>
    </div>
    <div id="div-programManager" *ngIf="canViewAllCommitteeDetails">
      <label>Program Manager</label>
      <p>
        <a
          class="text-link"
          id="a-programManager"
          href="mailto:{{ committee.committeeProgramManagerEmail }}"
        >
          {{ committee.committeeProgramManagerName }}
        </a>
      </p>
    </div>
    <div id="div-committeeType">
      <label>Type</label>
      <p id="p-committeeType">{{ committee.committeeType }}</p>
    </div>
  </div>

  <div
    *ngIf="loading || !committee"
    class="flex justify-center items-center min-h-[40vh]"
  >
    <div *ngIf="loading">
      <app-loading-indicator [size]="32"></app-loading-indicator>
    </div>
    <div *ngIf="!loading && !committee">Unable to load project details</div>
  </div>
</div>
