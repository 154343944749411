import { Component, forwardRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../shared/services/auth.service';
import { IOption } from '../../controls/models/option';
import { SelectMultipleComponent } from '../../controls/select-multiple/select-multiple.component';
import { CommitteeService } from '../shared/services/committee.service';
import uniq from 'lodash/uniq';
import orderBy from 'lodash/orderBy';

@Component({
  selector: 'app-select-multiple-sector',
  templateUrl: './select-multiple-sector.component.html',
  styleUrl: './select-multiple-sector.component.scss',  
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectMultipleSectorComponent),
      multi: true
    }
  ]
})
export class SelectMultipleSectorComponent implements ControlValueAccessor, OnDestroy {

  @ViewChild('selectMultiple') selectMultipleComponent!: SelectMultipleComponent;

  @Input() id: string = 'select-sector';
  @Input() label: string = 'Sector';

  onChange: any = () => { };
  onTouched: any = () => { };

  private _values: any[] | undefined;
  get values() {
    return this._values;
  }
  set values(values) {
    this._values = values;
    this.onChange(values);
    this.onTouched();
  }

  loading: boolean = true;
  subscriptions: Subscription = new Subscription();
  options: IOption<string>[] = [];

  constructor(
    private _authService: AuthService,
    private _committeeService: CommitteeService,
  ) {
    const userSubscription = this._authService.currentUserSubject.subscribe(user => {
      if (user) {
        this.getCommittees();
      }
    });

    this.subscriptions.add(userSubscription);
  }

  writeValue(values: any[]): void {
    this.values = values;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  getCommittees() {
    const searchCommittees = this._committeeService.getSectors().subscribe(response => {
      if (response.success) {
        const uniqueCommittees = uniq(response.content);
        this.options = orderBy(uniqueCommittees)
          .map(x => ({ value: x, label: x }));
      }

      this.loading = false;
    });

    this.subscriptions.add(searchCommittees);
  }
}

