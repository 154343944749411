import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IConnectLinkItem } from '../../models/connect-link-item';

@Component({
  selector: 'app-links-grid',
  templateUrl: './links-grid.component.html',
  styleUrl: './links-grid.component.scss'
})
export class LinksGridComponent {
  @Input() id: string = '';
  @Input() links: IConnectLinkItem[] = [];

  @Output() actionClicked: EventEmitter<IConnectLinkItem> = new EventEmitter<IConnectLinkItem>();

  onButtonClick(event: MouseEvent) : void {
    const target = event.currentTarget as HTMLElement;

    if(target?.id) {
      const link = this.links.find(x => x.id === target.id);
      if(link) {
        this.actionClicked.emit(link);
      }
    }
  }
}
