import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { authGuard } from '../../shared/guards/auth.guard';
import { ReportsDashboardComponent } from './reports/reports-dashboard/reports-dashboard.component';
import { GuidesAndVideosComponent } from './guides-and-videos/guides-and-videos.component';
import { ReportsEmbededComponent } from './reports/reports-embeded/reports-embeded.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [authGuard],
  },
  {
    path: 'reports',
    component: ReportsDashboardComponent,
    canActivate: [authGuard],
  },
  {
    path: 'report/:reportType',
    component: ReportsEmbededComponent,
    canActivate: [authGuard],
  },
  {
    path: 'guides-and-videos',
    component: GuidesAndVideosComponent,
    canActivate: [authGuard],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes),],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
