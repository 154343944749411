<div class="sm:hidden">
    <app-select [id]="'select-tab' + id" [(ngModel)]="selectedTab" label=" " [options]="tabAsOptions" id="select-tab"></app-select>
</div>
<div class="hidden sm:block border-b border-gray-200 mb-8">
    <nav class="-mb-px flex space-x-8" aria-label="Tabs">
        <a *ngFor="let tab of tabs"
            class="whitespace-nowrap border-b-2 border-transparent px-1 py-3 font-bold text-lg hover:text-black cursor-pointer"
            (click)="onSelectTabClickEvent(tab)"
            [ngClass]="selectedTab === tab.id ? 'text-black border-b-brand-orange' : 'text-gray-500'"
            [id]="'tab-' + id + '-' + tab.id"
        >
            {{ tab.label }}
        </a>
    </nav>
</div>