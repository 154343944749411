<div class="w-full flex flex-col mb-4">
    <label *ngIf="label" [attr.for]="id" class="mb-2 block text-sm font-medium leading-6 text-gray-900">{{label}}</label>
    <div *ngIf="loading" class="h-[48px] w-full flex justify-center items-center border border-gray-300 rounded">
        <app-loading-indicator [size]="25"></app-loading-indicator>
    </div>

    <ng-container *ngIf="!loading">
        <select [(ngModel)]="value" [attr.id]="id" [attr.name]="id"
            class="block w-full rounded-md border-0 py-3 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-brand-orange sm:text-sm sm:leading-6 outline-none"
        >
            <option
                *ngFor="let option of options"
                [value]="option.value"
                [selected]="option.value === value"
            >
                {{option.label}}
            </option>
        </select>

        <!-- this uses angular material - but style overrides are needed -->
        <!-- <mat-form-field>
            <mat-select [(ngModel)]="value">
                <mat-option
                    *ngFor="let option of options"
                    [value]="option.value"
                >
                    {{option.label}}
                </mat-option>
            </mat-select>
        </mat-form-field> -->
    </ng-container>
</div>
