import { IBlobFile } from "../models/blob-file";
import FileDownload from 'js-file-download';

export class BlobDownloadUtility {

    public static downloadBlobToBrowser(blob: IBlobFile): void {
        const blobData = base64ToByteArray(blob.blobContent);
        const fileName = blob.overview.metadata.filename;
        const mimeType = getMimeTypeFromFilename(fileName);

        const blobObject = new Blob([blobData], { type: mimeType });
        FileDownload(blobObject, blob.overview.metadata.filename, mimeType);

        return;
    }
}

function base64ToByteArray(base64: string): Uint8Array {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes;
}

function getMimeTypeFromFilename(fileName: string) : string {
    let mimeType = '';

    if (!fileName || fileName.length === 0) {
        return '';
    }

    const fileExtension = fileName.split('.').pop();

    if (fileExtension === 'pdf') {
        mimeType = 'application/pdf';
    } else if (fileExtension === 'mp4') {
        mimeType = 'video/mp4';
    } else {
        mimeType = '';
    }

    return mimeType;
}
