import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import writeXlsxFile from 'write-excel-file';
import { IIecBallotDetails } from '../../shared/models/iec-ballot-details';
import { IecBallotService } from '../../shared/services/iec-ballot.service';

@Component({
  selector: 'app-iec-details',
  templateUrl: './iec-details.component.html',
  styleUrl: './iec-details.component.scss'
})
export class IecDetailsComponent implements OnInit {

  public loading: boolean = true;
  public ballot: IIecBallotDetails | null = null;

  constructor(
    private route: ActivatedRoute,
    private iecBallotService: IecBallotService
  ) {

  }

  ngOnInit(): void {
    const ballotId = this.route.snapshot.paramMap.get('id');
    if (!ballotId) {
      this.loading = false;
      // TODO: what to do when ballotId is not available, redirect to some other page?
      return;
    }

    this.getBallot(ballotId);

    // this is to test http interceptor:
    // call refresh token when a request is made and failed since token has expired
    // this will trigger a refresh token request and then retry the original request
    // access token expiration was adjusted to 70 seconds during the time this was tested
    setTimeout(() => {
      this.getBallot(ballotId);
    }, 70 * 1000)
  }

  getBallot(ballotId: string) {
    this.iecBallotService.getBallot(ballotId).subscribe((response) => {
      if (response.success) {
        this.ballot = response.content;
      }
    });
  }

  testExcelFile() {
    const testData = [{
      value: 'Hello'
    }]

    writeXlsxFile([
      testData
    ], {
      fileName: 'test.xlsx'      
    })
  }
}