import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {IReportEmbedConfiguration, service, models, factories } from 'powerbi-client';
import { ReportService } from '../shared/services/report.service';
import { ReportType } from '../shared/models/report-type';

@Component({
  selector: 'app-reports-embeded',
  templateUrl: './reports-embeded.component.html',
  styleUrls: ['./reports-embeded.component.scss']
})
export class ReportsEmbededComponent implements OnInit{

  reportType: ReportType | undefined;
  @ViewChild('reportContainer') reportContainer!: ElementRef;

  reportConfig: IReportEmbedConfiguration = {
    type: 'report',
    embedUrl: undefined,
    tokenType: models.TokenType.Embed,
    accessToken: undefined,
    settings: undefined,
  };

  constructor(
    private _reportService: ReportService,
    private router: Router,
    public route: ActivatedRoute
  ) {

  }

  ngOnInit(): void {
    this.initialize();
  }

  initialize() {
    this.route.params.subscribe(params => {
      if (params['reportType']) {
        const reportTypeParam = params['reportType'];
        // Convert the string parameter to the enum value
        this.reportType = ReportType[reportTypeParam as keyof typeof ReportType];

        if (this.reportType !== undefined) {
          this.embedReport(this.reportType);
        } else {
          console.error(`Invalid report type: ${reportTypeParam}`);
          this.router.navigate(['/reports']);
        }
      }
    });
  }

  embedReport(reportType: ReportType) {
    this._reportService.getPowerBiEmbed(reportType).subscribe
      (response => {
        if(response.success){
          const reportConfigResponse = response.content;
          this.reportConfig = {
            ...this.reportConfig,
            id: reportConfigResponse.embedReport[0].reportId,
            embedUrl: reportConfigResponse.embedReport[0].embedUrl,
            accessToken: reportConfigResponse.embedToken.token,
          };

          const reportContainer = this.reportContainer;
          const powerbi = new service.Service(factories.hpmFactory, factories.wpmpFactory, factories.routerFactory);
          const report = powerbi.embed(reportContainer.nativeElement, this.reportConfig);

          report.off("loaded");
          report.on("loaded", () => {
            console.log("Loaded");
          });
        }
  });
  }

}
