import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommitteesComponent } from './committees.component';
import { CommitteesRoutingModule } from './committees-routing.module';
import { SelectMultipleCommitteeComponent } from './select-multiple-committee/select-multiple-committee.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ControlsModule } from '../controls/controls.module';
import { CommitteeDetailsComponent } from './committee-details/committee-details.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SelectMultipleSectorComponent } from './select-multiple-sector/select-multiple-sector.component';
import { SelectMultipleCommitteeStatusComponent } from './select-multiple-committee-status/select-multiple-committee-status.component';
import { RecentlyViewedCommittteesComponent } from './recently-viewed-committtees/recently-viewed-committtees.component';
import { ButtonEmailCommitteeMembersComponent } from './button-email-committee-members/button-email-committee-members.component';



@NgModule({
  declarations: [
    CommitteesComponent,
    SelectMultipleCommitteeComponent,
    CommitteeDetailsComponent,
    SelectMultipleSectorComponent,
    SelectMultipleCommitteeStatusComponent,
    RecentlyViewedCommittteesComponent,
    ButtonEmailCommitteeMembersComponent
  ],
  imports: [
    CommonModule,
    CommitteesRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ControlsModule,
    FontAwesomeModule,
  ],
  exports: [
    SelectMultipleCommitteeComponent
  ]
})
export class CommitteesModule { }
