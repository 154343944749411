import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SaLibraryComponent } from './sa-library.component';
import { SaLibraryRoutingModule } from './sa-library-routing.module';



@NgModule({
  declarations: [
    SaLibraryComponent
  ],
  imports: [
    CommonModule,
    SaLibraryRoutingModule
  ]
})
export class SaLibraryModule { }
