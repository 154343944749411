<div
  [class.hidden]="
    loading || !projectDetails || !authorized || votes.length === 0
  "
  class="flex-grow flex flex-col justify-between gap-4 hi"
>
  <div class="grid grid-cols-2 gap-4">
    <div>
      <label
        *ngIf="latestVoteDateWording"
        for="latest-vote-date"
        class="font-normal"
      >
        Results
        <span id="latest-vote-date" class="font-semibold">{{
          latestVoteDateWording
        }}</span>
      </label>
    </div>
    <div class="flex flex-col">
      <label class="font-normal" for="balloting-start-date">
        Balloting start date:
        <span id="balloting-start-date">
          {{ projectDetails?.ballotStartDate | date : "dd/MM/yyyy" }}
        </span>
      </label>
      <label class="font-normal" for="balloting-end-date">
        Balloting end date:
        <span id="balloting-end-date">
          {{ projectDetails?.ballotEndDate | date : "dd/MM/yyyy" }}
        </span>
      </label>
    </div>
  </div>

  <div class="flex-grow grid grid-cols-2 gap-4 border-b border-gray-200 py-4">
    <div class="flex flex-grow max-h-64" id="div-ballot-pieChart">
      <canvas id="chart-ballot" #chartBallot></canvas>
    </div>

    <div class="flex flex-col justify-center pr-4" id="chart-ballot-legend">
      <div class="border-b border-gray-200 flex flex-row justify-between py-4">
        <label
          ><span [style.color]="positiveColour">&#9632;</span> Positive</label
        >
        <label id="positive-vote-count">{{ positiveVotesCount }}</label>
      </div>
      <div class="border-b border-gray-200 flex flex-row justify-between py-4">
        <label
          ><span [style.color]="negativeColour">&#9632;</span> Negative</label
        >
        <label id="negative-vote-count">{{ negativeVotesCount }}</label>
      </div>
      <div class="flex flex-row justify-between py-4">
        <label
          ><span [style.color]="notVotedColour">&#9632;</span> Not voted</label
        >
        <label id="not-voted-count">{{ notVotedCount }}</label>
      </div>
    </div>
  </div>

  <div *ngIf="showConnectViewButton" class="flex flex-row">
    <a
      id="a-view-in-public-commenting"
      class="btn btn-primary"
      [routerLink]="['/ballots/sd', projectDetails?.sdBallotId]"
      target="_blank"
    >
      View Ballot
    </a>
  </div>
</div>

<div
  *ngIf="!loading && projectDetails && authorized && votes.length === 0"
  class="flex-grow flex justify-center items-center"
>
  <p>Unable to load ballot details</p>
</div>

<div *ngIf="loading" class="flex-grow flex justify-center items-center">
  <app-loading-indicator [size]="32"></app-loading-indicator>
</div>
