import { Component, Input } from '@angular/core';
import { StatusBadgeColourScheme } from '../../controls/status-badge/status-badge-constants';
import { faFile } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-document-type-badge',
  templateUrl: './document-type-badge.component.html',
  styleUrl: './document-type-badge.component.scss'
})
export class DocumentTypeBadgeComponent {
  public statusBadgeColourSchemeEnum = StatusBadgeColourScheme;

  @Input() documentType: string = '';
  @Input() name: string = '';
}
