import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { IApiResponse } from '../../../shared/models/api-response';
import { IUser } from '../../../shared/models/user';
import { AuthService } from '../../../shared/services/auth.service';
import { IProjectDetails } from '../shared/models/project-details';
import { ProjectService } from '../shared/services/project.service';

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrl: './project-details.component.scss'
})
export class ProjectDetailsComponent implements OnDestroy {

  currentUser!: IUser;

  loading: boolean = true;
  subscriptions: Subscription = new Subscription();

  projectDetails: IProjectDetails | null = null;
  selectedTab: string = '';

  constructor(
    private _authService: AuthService,
    private _projectService: ProjectService,
    private _activatedRoute: ActivatedRoute
  ) {
    const userSubscription = this._authService.currentUserSubject.subscribe(user => {
      if (user) {
        this.currentUser = user;
        this.initialize();
      }
    });

    this.subscriptions.add(userSubscription);
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  initialize() {
    this.getProjectDetails();
  }

  getProjectDetails() {
    const projectId = this._activatedRoute.snapshot.paramMap.get('id');
    if (!projectId) return;

    const projectDetailsSubscription = this._projectService
      .getProjectDetails(Number(projectId))
      .subscribe({
        next: (response: IApiResponse<IProjectDetails>) => {
          if (response.success) {
            this.projectDetails = response.content;
          }

          this.loading = false;
        },
        error: _ => {
          this.loading = false;
        },
      });

    this.subscriptions.add(projectDetailsSubscription);
  }

  onTabChange(tabId: string) {
    this.selectedTab = tabId;
  }
}