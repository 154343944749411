<div class="bg-grey-200 page-padding-y pb-6">
  <ng-container *ngIf="committee && !loading">
    <div class="page-padding-x flex flex-col 2xl:flex-row gap-2 2xl:gap-0">
      <h1 class="w-full 2xl:w-1/3 break-words">
        <span id="h1-committee-code" class="text-darkBlue-500">
          {{ committee.committeeCode }}
        </span>
        <br /><br />
        <span id="h1-committee-title">{{ committee.committeeTitle }}</span>
      </h1>
      <div
        class="w-full 2xl:w-2/3 flex flex-wrap gap-2 justify-end place-items-end"
      >
        <app-button-export-committee-members
          [committee]="committee"
        ></app-button-export-committee-members>

        <ng-container *ngIf="projects">
          <app-button-export-committee-details
            [committee]="committee"
            [projects]="projects"
          ></app-button-export-committee-details>
        </ng-container>

        <app-button-email-committee-members
          [committeeCode]="committee.committeeCode"
          [displayAsButton]="true"
        ></app-button-email-committee-members>

        <app-button-schedule-committee-meeting
          [committee]="committee"
        ></app-button-schedule-committee-meeting>
      </div>
    </div>
  </ng-container>

  <div class="mt-2 2xl:mt-8">
    <app-recently-viewed-committtees
      (onFinishLoading)="onRecentlyViewedCommitteeFinishedLoading()"
      [reloadRecentlyViewedCommittees]="reloadRecentlyViewedCommittees"
    ></app-recently-viewed-committtees>
  </div>
</div>

<div
  class="flex-1 pt-6 bg-grey-100 page-padding-x page-padding-b max-w-[100vw]"
>
  <ng-container *ngIf="!loading">
    <ng-container *ngIf="!committee">
      <div class="flex justify-center items-center h-full">
        <p>Unable to get committee details</p>
      </div>
    </ng-container>
    <ng-container *ngIf="committee">
      <app-tab
        #appTab
        [tabs]="getTabs()"
        id="committeesTab"
        (onTabChange)="onTabChange($event)"
      >
      </app-tab>

      <div [class.hidden]="selectedTab !== this.tabIds.overview">
        <app-card>
          <app-committee-details-overview
            [committee]="committee"
          ></app-committee-details-overview>
        </app-card>
      </div>
      <div
        [class.hidden]="selectedTab !== this.tabIds.internationalParticipation"
      >
        International Participation
      </div>
      <div [class.hidden]="selectedTab !== this.tabIds.committeeMembership">
        <app-committee-details-committee-membership
          [committee]="committee"
        ></app-committee-details-committee-membership>
      </div>
      <div [class.hidden]="selectedTab !== this.tabIds.projects">
        <app-committee-details-project
          [projects]="projects"
        ></app-committee-details-project>
      </div>
      <div [class.hidden]="selectedTab !== this.tabIds.publications">
        <app-committee-details-publication
          [publications]="publications" [hasLoadingPublications] = "hasLoadingPublications"
        ></app-committee-details-publication>
      </div>
      <div
        [class.hidden]="
          selectedTab !== this.tabIds.subCommitteesAndWorkingGroups
        "
      >
        <app-sub-committees
          [subCommittees]="subCommittees"
        ></app-sub-committees>
      </div>
      <div
        *ngIf="hasAccessToDeclarationOfInterest"
        [class.hidden]="selectedTab !== this.tabIds.declarationOfInterest"
      >
        Declaration of Interest
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="loading">
    <div class="flex justify-center items-center h-full">
      <app-loading-indicator [size]="32"></app-loading-indicator>
    </div>
  </ng-container>
</div>
