<div class="text-center">
    <app-loading-indicator *ngIf="loading" [size]="16"></app-loading-indicator>
</div>

<a *ngIf="!loading && mailToHref" href="{{mailToHref}}" (click)="$event.stopPropagation()" 
    class="text-sm text-brand-darkBlue flex items-center gap-2"
>
    <span class="ml-2">
        <fa-icon [icon]="faEnvelope"></fa-icon>
    </span>
    Email all members
</a>