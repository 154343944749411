<div class="w-full flex flex-col mb-4">
    <label *ngIf="label" [attr.for]="id" class="mb-2 block text-sm font-medium leading-6 text-gray-900">
        {{label}}
    </label>
    <div *ngIf="loading" class="h-12 w-full flex justify-center items-center border border-gray-300 rounded">
        <app-loading-indicator [size]="25"></app-loading-indicator>
    </div>

    <ng-container *ngIf="!loading">
        <div class="relative">
            <button #toggleButton
                type="button" 
                class="flex items-center relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-brand-orange sm:text-sm sm:leading-6 h-12 brightness-100"
                [class.ring-brand-orange]="isMenuOpen"
                (click)="onSelectButtonClick()"
                role="select"
                aria-haspopup="listbox" 
                [attr.id]="id" 
                [attr.aria-expanded]="isMenuOpen" 
                [attr.aria-labelledby]="id">
                <span class="block truncate">{{ getSelectedLabel() }}</span>
            </button>

            <div #menu
                class="absolute z-10 w-full rounded-md bg-white py-1 text-base shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                [class.hidden]="!isMenuOpen"
            >
                <div class="flex justify-between items-center pt-4 pb-2 px-3">
                    <span class="block truncate">{{ getSelectedLabel() }}</span>
                    <span class="underline text-gray-400 cursor-pointer"
                        (click)="onClearAllClick()"
                    >
                        Clear All
                    </span>
                </div>
                <div *ngIf="showFilter" class="relative py-2 px-4 bg-grey-200">
                    <div class="absolute inset-y-0 start-0 flex items-center ps-7 pointer-events-none text-gray-400">            
                        <fa-icon [icon]="faSearch"></fa-icon>
                    </div>
                    <input 
                        [(ngModel)]="filterText" 
                        [id]="id + '-filterText'" 
                        placeholder="Search"
                        class="block w-full rounded-sm border-0 py-2 pl-3 pr-10 text-gray-900 ring-0 outline-none ps-10"
                    />
                </div>
                <ul class="max-h-60 w-full overflow-auto scrollbar" tabindex="-1" role="listbox" [attr.aria-labelledby]="id">
                    <li class="relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900"
                        *ngFor="let option of getOptions()" role="option" 
                        [id]="'listbox-option-' + option.value"
                        (click)="onSelectOptionClick(option.value)"
                        [class.bg-blue-50]="values && values.includes(option.value)"
                    >
                        <span class="block truncate font-normal">{{option.label}}</span>
                        <span class="absolute inset-y-0 right-0 flex items-center pr-4 text-brand-darkBlue"
                            *ngIf="values && values.includes(option.value)">
                            <fa-icon [icon]="faCheck"></fa-icon>
                        </span>
                    </li>
                </ul>

            </div>

        </div>
    </ng-container>
</div>