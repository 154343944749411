/**
* does not handle child objects
*/
export const objectToQueryString = (obj: any): string => {
   var queryString: string = '';

   if (obj) {
       Object.keys(obj).forEach((key) => {
           var val = obj[key];
           if (val === undefined || val === null) {

           }
           else if (Array.isArray(val)) {
               for (let i = 0; i < val.length; i++) {
                   queryString += `&${key}=${val[i]}`;
               }
           }
           else {
               queryString += `&${key}=${val}`;
           }
       });
   }

   return queryString.replace('&', '?');
}


export const deepCopy = <T>(value: T): T => {
    // Check if the value is null or not an object/array
    if (value === null || typeof value !== 'object') {
        return value;
    }

    // Handle Date
    if (value instanceof Date) {
        return new Date(value.getTime()) as unknown as T;
    }

    // Handle Array
    if (Array.isArray(value)) {
        return value.map(item => deepCopy(item)) as unknown as T;
    }

    // Handle Object
    // If the value is an object, use Object.keys to get all the keys of the object.
    // Use reduce to iterate over the keys and accumulate a new object with deep-copied properties.    
    if (value instanceof Object) {
        return Object.keys(value).reduce((acc, key) => {
            acc[key] = deepCopy((value as { [key: string]: any })[key]);
            return acc;
        }, {} as { [key: string]: any }) as T;
    }

    throw new Error('Unable to copy value! Its type isn\'t supported.');
};


export default {
    objectToQueryString,
    deepCopy

}