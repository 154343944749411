import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { IAppUrls } from '../../../shared/models/app-urls';
import { IUser } from '../../../shared/models/user';
import { AuthService } from '../../../shared/services/auth.service';
import { ConfigurationService } from '../../../shared/services/configuration.service';
import { SamlService } from '../../../shared/services/saml.service';
import { UserUtility } from '../../../shared/utilities/user-utility';
import { PermissionService } from '../../../shared/services/permission.service';
import { Router } from '@angular/router';

// requires SF Lightning Out library to be loaded
declare var $Lightning: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnDestroy {
  sfAppLauncherContainerId: string = 'sfAppLauncherContainer';

  currentUser: IUser | null = null;
  isInternalUser = false;

  appUrls: IAppUrls | null = null;

  sfAccessToken: string | null = null;
  isSalesforceJsLoaded = false;

  canAccessNormOrgPage = false;
  canAccessStandardsLibraryPage = false;
  canAccessStandardsLibrarySimPage = false;
  canAccessBallotsPage = false;
  canAccessCommitteeWorkspacePage = false;

  subscriptions: Subscription = new Subscription();

  isDashboardActive: boolean = false;
  private activePaths: string[] = [
    '/dashboard',
    '/reports',
    '/report',
    '/guides-and-videos'
  ];

  constructor(
    private _authService: AuthService,
    private _samlService: SamlService,
    private _configService: ConfigurationService,
    private _permissionService: PermissionService,
    private router: Router,
  ) {

    const currentUserSubscription = this._authService.currentUserSubject.subscribe((user) => {
      this.currentUser = user;
      if (user) {
        this.initialize();
      }
    });

    this.subscriptions.add(currentUserSubscription);
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  initialize() {
    if (!this.currentUser) {
      return;
    }

    this.isInternalUser = UserUtility.isInternalUser(this.currentUser);

    this.canAccessBallotsPage = UserUtility.canAccessBallotsPage(this.currentUser);
    this.canAccessNormOrgPage = UserUtility.canAccessNomOrgPage(this.currentUser);
    this.canAccessStandardsLibraryPage = UserUtility.canAccessStandardsLibraryPage(this.currentUser);
    this.canAccessStandardsLibrarySimPage = UserUtility.canAccessStandardsLibrarySimPage(this.currentUser);
    this.canAccessCommitteeWorkspacePage = UserUtility.canAccessCommitteeWorkspacePage(this.currentUser);

    const appUrlSubscription = this._configService.getAppUrls()
      .subscribe({
        next: (appUrls) => {
          if (appUrls) {
            this.loadSalesforceJsLibrary(environment.salesForceAppLauncherUrl);
            this.appUrls = appUrls;
          }
        },
        error: (error) => {
          // TODO: Log to SumoLogic;
        }
      });


      this.subscriptions.add(appUrlSubscription);

      this.router.events.subscribe(() => {
          this.checkIfDashboardActive();
      })
  }

  loadSalesforceJsLibrary(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = true;
    script.defer = true;
    body.appendChild(script);

    this.isSalesforceJsLoaded = true;
  }

  handleLogout() {
    this._authService.logout();
  }

  handleOpenAppLauncher(callback?: () => void) {
    if (!this.isSalesforceJsLoaded) {
      return;
    }

    if (this.sfAccessToken) {
      this.openAppLauncher(this.sfAccessToken, callback);
      return;
    }

    this.getSfAccessTokenThenOpenAppLauncher(callback);
  }

  handleOpenAppLauncherPublicComment() {
    this.handleOpenAppLauncher();
  }

  handleOpenAppLauncherCommitteeWorkspace() {
    this.handleOpenAppLauncher();
  }

  private openAppLauncher(accessToken: string, callback?: () => void) {
    if (!accessToken || !this.currentUser) {
      return;
    }

    const sfAppLauncherContainerId = this.sfAppLauncherContainerId;
    const request = {
      sessionId: accessToken,
      partnerServerUrl: this.currentUser.partnerServerURL,
      userId: this.currentUser.userIdCustom,
      userType: this.currentUser.userType
    }

    const createCommunitiesAppLauncher = function () {
      $Lightning.createComponent("c:CommunitiesAppLauncherExternal", request, sfAppLauncherContainerId, callback);
    };

    $Lightning.use("c:testLightningOut", createCommunitiesAppLauncher, this.appUrls?.iamUrl,);
  }

  private getSfAccessTokenThenOpenAppLauncher(callback?: () => void) {
    this._samlService.getSfAccessToken().subscribe(response => {
      if (response.success) {
        this.sfAccessToken = response.content;
        this.openAppLauncher(this.sfAccessToken, callback);
      }
    });
  }

  private checkIfDashboardActive(){
    const currentUrl = this.router.url;
    this.isDashboardActive = this.activePaths.some(path => currentUrl.startsWith(path));
  }
}
