<div class="bg-grey-200 page-padding-y pb-6">
    <ng-container *ngIf="committee && !loading">
        <div class="page-padding-x mb-3">
            <h1 class="w-1/2">
                <span class="text-darkBlue-500">
                    {{committee.committeeCode}}
                </span>
                <br /><br />
                {{committee.committeeTitle}}
            </h1>
        </div>
    </ng-container>
</div>

<div class="flex-1 pt-6 bg-grey-100 page-padding-x page-padding-b">
    <ng-container *ngIf="!loading">
        <ng-container *ngIf="!committee">
            <div class="flex justify-center items-center h-full">
                <p>Unable to get committee details</p>
            </div>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="loading">
        <div class="flex justify-center items-center h-full">
            <app-loading-indicator [size]="32"></app-loading-indicator>
        </div>
    </ng-container>    
</div>