import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { IApiResponse } from '../../../../shared/models/api-response';
import { convertStringDateToJSDate } from '../../../../shared/utilities/date-utility';
import { ISdBallotVote } from '../models/sd/sd-ballot-vote';

@Injectable({
  providedIn: 'root'
})
export class SdBallotService {

  baseUrl: string = `${environment.servicesUrl.ballot}api/ballots/sd`;

  constructor(
    private http: HttpClient,
  ) { }

  public getVotesByProjectId(projectId: number): Observable<IApiResponse<ISdBallotVote[]>> {
    return this.http
      .get<IApiResponse<ISdBallotVote[]>>(`${this.baseUrl}/projects/${projectId}/votes`)
      .pipe(
        map((rawResponse) => {
          let response = { ...rawResponse };
          response.content.forEach(x => x.lastUpdated = convertStringDateToJSDate(x.lastUpdated as any));

          return response;
        })
      );
  }
}
