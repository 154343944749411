import { Content } from "pdfmake/interfaces";

const cleanHtml = (value: string): string => {
    if (!value) return '';

    return new DOMParser()
        .parseFromString(value, 'text/html').documentElement.textContent
        ?.replace(/(<([^>]+)>)/gi, "") ?? '';
}

export const getContentFromHtmlString = (htmlString: string): Content[] => {
    if ((htmlString?.trim()?.length ?? 0) === 0)
        return [];

    let result: Content[] = [];

    htmlString = htmlString.replace(/<br>/g, "\n").replace(/<br\/>/g, "\n").replace(/<\/p>/g, "\n");

    if (htmlString.indexOf('<li>') === -1) {
        result.push({ text: cleanHtml(htmlString) });
        return result;
    }

    while (htmlString.indexOf('<li>') !== -1) {
        let ulStart = htmlString.indexOf('<ul>');
        let olStart = htmlString.indexOf('<ol>');
        let ulFirst = (olStart !== -1 && ulStart < olStart) || olStart === -1;

        if (ulFirst && ulStart !== -1 && htmlString.indexOf('</ul>') !== -1) {
            let splitted = htmlString.split(/<li>(.*)/s); // split first occurence only
            result.push({ text: cleanHtml(splitted[0]) }); // add text before start of <ul>

            splitted = splitted[1].split(/<\/ul>(.*)/s); // split first occurence of end ul tag
            htmlString = splitted[1]; // assign to value for further processing (if there are more list)

            result.push({ ul: splitted[0].split('</li>').map(x => cleanHtml(x)) });
            continue;
        }
        else if (olStart !== -1 && htmlString.indexOf('</ol>') !== -1) {
            let splitted = htmlString.split(/<li>(.*)/s);
            result.push({ text: cleanHtml(splitted[0]) });

            splitted = splitted[1].split(/<\/ol>(.*)/s);
            htmlString = splitted[1];

            result.push({ ol: splitted[0].split('</li>').map(x => cleanHtml(x)) });
            continue;
        }
        else {
            // not properly formatted
            htmlString = htmlString
                .replace(/<li>/g, "\u200B\t")
                .replace(/<\/li>/g, "\n");

            result.push({ text: cleanHtml(htmlString) });
            htmlString = '';
        }
    }

    if (htmlString) {
        result.push({ text: cleanHtml(htmlString) });
    }

    return result;
}

export default {
    getContentFromHtmlString
}