<div class="connect-links-section">
  <ng-container *ngFor="let link of links">
    <a
      *ngIf="!link.routerLink && !link.action && link.show"
      id="{{ link.id }}"
      class="link-item"
      href="{{ link.href }}"
      target="_blank"
    >
      <span class="link-text">{{ link.displayText }}</span>
    </a>

    <a
      *ngIf="link.routerLink && link.show"
      id="{{ link.id }}"
      class="link-item"
      routerLink="{{ link.routerLink }}"
    >
      <span class="link-text">{{ link.displayText }}</span>
    </a>

    <button
      type="button"
      *ngIf="link.action && link.show"
      id="{{ link.id }}"
      class="link-item"
      (click)="onButtonClick($event)"
    >
      <span class="link-text">{{ link.displayText }}</span>
    </button>
  </ng-container>
</div>
