import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { IApiResponse } from '../../../../shared/models/api-response';
import { convertStringDateToJSDate } from '../../../../shared/utilities/date-utility';
import { objectToQueryString } from '../../../../shared/utilities/object-utility';
import { ICommittee } from '../models/committee';
import { ICommitteeSearchFilter } from '../models/committee-search-filter';
import { ICommitteeSearchResult } from '../models/committee-search-result';
import { IRecentlyViewedCommittee } from '../models/recently-viewed-committee';

@Injectable({
  providedIn: 'root'
})
export class CommitteeService {

  baseUrl: string = `${environment.servicesUrl.committee}api/committees`;

  constructor(
    private http: HttpClient,
  ) { }

  getCommittees(filter?: ICommitteeSearchFilter): Observable<IApiResponse<ICommitteeSearchResult[]>> {
    let queryString = '';
    if (filter) {
      queryString = objectToQueryString(filter);
    }

    return this.http
      .get<IApiResponse<ICommitteeSearchResult[]>>(`${this.baseUrl}${queryString}`);
  }

  getCommittee(committeeCode: string, logViewRecord?: boolean): Observable<IApiResponse<ICommittee>> {
    let queryString = '';
    if (logViewRecord) {
      queryString = objectToQueryString({logViewRecord});
    }

    return this.http
      .get<IApiResponse<ICommittee>>(`${this.baseUrl}/${committeeCode}${queryString}`);
  }

  getSectors(): Observable<IApiResponse<string[]>> {
    return this.http
      .get<IApiResponse<string[]>>(`${this.baseUrl}/sectors`);
  }
}
