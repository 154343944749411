<div class="w-full bg-brand-darkBlue flex justify-between items-stretch text-white px-10 pt-2">
    <div class="flex items-center gap-10">
        <app-sa-logo></app-sa-logo>
        
        <span class="copyright text-[15.5px] pb-1">
            Copyright © {{year}} Standards Australia
        </span>
    </div>

    <div class="flex items-center gap-2 lg:gap-10 pb-0.5 children:text-[15.5px]">
        <a target="_blank" id="a-contact-standards-australia" href="https://www.standards.org.au/contact">Contact</a>                
        <a target="_blank" id="a-privacy-policy" href="https://www.standards.org.au/privacy-policy">Privacy Policy</a>
        <a target="_blank" id="a-conditions-of-use" href="https://www.standards.org.au/home/applications-conditions-of-use">Applications - Conditions of Use</a>
    </div>
</div>