import { IUser } from '../models/user';
import { appConstants } from '../constants/app-constants';

export class UserUtility {

  static isInternalUser(user: IUser | null): boolean {
    UserUtility.throwIfInvalidUser(user);
    const checkedUser = user as IUser;
    return checkedUser.userType === appConstants.userType.internal;
  }

  static hasRoles(user: IUser | null, ...roles: string[]): boolean {
    UserUtility.throwIfInvalidUser(user);
    
    const checkedUser = user as IUser;

    if (!checkedUser.role) {
      return false;
    }

    const userRoles = checkedUser.role.split(',');
    return userRoles.some((userRole) => roles.includes(userRole));
  }

  static hasExactRoles(user: IUser | null, ...roles: string[]): boolean {
    UserUtility.throwIfInvalidUser(user);
    
    const checkedUser = user as IUser;

    if (!checkedUser.role) {
      return false;
    }

    const userRoles = checkedUser.role.split(',');
    if(userRoles.length !== roles.length) {
      return false;
    }    
    return userRoles.every((userRole) => roles.includes(userRole));
  }

  static isCommitteeMember(user: IUser | null): boolean {
    UserUtility.throwIfInvalidUser(user);
    const checkedUser = user as IUser;
    return UserUtility.hasRoles(checkedUser, appConstants.userRole.committeeMember);
  }
  
  static isCommitteeMemberWithCommittee(user: IUser | null): boolean {
    UserUtility.throwIfInvalidUser(user);
    const checkedUser = user as IUser;
    return UserUtility.isCommitteeMember(checkedUser) && checkedUser.committees?.length > 0;
  }

  static isMemberOfPublicOnly(user: IUser): boolean {
    UserUtility.throwIfInvalidUser(user);
    return user.role === appConstants.userRole.public;
  }

  static isNomOrgOnly(user: IUser): boolean {
    return UserUtility.hasExactRoles(user, appConstants.userRole.nominatingOrganisation, appConstants.userRole.public);
  }


  /// <summary>
  /// Check if User Role is above MoP.  Exclude MoP, ECommerce, Parliamentarian
  /// </summary>    
  static userIsAboveMoP(user: IUser | null): boolean {

    return UserUtility.hasRoles(
        user,
        appConstants.userRole.admin,
        appConstants.userRole.committeeMember,
        appConstants.userRole.generalManagerOperations,
        appConstants.userRole.itSupport,
        appConstants.userRole.moderator,
        appConstants.userRole.nominatingOrganisation,
        appConstants.userRole.programManager,
        appConstants.userRole.projectManager,
        appConstants.userRole.staff
    );
  }

  static throwIfInvalidUser(user: IUser | null): void {
    if (!user) {
      throw new Error('Invalid User. User is null.');
    }
  }

  static getCommitteeCodes(user: IUser): string[] {
    return user.committees.split(',');
  }

  static userIsOnCommittee(user: IUser, ...committees: string[]): boolean {
    this.throwIfInvalidUser(user);

    return UserUtility.getCommitteeCodes(user).some((userCommittee) => committees.includes(userCommittee));
  }

  static canAccessNomOrgPage(user: IUser): boolean {
    return UserUtility.hasRoles(
      user,
      appConstants.userRole.admin,
      appConstants.userRole.itSupport,
      appConstants.userRole.generalManagerOperations,
      appConstants.userRole.nominatingOrganisation
    );
  }

  static canAccessStandardsLibraryPage(user: IUser): boolean {
    return UserUtility.hasRoles(
      user,
      appConstants.userRole.committeeMember,
      appConstants.userRole.nominatingOrganisation,
      appConstants.userRole.parliamentaryMemberAccess
    );
  }

  static canAccessStandardsLibrarySimPage(user: IUser): boolean {
    return !UserUtility.canAccessStandardsLibraryPage(user) && user.role !== appConstants.userRole.public;
  }

  static canAccessBallotsPage(user: IUser): boolean {
    return UserUtility.hasRoles(
      user,
      appConstants.userRole.admin,
      appConstants.userRole.committeeMember,
      appConstants.userRole.generalManagerOperations,
      appConstants.userRole.itSupport,
      appConstants.userRole.moderator,
      appConstants.userRole.programManager,
      appConstants.userRole.projectManager,
      appConstants.userRole.staff
    );
  }

  static canAccessCommitteeWorkspacePage(user: IUser): boolean {
    return !(user.role === appConstants.userRole.public || UserUtility.hasExactRoles(user, appConstants.userRole.nominatingOrganisation, appConstants.userRole.public));
  }

  static canViewProjectUpdates(user: IUser): boolean {
    return !(UserUtility.isMemberOfPublicOnly(user) || UserUtility.isNomOrgOnly(user));
  }
}
