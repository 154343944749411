import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-update-scroller-card',
  templateUrl: './update-scroller-card.component.html',
  styleUrl: './update-scroller-card.component.scss'
})
export class UpdateScrollerCardComponent {
  @Input() title!: string;
  @Input() description!: string;
}
