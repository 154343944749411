// TODO: remove properties that are not needed anymore
export interface IUser {
    userId: string;
    userName: string;
    firstName: string;
    lastName: string;
    title: string;
    email: string;
    role: string;
    committees: string;
    objectId: string;
    sessionId: string;
    userType: string;
    sessionExpiry: string;
    userIdCustom: string;
    partnerServerURL: string;
    features: string;
}

export class User implements IUser {
    userId: string;
    userName: string;
    firstName: string;
    lastName: string;
    title: string;
    email: string;
    role: string;
    committees: string;
    objectId: string;
    sessionId: string;
    userType: string;
    sessionExpiry: string;
    userIdCustom: string;
    partnerServerURL: string;
    features: string;

    constructor() {
        this.userId = '';
        this.userName = '';
        this.firstName = '';
        this.lastName = '';
        this.title = '';
        this.email = '';
        this.role = '';
        this.committees = '';
        this.objectId = '';
        this.sessionId = '';
        this.userType = '';
        this.sessionExpiry = '';
        this.userIdCustom = '';
        this.partnerServerURL = '';
        this.features = '';
    }

    IsNotPublicMember() {
        return this.role != 'public';
    }

    hasAccessToCommittee(committeeCode: string) {
        const baseCommitteeCode = committeeCode.split('-').slice(0, 2).join('-');
        return this.committees.indexOf(baseCommitteeCode) !== -1;
    }
}