import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import orderBy from 'lodash/orderBy';
import { Subscription } from 'rxjs';
import { IUser } from '../../../../shared/models/user';
import { AuthService } from '../../../../shared/services/auth.service';
import { UserUtility } from '../../../../shared/utilities/user-utility';
import { ITableColumn, TableColumnType } from '../../../controls/table/table-column';
import { ITableFilter } from '../../../controls/table/table-filter';
import { ICommittee } from '../../shared/models/committee';
import { ICommitteeMember } from '../../shared/models/committee-member';
import { IOption } from '../../../controls/models/option';
import uniq from 'lodash/uniq';

@Component({
  selector: 'app-committee-details-committee-membership',
  templateUrl: './committee-details-committee-membership.component.html',
  styleUrl: './committee-details-committee-membership.component.scss'
})
export class CommitteeDetailsCommitteeMembershipComponent implements OnDestroy, OnChanges {
  @Input() committee: ICommittee | null = null;

  loading: boolean = true;
  subscriptions: Subscription = new Subscription();

  currentUser: IUser | null = null;

  tableData: ICommitteeMember[] = [];
  tableColumns: ITableColumn<ICommitteeMember>[] = [];
  tableFilters: ITableFilter[] = [];
  roles: IOption<string>[] = [];
  canFilterRoles: boolean = false;

  searchForm = new FormGroup({
    roles: new FormControl<string[]>([]),
  });

  constructor(
    private _authService: AuthService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    const committee = changes['committee'];
    if (!committee?.currentValue) {
      return;
    }

    const userSubscription = this._authService.currentUserSubject.subscribe(user => {
      if (user) {
        this.currentUser = user;
        this.initialize();
      }
    });

    this.subscriptions.add(userSubscription);
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  initialize() {
    this.loading = true;

    if (!this.committee) {
      this.loading = false;
      return;
    }

    this.initializeCanFilterRoles();
    this.initializeFormSubscriptions();    
    this.initializeTableData();    
    this.initializeTableColumns();
    this.initializeRoles();

    this.loading = false;
  }

  initializeFormSubscriptions() {
    if(!this.canFilterRoles) return;

    const formValueChangesSubscription = this.searchForm.valueChanges.subscribe(change => {
      this.onFilterChangeEvent(change);
    });
    this.subscriptions.add(formValueChangesSubscription);
  }

  initializeCanFilterRoles() {
    if (this.currentUser && this.committee) {
      this.canFilterRoles = UserUtility.isInternalUser(this.currentUser) || UserUtility.userIsOnCommittee(this.currentUser, this.committee.committeeCode) || UserUtility.accessCommitteeAsSCWG(this.currentUser, this.committee.committeeCode);
    }
  }

  initializeRoles() {
    if (this.canFilterRoles && this.committee?.membershipDetails) {
      let roles = this.committee.membershipDetails
        .filter(x => x.membershipType && x.membershipType.trim().length > 0)
        .map(x => x.membershipType);

      roles = uniq(roles);
      this.roles = orderBy(roles).map(x => ({ value: x, label: x }));
    }
  }

  initializeTableData() {
    if(!this.committee?.membershipDetails){
      this.tableData = [];
      return
    }

    if(this.canFilterRoles){
      this.tableData = this.committee.membershipDetails;
    } else {
      let nomOrgs = this.committee.membershipDetails
        .filter(x => x.membershipNomOrg && x.membershipNomOrg.trim().length > 0)
        .map(x => x.membershipNomOrg);

      nomOrgs = uniq(nomOrgs);
      this.tableData = nomOrgs.map(nomOrg => {
        return {
          membershipNomOrg: nomOrg,
        } as ICommitteeMember as any;
      });
    }
  }

  initializeTableColumns() {
    let columns: ITableColumn<ICommitteeMember>[] = [{
      name: 'membershipNomOrg',
      displayName: 'Nominating Organisation',
      isSortable: true,
    }];

    if (this.committee) {
      if (this.canFilterRoles) {
        columns = [
          ...columns,
          {
            name: 'memberFullName',
            displayName: 'Name',
            isSortable: true,
          },
          {
            name: 'memberEmail',
            displayName: 'Email',
            type: TableColumnType.link,
            isSortable: true,
            getLink: (row: ICommitteeMember) => `mailto:${row.memberEmail}`,
          },
          {
            name: 'membershipType',
            displayName: 'Role',
            isSortable: true,
          }
        ]
      }
    }

    this.tableColumns = columns;
  }

  onFilterChangeEvent(values: typeof this.searchForm.value) {
    if (!values) {
      return;
    }

    const filters: ITableFilter[] = [];

    if (values.roles && values.roles.length > 0) {
      filters.push({
        properties: ['membershipType'],
        value: values.roles
      });
    }

    this.tableFilters = filters;
  }
}

