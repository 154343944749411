import { toZonedTime } from 'date-fns-tz'; 

export const convertStringDateToJSDate = (date: string | null): Date | null => {
    // add additional logic here if necessary    
    if (!date) {
        return null;
    }
    
    return new Date(date);
}

export const getCurrentDateForAustraliaSydney = (): Date => {
    return toZonedTime(new Date(), 'Australia/Sydney');
}

export default {
    convertStringDateToJSDate,
    getCurrentDateForAustraliaSydney
}