import { Component, EventEmitter, Input, Output, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../shared/services/auth.service';
import { IRecentlyViewedCommittee } from '../shared/models/recently-viewed-committee';
import { CommitteeUserService } from '../shared/services/committee-user.service';
import { Router } from '@angular/router';
import { IUser } from '../../../shared/models/user';

@Component({
  selector: 'app-recently-viewed-committtees',
  templateUrl: './recently-viewed-committtees.component.html',
  styleUrl: './recently-viewed-committtees.component.scss'
})
export class RecentlyViewedCommittteesComponent implements OnDestroy, OnChanges {

  @Input() reloadRecentlyViewedCommittees: number = 0;
  @Output() onFinishLoading = new EventEmitter<boolean>();

  user: IUser | null = null;
  loading: boolean = true;
  subscriptions: Subscription = new Subscription();

  committees: IRecentlyViewedCommittee[] | null = null;

  constructor(
    private _authService: AuthService,
    private _committeeUserService: CommitteeUserService,
    private _router: Router
  ) {
    const userSubscription = this._authService.currentUserSubject.subscribe(user => {
      if (user) {
        this.user = user;
        this.initialize();
      }
    });

    this.subscriptions.add(userSubscription);
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const reload = changes['reloadRecentlyViewedCommittees'];
    if (!this.user || !reload?.currentValue || reload.firstChange) {
      return;
    }
    else {
      this.initialize();
    }    
  }

  initialize() {
    this.getRecentlyViewedCommittees();
  }

  getRecentlyViewedCommittees() {
    const recentlyViewedCommittees = this._committeeUserService
      .getRecentlyViewedCommittees()
      .subscribe({
        next: response => {
          if (response.success) {
            this.committees = response.content;
          }

          this.loading = false;
          this.onFinishLoading.emit(true);
        },
        error: _ => {
          this.loading = false;
          this.onFinishLoading.emit(true);
        },
      });

    this.subscriptions.add(recentlyViewedCommittees);
  }

  /*
  * Navigates to the committee page with the given committee code regardless of the current route/page
  * as it forces the page to reload
  * @param committeeCode The code of the committee to navigate to
  */
  goToCommitteePage(committeeCode: string) {
    this._router.navigateByUrl(`/`, { skipLocationChange: true }).then(_ => {
      this._router.navigate([`/committees/${committeeCode}`]);
    });
  }
}
