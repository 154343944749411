<div class="flex flex-col">
  <h2>Committee Details</h2>
  <div *ngIf="committee" class="mt-4">
    <div class="flex flex-col lg:flex-row">
      <div class="lg:w-1/3">
        <div id="div-sector">
          <label>Sector</label>
          <p>{{ committee.sectorDetails?.sectorName ?? "" }}</p>
        </div>
      </div>
      <div class="lg:w-2/3">
        <div id="div-scopeAreaOfActivity">
          <label>Scope (Area Of Activity)</label>
          <div [innerHTML]="sanitizedTermsOfReference"></div>
        </div>
      </div>
    </div>

    <div class="border-b border-gray-200 my-8"></div>

    <div class="flex flex-col lg:flex-row">
      <div class="lg:w-1/3">
        <div
          id="div-committeeChair"
          *ngIf="isShowingField(committee.committeeChair)"
        >
          <label>Committee Chair</label>
          <p>
            <a
              class="text-link"
              id="a-committeeChair"
              href="mailto:{{ committee.committeeChairEmail }}"
            >
              {{ committee.committeeChair }}
            </a>
          </p>
        </div>
        <div
          id="div-projectManager"
          *ngIf="isShowingField(committee.committeeProjectManagerName)"
        >
          <label>Project Manager</label>
          <p>
            <a
              class="text-link"
              id="a-projectManager"
              href="mailto:{{ committee.committeeProjectManagerEmail }}"
            >
              {{ committee.committeeProjectManagerName }}
            </a>
          </p>
        </div>
        <div
          id="div-secondaryProjectManager"
          *ngIf="
            isShowingFieldAsNoMop(
              committee.committeeSecondaryProjectManagerName
            )
          "
        >
          <label>Secondary Project Manager</label>
          <p>
            <a
              class="text-link"
              id="a-secondaryProjectManager"
              href="mailto:{{
                committee.committeeSecondaryProjectManagerEmail
              }}"
            >
              {{ committee.committeeSecondaryProjectManagerName }}
            </a>
          </p>
        </div>
        <div
          id="div-programManager"
          *ngIf="isShowingField(committee.committeeProgramManagerName)"
        >
          <label>Program Manager</label>
          <p>
            <a
              class="text-link"
              id="a-programManager"
              href="mailto:{{ committee.committeeProgramManagerEmail }}"
            >
              {{ committee.committeeProgramManagerName }}
            </a>
          </p>
        </div>
        <div id="div-engagementManager">
          <label>Engagment Manager</label>
          <p>
            <a
              class="text-link"
              id="a-engagementManager"
              href="mailto:{{ committee.stakeholderEngagementManagerEmail }}"
            >
              {{ committee.stakeholderEngagementManagerName }}
            </a>
          </p>
        </div>
        <div
          id="div-independentFacilitator"
          *ngIf="
            isShowingFieldAsNoMop(committee.committeeIndependentFacilitatorName)
          "
        >
          <label>Independent Facilitator</label>
          <p>
            <a
              class="text-link"
              id="a-independentFacilitator"
              href="mailto:{{ committee.committeeIndependentFacilitatorEmail }}"
            >
              {{ committee.committeeIndependentFacilitatorName }}
            </a>
          </p>
        </div>
        <div
          id="div-technicalWriter"
          *ngIf="isShowingFieldAsNoMop(committee.committeeTechnicalWriterName)"
        >
          <label>Technical Writer</label>
          <p>
            <a
              class="text-link"
              id="a-technicalWriter"
              href="mailto:{{ committee.committeeTechnicalWriterEmail }}"
            >
              {{ committee.committeeTechnicalWriterName }}
            </a>
          </p>
        </div>
      </div>
      <div class="lg:w-2/3">
        <div
          id="div-liasonFromTo"
          *ngIf="canViewAllCommitteeDetails && liasonDetailsFrom.length > 0"
        >
          <label>Liason FROM this committee TO</label>
          <a
            *ngFor="let liaisonDetail of liasonDetailsFrom"
            [id]="'a-liasonFromTo-' + liaisonDetail.commiteeCode"
            [routerLink]="['/committees', liaisonDetail.commiteeCode]"
            class="text-link block text-ellipsis whitespace-nowrap overflow-hidden"
          >
            <div class="flex gap-3">
              <div>{{ liaisonDetail.commiteeCode }}</div>
              <div>{{ liaisonDetail.commiteeTitle }}</div>
            </div>
          </a>
        </div>

        <div
          id="div-liasonToFrom"
          *ngIf="canViewAllCommitteeDetails && liasonDetailsTo.length > 0"
          class="mt-3"
        >
          <label>Liason TO this committee FROM</label>
          <a
            *ngFor="let liaisonDetail of liasonDetailsTo"
            [id]="'a-liasonToFrom-' + liaisonDetail.commiteeCode"
            [routerLink]="['/committees', liaisonDetail.commiteeCode]"
            class="text-link block text-ellipsis whitespace-nowrap overflow-hidden"
          >
            <div class="flex gap-3">
              <div>{{ liaisonDetail.commiteeCode }}</div>
              <div>{{ liaisonDetail.commiteeTitle }}</div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="loading || !committee"
    class="flex justify-center items-center min-h-[40vh]"
  >
    <div *ngIf="loading">
      <app-loading-indicator [size]="32"></app-loading-indicator>
    </div>
    <div *ngIf="!loading && !committee">Unable to load committee details</div>
  </div>
</div>
