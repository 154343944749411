import { iecBallotVoteOptions } from "./iec-ballot.constants";

// declare all constants here to make it more readable in code
// if constants are too big, then create a separate file for it ex: ballot-types.constants.ts
export const appConstants = {
    localStorageKey: {
        token: 'token',
    },
    iecBallots: {
        voteOptions: iecBallotVoteOptions
    },
    publicationStatus: {
        balloting: "PS_BALLOTING",
        ballotingCompleted: "PS_BALLOTING_COMPLETED",
        commenting: "PS_COMMENTING",
        commentingAndBalloting: "PS_COMMENTINGANDBALLOTING",
        commentingCompleted: "PS_COMMENTING_COMPLETED",
        drafting: "PS_DRAFTING",
        published: "PS_PUBLISHED",
    },
    projects: {
        viewTypes: {
            myProjects: 'My Projects',
        }
    },
    queryParams: {
        view: 'view',
    },
    userRole: {
        admin: "admin",
        committeeMember: "committeemember",
        generalManagerOperations: "GMO",
        itSupport: "IT-SUPPORT",
        moderator: "moderator",
        nominatingOrganisation: "nomorg",
        parliamentaryMemberAccess: "PMA",
        programManager: "pgmgr",
        projectManager: "pm",
        public: "public",
        staff: "staff",        
    },
    userType: {
        internal: 'Internal',
        external: 'External'
    },
    votingRights: {
        eligible: 'Eligible',
    }
}