import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { orderBy } from 'lodash';
import { Subscription } from 'rxjs';
import { appConstants } from '../../../../../shared/constants/app-constants';
import { IUser } from '../../../../../shared/models/user';
import { AuthService } from '../../../../../shared/services/auth.service';
import { ConfigurationService } from '../../../../../shared/services/configuration.service';
import { getUpdateTimeStampWording } from '../../../../../shared/utilities/date-utility';
import { UserUtility } from '../../../../../shared/utilities/user-utility';
import { ISdBallotVote } from '../../../../ballots/shared/models/sd/sd-ballot-vote';
import { SdBallotService } from '../../../../ballots/shared/services/sd-ballot.service';
import { IPieChartDataItem } from '../../../shared/models/pie-chart-data-item';
import { IProjectDetails } from '../../../shared/models/project-details';
import { ChartjsService } from '../../../shared/services/chartjs.service';

@Component({
  selector: 'app-project-details-overview-ballot',
  templateUrl: './project-details-overview-ballot.component.html',
  styleUrl: './project-details-overview-ballot.component.scss'
})
export class ProjectDetailsOverviewBallotComponent implements OnDestroy, OnChanges {

  positiveColour: string = '#6589F7';
  negativeColour: string = '#BDCBFC';
  notVotedColour: string = '#ABABAB';

  @Input() projectDetails: IProjectDetails | null = null;
  @ViewChild('chartBallot') canvas: ElementRef<HTMLCanvasElement> = {} as ElementRef<HTMLCanvasElement>;

  currentUser: IUser | null = null;
  votes: ISdBallotVote[] = [];

  authorized: boolean = false;
  loading: boolean = true;
  subscriptions: Subscription = new Subscription();

  commentingStartDate: Date | null = null;
  commentingEndDate: Date | null = null;
  commentPeriodStatus: string = '';

  positiveVotesCount: number = 0;
  negativeVotesCount: number = 0;
  notVotedCount: number = 0;
  latestVoteDateWording: string = '';

  showConnectViewButton: boolean = false;

  constructor(
    private chartjsService: ChartjsService,
    private _authService: AuthService,
    private _sdBallotService: SdBallotService,
    private _configService: ConfigurationService,
  ) { }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['projectDetails']) {
      this.projectDetails = changes['projectDetails'].currentValue;
      if (!this.projectDetails) return;

      const userSubscription = this._authService.currentUserSubject.subscribe(user => {
        if (user) {
          this.currentUser = user;

          this.initialise();
        }
      });

      this.subscriptions.add(userSubscription);
    }
  }

  initialise(): void {
    if (!this.projectDetails || !this.currentUser) {
      return;
    }

    this.authorized = UserUtility.canViewSdBallotDetails(this.currentUser, this.projectDetails.committeeCode);

    if (!this.authorized) {
      this.loading = false;
      return;
    }

    this.getBallotVotes();
    this.initialiseViewButtons();
  }

  getBallotVotes() {
    if (!this.projectDetails || !this.currentUser) {
      return;
    }

    this._sdBallotService
    .getVotesByProjectId(this.projectDetails.projectId)
    .subscribe({
      next: (response) => {
        this.votes = response.success ? response.content : [];
        
        if (this.votes.length > 0) {
          const eligibleVotes = this.votes.filter(x => x.isEligible);
          const positiveVotes = eligibleVotes.filter(x => x.voteId === appConstants.sdBallots.voteOptions.agree);
          const negativeVotes = eligibleVotes.filter(x => x.voteId === appConstants.sdBallots.voteOptions.disagree);

          this.positiveVotesCount = positiveVotes.length;
          this.negativeVotesCount = negativeVotes.length;
          this.notVotedCount = eligibleVotes.length - positiveVotes.length - negativeVotes.length;

          const latestVote = orderBy(this.votes, ['lastUpdated'], ['desc'])[0];
          if (latestVote.voteId !== appConstants.sdBallots.voteOptions.notCast && latestVote.lastUpdated) {
            this.latestVoteDateWording = getUpdateTimeStampWording(latestVote.lastUpdated)
          }
        }
        
        this.createChart();
        this.loading = false;                    
      },
      error: _ => {
        this.loading = false;
      },
    });
  }

  initialiseViewButtons(): void {
    if (!this.projectDetails || !this.currentUser) {
      return;
    }

    const isDraftOrCommentPhase = [
      appConstants.publicationStatus.drafting,
      appConstants.publicationStatus.commenting,
      appConstants.publicationStatus.commentingCompleted
    ].indexOf(this.projectDetails.phaseId) !== -1;

    if(isDraftOrCommentPhase){
      return;
    }

    const isAdminPgmPmTw = UserUtility.isTwPmPgmAdmin(this.currentUser);
    const isPartOfCommittee = !UserUtility.isMemberOfPublicOnly(this.currentUser) 
                              && UserUtility.userIsOnCommittee(this.currentUser, this.projectDetails.committeeCode);

    const isAuthorised = isAdminPgmPmTw || isPartOfCommittee;
    const validBallotStatus = isAdminPgmPmTw 
      || (isPartOfCommittee && this.projectDetails.ballotStatus !== appConstants.sdBallots.status.notOpen);

    this.showConnectViewButton = isAuthorised && validBallotStatus && this.projectDetails.existInConnect;
  }

  createChart() {
    const chartData: IPieChartDataItem[] = [
      {
        label: 'Positive',
        count: this.positiveVotesCount,
        color: this.positiveColour
      },
      {
        label: 'Negative',
        count: this.negativeVotesCount,
        color: this.negativeColour
      },
      {
        label: 'Not voted',
        count: this.notVotedCount,
        color: this.notVotedColour
      }
    ];

    this.chartjsService.renderDoughnutChart(this.canvas.nativeElement, chartData, 'Total votes');
  }
}