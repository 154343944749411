function _createForOfIteratorHelperLoose(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (it) return (it = it.call(o)).next.bind(it); if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; return function () { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import generateWorksheet from './worksheet.js';
import initStyles from './styles.js';
import initSharedStrings from './sharedStrings.js';
import validateSheetName from './validateSheetName.js';
export function generateSheets(_ref) {
  var data = _ref.data,
      sheetName = _ref.sheetName,
      sheetNames = _ref.sheetNames,
      schema = _ref.schema,
      columns = _ref.columns,
      headerStyle = _ref.headerStyle,
      fontFamily = _ref.fontFamily,
      fontSize = _ref.fontSize,
      orientation = _ref.orientation,
      stickyRowsCount = _ref.stickyRowsCount,
      stickyColumnsCount = _ref.stickyColumnsCount,
      showGridLines = _ref.showGridLines,
      dateFormat = _ref.dateFormat;

  var _initSharedStrings = initSharedStrings(),
      getSharedStringsXml = _initSharedStrings.getSharedStringsXml,
      getSharedString = _initSharedStrings.getSharedString;

  var _initStyles = initStyles({
    fontFamily: fontFamily,
    fontSize: fontSize
  }),
      getStylesXml = _initStyles.getStylesXml,
      getStyle = _initStyles.getStyle; // Versions before `1.3.4` had a bug:
  // In a "write multiple sheets" scenario, `columns` parameter
  // wasn't required to be an array of `columns` for each sheet.


  if (sheetNames) {
    if (columns) {
      if (!Array.isArray(columns[0])) {
        throw new Error('In a "write multiple sheets" scenario, `columns` parameter must be an array of `columns` for each sheet.');
      }
    }
  }

  if (!sheetNames) {
    sheetNames = [sheetName || 'Sheet1'];
    data = [data];

    if (columns) {
      columns = [columns];
    }

    if (schema) {
      schema = [schema];
    }
  } // Validate sheet name.


  for (var _iterator = _createForOfIteratorHelperLoose(sheetNames), _step; !(_step = _iterator()).done;) {
    var _sheetName = _step.value;
    validateSheetName(_sheetName);
  }

  var worksheets = [];
  var sheetIndex = 0;

  for (var _iterator2 = _createForOfIteratorHelperLoose(sheetNames), _step2; !(_step2 = _iterator2()).done;) {
    var sheet = _step2.value;
    worksheets.push(generateWorksheet(data[sheetIndex], {
      schema: schema && schema[sheetIndex],
      columns: columns && columns[sheetIndex],
      headerStyle: headerStyle,
      getStyle: getStyle,
      getSharedString: getSharedString,
      customFont: fontFamily || fontSize,
      dateFormat: dateFormat,
      orientation: orientation,
      stickyRowsCount: stickyRowsCount,
      stickyColumnsCount: stickyColumnsCount,
      showGridLines: showGridLines,
      sheetId: sheetIndex + 1
    }));
    sheetIndex++;
  }

  return {
    sheets: sheetNames.map(function (sheetName, i) {
      return {
        id: i + 1,
        name: sheetName,
        data: worksheets[i]
      };
    }),
    getSharedStringsXml: getSharedStringsXml,
    getStylesXml: getStylesXml
  };
}
