<p>iec-ballot-detail page</p>

<div 
    *ngIf="ballot"
    class="my-4"
>
    <div class="flex justify-between">
        <div>IEC Ballot</div>
        <app-iec-report [ballot]="ballot"></app-iec-report>
        <button (click)="testExcelFile()">Text Excel Download</button>
    </div>
    
    <pre>
        {{ballot | json}}
    </pre>

</div>
