<div class="grow flex flex-col">
  <div class="bg-grey-200 page-padding-y page-padding-x">
    <h3 id="h-standards-library">Standards Library</h3>
  </div>
  <div class="page-padding-x bg-grey-100">Filters</div>

  <div class="grow bg-grey-200 page-padding-x">
    <app-document-search-results
      [searchResults]="searchResults"
      [loading]="loading"
    ></app-document-search-results>
  </div>
</div>
