import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { Subscription } from 'rxjs';
import { IUser } from '../../../shared/models/user';
import { AuthService } from '../../../shared/services/auth.service';
import { UserUtility } from '../../../shared/utilities/user-utility';
import { CommitteeService } from '../shared/services/committee.service';

@Component({
  selector: 'app-button-email-committee-members',
  templateUrl: './button-email-committee-members.component.html',
  styleUrl: './button-email-committee-members.component.scss'
})
export class ButtonEmailCommitteeMembersComponent implements OnChanges, OnDestroy {
  faEnvelope = faEnvelope;

  @Input() committeeCode!: string;
  @Input() displayAsButton: boolean = false;

  loading: boolean = true;
  subscriptions: Subscription = new Subscription();

  currentUser: IUser | null = null;
  mailToHref: string = '';

  constructor(
    private _authService: AuthService,
    private _committeeService: CommitteeService,
  ) {
    const userSubscription = this._authService.currentUserSubject.subscribe(user => {
      this.currentUser = user;
    });

    this.subscriptions.add(userSubscription);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const committeeCode = changes['committeeCode'];
    if (!committeeCode?.currentValue || !this.currentUser) {
      return;
    }

    if (UserUtility.isInternalUser(this.currentUser) || UserUtility.userIsOnCommittee(this.currentUser, this.committeeCode)) {
      this.setMailToHref();
    }
    else {
      this.loading = false;
    }
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  setMailToHref() {
    const committeeMembers = this._committeeService
      .getCommittee(this.committeeCode)
      .subscribe({
        next: response => {
          if (response.success) {
            const committee = response.content;

            const emails = committee.membershipDetails
              .filter(x => x.membershipType)
              .map(x => x.memberEmail)
              .join(';');

            if ((emails && emails.length > 0) || committee.committeeProjectManagerEmail) {
              const cc = committee.committeeProjectManagerEmail ? `cc=${committee.committeeProjectManagerEmail}&` : '';
              this.mailToHref = `mailto:${emails}?${cc}subject=Committee-in-Confidence: ${this.committeeCode}, ${committee.committeeTitle}&body=Committee-in-Confidence: ${this.committeeCode}, ${committee.committeeTitle}%0D%0D%0D%0D%0D%0D`;
            }
          }

          this.loading = false;
        },
        error: _ => {
          this.loading = false;
        }
      });

    this.subscriptions.add(committeeMembers);
  }

  onClickLink(e: MouseEvent) {
    e.stopPropagation();
    window.location.href = this.mailToHref;
  }
}
