function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _createForOfIteratorHelperLoose(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (it) return (it = it.call(o)).next.bind(it); if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; return function () { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

// Copy-pasted from:
// https://github.com/davidramos-om/zipcelx-on-steroids/blob/master/src/formatters/rows/generatorRows.js
import generateRow from './row.js';
export default function generateRows(data, _ref) {
  var schema = _ref.schema,
      headerStyle = _ref.headerStyle,
      getStyle = _ref.getStyle,
      getSharedString = _ref.getSharedString,
      customFont = _ref.customFont,
      dateFormat = _ref.dateFormat;

  if (schema) {
    var header = [];

    for (var _iterator = _createForOfIteratorHelperLoose(schema), _step; !(_step = _iterator()).done;) {
      var column = _step.value;

      // If at least one schema column has a title,
      // then print a header row.
      if (column.column) {
        header = [schema.map(function (column) {
          return _objectSpread({
            type: String,
            value: column.column,
            align: column.align
          }, headerStyle || DEFAULT_HEADER_STYLE);
        })];
        break;
      }
    }

    data = header.concat(data.map(function (row) {
      return schema.map(function (column) {
        return _objectSpread(_objectSpread({}, column), {}, {
          value: column.value(row)
        });
      });
    }));
  }

  return data.map(function (row, index) {
    return generateRow(row, index, {
      getStyle: getStyle,
      getSharedString: getSharedString,
      customFont: customFont,
      dateFormat: dateFormat,
      usesSchema: schema !== undefined
    });
  }).join('');
}
var DEFAULT_HEADER_STYLE = {
  fontWeight: 'bold'
};
