import { Component, Input,SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { ISIMPublishedDocument } from '../../shared/models/committee-published-document';
import { ITableColumn, TableColumnType } from '../../../controls/table/table-column';
import { ITableFilter } from '../../../controls/table/table-filter';
import { IUser } from '../../../../shared/models/user';
import { Subscription } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { AuthService } from '../../../../shared/services/auth.service';
import { UserUtility } from '../../../../shared/utilities/user-utility';

@Component({
  selector: 'app-committee-details-publication',
  templateUrl: './committee-details-publication.component.html',
  styleUrl: './committee-details-publication.component.scss',
})
export class CommitteeDetailsPublicationComponent {
  @Input() publications: ISIMPublishedDocument[] | null = null;
  @Input() hasLoadingPublications: boolean = false;
  tableData: ISIMPublishedDocument[] = [];
  tableColumns: ITableColumn<ISIMPublishedDocument>[] = [];
  tableFilters: ITableFilter[] = [];
  loading: boolean = true;
  currentUser: IUser | null = null;
  subscriptions: Subscription = new Subscription();
  constructor(
    private _authService: AuthService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    const userSubscription = this._authService.currentUserSubject.subscribe(user => {
      if (user) {
        this.currentUser = user;
      }
      this.initialize();
    });

    this.subscriptions.add(userSubscription);
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  initialize() {
    this.loading = true;
    if(this.hasLoadingPublications) {
      this.initializeTableData();
      this.initializeTableColumns();
      this.loading = false;
    }
  }

  initializeTableData() {
    if(this.publications){
      this.tableData = this.publications;
    }
  }

  initializeTableColumns() {
    const isMOP = this.currentUser && UserUtility.isMemberOfPublicOnly(this.currentUser);
    console.log("isMOP ", isMOP);
    let publications = isMOP ? [
      {
        name: 'designation',
        displayName: 'Designation',
        isSortable: true,
        headerWidth: '80px'
      },
      {
        name: 'title',
        displayName: 'Title',
        isSortable: true,
        headerWidth: '65%'
      },
    ] :
    [
      {
        name: 'designation',
        displayName: 'Designation',
        type: TableColumnType.link,
        isSortable: true,
        getLink: (row: ISIMPublishedDocument) => `${environment.internalSIMUrl}${row.uri}`,
        headerWidth: '80px'
      },
      {
        name: 'title',
        displayName: 'Title',
        isSortable: true,
        headerWidth: '65%'
      },
    ]
    let columns = publications;
    this.tableColumns = columns;
  }
}
