import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, of, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IApiResponse } from '../models/api-response';
import { IAppUrls } from '../models/app-urls';
 
@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
 
  _configApiBaseUrl = environment.servicesUrl.authentication.endsWith('/')
    ? `${environment.servicesUrl.authentication}api/configurations`
    : `${environment.servicesUrl.authentication}/api/configurations`;
 
  private appUrls: IAppUrls | null = null;
 
  constructor(
    private http: HttpClient) {
 
  }
 
  public getAppUrls(): Observable<IAppUrls> {
    if (this.appUrls) {
      return of(this.appUrls);
    }
 
    return this.http
      .get<IApiResponse<IAppUrls>>(`${this._configApiBaseUrl}/app-urls`)
      .pipe(
        map((response) => {
          if (response.success) {
            return response.content;
          } else {
            throw new Error(response.message);
          }
        }),
        catchError((error) => {
          throw(error);
        })
      );
  }
}