<ng-container *ngIf="!loading && committees && committees.length > 0">
    <app-update-scroller
      [title]="'Recent Committees'"
      [updateCount]="committees.length"
    >
      <a
        *ngFor="let committee of committees"
        (click)="goToCommitteePage(committee.committeeCode)"
        class="cursor-pointer"
      >
        <app-update-scroller-card
          [title]="committee.committeeCode"
          [description]="committee.committeeName"
          [id]="'card-recent-project-' + committee.committeeCode"
        >
          <div headerLeft name="date">
            {{ committee.accessDate | date : "dd/MM/yyyy" }}
          </div>
          <app-button-email-committee-members
            [committeeCode]="committee.committeeCode"
            headerRight
          >
          </app-button-email-committee-members>
        </app-update-scroller-card>
      </a>
    </app-update-scroller>
  </ng-container>
  