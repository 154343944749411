<div class="w-full flex flex-col mb-4">
    <label *ngIf="label" [attr.for]="id" class="mb-2 block text-sm font-medium leading-6 text-gray-900">{{label}}</label>
    <div class="relative">
        <div *ngIf="startIcon" class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none text-gray-400">            
            <fa-icon [icon]="startIcon"></fa-icon>
        </div>
        <input [(ngModel)]="value" [attr.id]="id" [attr.name]="id" 
            [type]="type" 
            [placeholder]="placeholder"
            class="block w-full rounded-md border-0 py-2 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-brand-orange sm:text-sm sm:leading-6 outline-none"
            [class.ps-10]="startIcon"
        />
    </div>

</div>