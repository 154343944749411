<span [id]="name" [ngClass]="cssClass">
  <div class="flex flex-row justify-start items-center gap-1">
    <span *ngIf="iconName" class="material-symbols-rounded icon-16">{{
      iconName
    }}</span>
    <span class="text-button-sm">
      <ng-content></ng-content>
    </span>
  </div>
</span>
