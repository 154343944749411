import { Component, forwardRef, Input, OnDestroy } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { IOption } from '../../controls/models/option';
import { AuthService } from '../../../shared/services/auth.service';
import { CommitteeService } from '../../committees/shared/services/committee.service';
import uniqBy from 'lodash/uniqBy';
import orderBy from 'lodash/orderBy';

@Component({
  selector: 'app-select-committee',
  templateUrl: './select-committee.component.html',
  styleUrl: './select-committee.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectCommitteeComponent),
      multi: true
    }
  ]
})
export class SelectCommitteeComponent implements ControlValueAccessor, OnDestroy {

  @Input('value') _value: string | undefined;
  @Input() id: string = 'select-committee';
  @Input() label: string = 'Committee';

  onChange: any = () => { };
  onTouched: any = () => { };

  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;
    this.onChange(val);
    this.onTouched();
  }

  loading: boolean = true;
  subscriptions: Subscription = new Subscription();
  options: IOption<string>[] = [];

  constructor(
    private _authService: AuthService,
    private _committeeService: CommitteeService,
  ) {
    const userSubscription = this._authService.currentUserSubject.subscribe(user => {
      if (user) {
        this.getCommittees();
      }
    });

    this.subscriptions.add(userSubscription);
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  getCommittees() {
    const searchCommittees = this._committeeService.getCommittees().subscribe(response => {
      if (response.success) {
        const uniqueCommittees = uniqBy(response.content, x => x.committeeCode);      
        const options = orderBy(uniqueCommittees, x => x.committeeCode)          
          .map(x => ({ value: x.committeeCode, label: `${x.committeeCode} ${x.committeeName ?? ''}` }));

        this.options = [
          { value: '', label: 'All' },
          ...options
        ];        
      }

      this.loading = false;
    });

    this.subscriptions.add(searchCommittees);
  }
}