import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { BehaviorSubject, catchError, map, Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { appConstants } from '../constants/app-constants';
import { IApiResponse } from '../models/api-response';
import { ITokenDetails } from '../models/token-details';
import { IUser, User } from '../models/user';
import { UserUtility } from '../utilities/user-utility';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  _authApiBaseUrl = environment.servicesUrl.authentication.endsWith('/')
    ? `${environment.servicesUrl.authentication}auth`
    : `${environment.servicesUrl.authentication}/auth`;

  private _currentUser: IUser | null = null;
  currentUserSubject: BehaviorSubject<IUser | null> = new BehaviorSubject<IUser | null>(null);

  private isLoggingOut = false;

  constructor(
    private http: HttpClient) {

  }

  get authToken(): string | null {
    return localStorage.getItem(appConstants.localStorageKey.token);
  }

  get isLoggedIn(): Observable<boolean> {
    if (!this.authToken) {
      this.logout();
      return new Observable(observer => {
        observer.next(false);
        observer.complete();
      });
    }

    if (!this._currentUser) {
      return this.getUserInfo()
        .pipe(
          map(_ => this._currentUser ? true : false)
        );
    }

    return new Observable(observer => {
      observer.next(true);
      observer.complete();
    });
  }

  get isInternalUser(): boolean {
    return UserUtility.isInternalUser(this._currentUser);
  }

  hasRoles(...roles: string[]): Observable<boolean> {
    if (!this._currentUser) {
      return this.getUserInfo().pipe(
        map(user => UserUtility.hasRoles(user, ...roles))
      );
    }

    return new Observable(observer => {
      observer.next(UserUtility.hasRoles(this._currentUser, ...roles));
      observer.complete();
    });
  }

  private setUser(tokenDetails: any) {
    if (!tokenDetails) return;

    let user = new User();
    user.userId = tokenDetails.sub;
    user.userName = tokenDetails.UserName;
    user.title = tokenDetails.Title;
    user.firstName = tokenDetails.FirstName;
    user.lastName = tokenDetails.LastName;
    user.email = tokenDetails.Mail;
    user.role = tokenDetails.Role;
    user.committees = tokenDetails.Committees;
    user.objectId = tokenDetails.ObjectId;
    user.sessionId = tokenDetails.SessionId;
    user.sessionExpiry = tokenDetails.expires;
    user.userType = tokenDetails.UserType;
    user.userIdCustom = tokenDetails.UserIdCustom;
    user.partnerServerURL = tokenDetails.PartnerServerURL;
    user.features = tokenDetails.Features;

    this._currentUser = user;
    this.currentUserSubject.next(user);
  }

  private extractAuthTokenDetails(): any {
    if (this.authToken) {
      try {
        return jwtDecode(this.authToken);
      }
      catch {
        console.error('Invalid token');
      }
    }

    return undefined;
  }

  private handleTokenDetailsResponse(response: IApiResponse<ITokenDetails>) {
    if (response.success) {
      localStorage.setItem(appConstants.localStorageKey.token, response.content.token);

      const tokenDetails = this.extractAuthTokenDetails();
      if (tokenDetails) {
        this.setUser(tokenDetails);
      }
      else {
        this.logout();
      }
    }
    else {
      this.logout();
    }
  }

  getUserInfo(): Observable<IUser | null> {
    const tokenDetails = this.extractAuthTokenDetails();
    if (!tokenDetails) {
      this.logout();
      return new Observable(observer => {
        observer.next(this._currentUser);
        observer.complete();
      });
    }

    const currentTime = Date.now() / 1000;
    if (tokenDetails.exp < currentTime) {
      return this.refreshAccessToken()
        .pipe(
          map(_ => this._currentUser)
        );
    }

    return new Observable(observer => {
      this.setUser(tokenDetails);
      observer.next(this._currentUser);
      observer.complete();
    });

    // TODO: for new auth in the future - jwt details will be limited so there should be an endpoint to get current user info/details 
  }

  getTokens() {
    return this.http
      .get<IApiResponse<ITokenDetails>>(this._authApiBaseUrl, { withCredentials: true })
      .pipe(
        map((response) => {
          this.handleTokenDetailsResponse(response);
          return response;
        })
      );
  }

  refreshAccessToken() {
    return this.http
      .post<IApiResponse<ITokenDetails>>(`${this._authApiBaseUrl}/refresh`, null, { withCredentials: true })
      .pipe(
        map((response) => {
          this.handleTokenDetailsResponse(response);
          return response;
        }),
        catchError((error) => {
          this.logout();
          return throwError(() => error);
        })
      );
  }

  redirectToLoginPage(returnUrl: string | null = null) {
    if (!returnUrl) {
      returnUrl = window.location.hash.replace('#/', '');
      returnUrl = returnUrl?.length > 0 ? returnUrl : 'dashboard';
    }

    window.location.href = `${environment.servicesUrl.authentication}SAML/SSO?landingPage=${returnUrl}`;
  }

  logout(returnUrl: string | null = null) {
    if (this.isLoggingOut) return;

    this.isLoggingOut = true;
    const accessToken = this.authToken;

    this.http
      .post<IApiResponse<string>>(`${this._authApiBaseUrl}/logout`, null, { withCredentials: true })
      .subscribe(response => {
        localStorage.clear();
        this._currentUser = null;

        if(!accessToken){
          this.redirectToLoginPage(returnUrl);
          return;
        }
        
        if (accessToken && response.content) {

          const allowedUrls = [
            'https://standards--sadev.sandbox.my.site.com/idppoc/services/auth/idp/saml2/logout',
            'https://standards--sadev.sandbox.my.salesforce.com/services/auth/idp/saml2/logout',

            'https://standards--satest.sandbox.my.salesforce.com/services/auth/idp/saml2/logout',
            'https://standards--satest.sandbox.my.site.com/idppoc/services/auth/idp/saml2/logout',

            'https://standards--uat.sandbox.my.salesforce.com/services/auth/idp/saml2/logout',
            'https://standards--uat.sandbox.my.site.com/idppoc/services/auth/idp/saml2/logout',

            'https://standards.my.salesforce.com/services/auth/idp/saml2/logout',
            'https://standards.my.site.com/idppoc/services/auth/idp/saml2/logout'
          ]

          const url = new URL(response.content);
          const urlIndex = allowedUrls.findIndex(x => x.startsWith(url.origin));
          if (urlIndex > -1) {
            var searchParams = new URLSearchParams();
            url.searchParams.forEach((value, key) => {
              if (['SAMLRequest', 'RelayState', 'SigAlg'].includes(key)) {
                searchParams.append(key, value);
              }
            });
            window.location.href = `${allowedUrls[urlIndex]}?${searchParams.toString()}`;
            return;
          }
        }
      });
  }
}