import { Injectable } from '@angular/core';
import { IBlobOverview } from '../../../../shared/models/blob-overview';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { IBlobFile } from '../../../../shared/models/blob-file';
import { IApiResponse } from '../../../../shared/models/api-response';

@Injectable({
  providedIn: 'root'
})
export class GuidesAndVideosService {

  constructor(
    private http: HttpClient
  ) { }

  DownloadUserGuideAndVideo(containerName: string, blobName: string): Observable<IApiResponse<IBlobFile>> {
    return this.http.get<IApiResponse<IBlobFile>>(environment.servicesUrl.ballot + `api/blobs/DownloadUserGuideAndVideo/${containerName}/${blobName}`);
  }

  GetUserGuideAndVideoContainerContents(): Observable<IApiResponse<IBlobOverview[]>> {
    return this.http.get<IApiResponse<IBlobOverview[]>>(environment.servicesUrl.ballot + `api/blobs/GetUserGuideAndVideoContainerContents/`);
  }
}
 