import { Component, Input, OnChanges, OnDestroy, SecurityContext, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { appConstants } from '../../../../shared/constants/app-constants';
import { IUser } from '../../../../shared/models/user';
import { AuthService } from '../../../../shared/services/auth.service';
import { UserUtility } from '../../../../shared/utilities/user-utility';
import { ICommittee } from '../../shared/models/committee';
import { ILiaison } from '../../shared/models/liason';

@Component({
  selector: 'app-committee-details-overview',
  templateUrl: './committee-details-overview.component.html',
  styleUrl: './committee-details-overview.component.scss'
})
export class CommitteeDetailsOverviewComponent implements OnDestroy, OnChanges {
  @Input() committee: ICommittee | null = null;

  loading: boolean = true;
  subscriptions: Subscription = new Subscription();

  currentUser: IUser | null = null;
  canViewAllCommitteeDetails: boolean = false;

  liasonDetailsFrom: ILiaison[] = [];
  liasonDetailsTo: ILiaison[] = [];
  sanitizedTermsOfReference: string = '';

  constructor(
    private _authService: AuthService,
    private _sanitizer: DomSanitizer
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    const committee = changes['committee'];
    if (!committee?.currentValue) {
      return;
    }

    const userSubscription = this._authService.currentUserSubject.subscribe(user => {
      if (user) {
        this.currentUser = user;
        this.initialize();
      }
    });

    this.subscriptions.add(userSubscription);
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  initialize() {
    if (!this.committee) {
      this.loading = false;
      return;
    }

    this.initializeCanViewAllCommitteeDetails();
    this.initializeTermsOfReference();
    this.initializeLiasonDetails();

    this.loading = false;
  }

  initializeCanViewAllCommitteeDetails() {
    if (this.currentUser && this.committee) {
      this.canViewAllCommitteeDetails = UserUtility.isInternalUser(this.currentUser)
        || UserUtility.isCommitteeMember(this.currentUser)
        || UserUtility.userIsOnCommittee(this.currentUser, this.committee.committeeCode);
    }
  }

  initializeTermsOfReference() {
    if (this.committee?.termOfReference) {
      this.sanitizedTermsOfReference = this._sanitizer.sanitize(SecurityContext.HTML, this.committee.termOfReference) ?? '';
    }
  }

  initializeLiasonDetails() {
    if (this.canViewAllCommitteeDetails && this.committee?.liaisonDetails) {
      this.liasonDetailsFrom = this.committee.liaisonDetails.filter(x => x.liaisonType === appConstants.liasonTypes.from);
      this.liasonDetailsTo = this.committee.liaisonDetails.filter(x => x.liaisonType === appConstants.liasonTypes.to);
    }
  }

  isShowingField(value: string) {
    if(value) {
      let trimmedText = value?.replace(/\s/g, '');
      return this.canViewAllCommitteeDetails && trimmedText.length > 0;
    }
    return false;
  }

  isShowingFieldAsNoMop(value: string){
    if(value && this.currentUser) {
      let isMOP = UserUtility.isMemberOfPublicOnly(this.currentUser);
      let trimmedText = value.replace(/\s/g, '');
      return !isMOP && trimmedText.length > 0;
    }
    return false;
  }
}
