import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { IApiResponse } from '../models/api-response';

@Injectable({
  providedIn: 'root'
})
export class SamlService {

  _samlApiBaseUrl = environment.servicesUrl.authentication.endsWith('/')
    ? `${environment.servicesUrl.authentication}saml`
    : `${environment.servicesUrl.authentication}/saml`;

  constructor(
    private http: HttpClient) {

  }

  public getSfAccessToken() {
    return this.http.get<IApiResponse<string>>(`${this._samlApiBaseUrl}/GetSalesforceAccessToken`);
  }

  public getLogoutUrl() {
    return this.http.get<IApiResponse<string>>(`${this._samlApiBaseUrl}/SLO`);
  }
}
