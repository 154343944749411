import { differenceInDays, differenceInHours, differenceInMinutes, differenceInMonths, differenceInSeconds, differenceInYears } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

export const convertStringDateToJSDate = (date: string | null): Date | null => {
    // add additional logic here if necessary    
    if (!date) {
        return null;
    }

    return new Date(date);
}

export const getCurrentDateForAustraliaSydney = (): Date => {
    return toZonedTime(new Date(), 'Australia/Sydney');
}

export const getUpdateTimeStampWording = (datePosted: Date): string => {
    let nowUTC = new Date();

    let diff = differenceInYears(nowUTC, datePosted);
    if (diff > 0) {
        return diff === 1 ? '1 year ago' : `${diff} years ago`;
    }

    diff = differenceInMonths(nowUTC, datePosted);
    if (diff > 0) {
        return diff === 1 ? '1 month ago' : `${diff} months ago`;
    }

    diff = differenceInDays(nowUTC, datePosted);
    if (diff > 0) {
        return diff === 1 ? 'Yesterday' : `${diff} days ago`;
    }

    diff = differenceInHours(nowUTC, datePosted)
    if (diff > 0) {
        return diff === 1 ? `${diff} hour ago` : `${diff} hours ago`;
    }

    diff = differenceInMinutes(nowUTC, datePosted)
    if (diff > 0) {
        return diff === 1 ? `${diff} minute ago` : `${diff} minutes ago`;
    }

    diff = differenceInSeconds(nowUTC, datePosted)
    if (diff > 0) {
        return diff === 1 ? `${diff} second ago` : `${diff} seconds ago`;
    }

    return '';
}

export default {
    convertStringDateToJSDate,
    getCurrentDateForAustraliaSydney,
    getUpdateTimeStampWording
}