import { Component, ElementRef, forwardRef, Input, Renderer2, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { faCheck, faSearch } from '@fortawesome/free-solid-svg-icons';
import { IOption } from '../models/option';

@Component({
  selector: 'app-select-multiple',
  templateUrl: './select-multiple.component.html',
  styleUrl: './select-multiple.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectMultipleComponent),
      multi: true
    }
  ]
})
export class SelectMultipleComponent implements ControlValueAccessor {

  faCheck = faCheck;
  faSearch = faSearch;

  @ViewChild('toggleButton') toggleButton!: ElementRef;
  @ViewChild('menu') menu!: ElementRef;

  @Input() id: string | undefined;
  @Input() label: string | undefined = "Label";
  @Input() loading: boolean = false;
  @Input() displayOptionsCount: number = 5;
  @Input() options: IOption<any>[] = [];
  @Input() showFilter: boolean = false;
  filterText: string = '';

  onChange: any = () => { };
  onTouched: any = () => { };

  values: any[] = [];
  isMenuOpen: boolean = false;

  constructor(private _renderer: Renderer2) {
    this._renderer.listen('window', 'click', (e: Event) => {
      const isToggleButtonEl = this.toggleButton?.nativeElement && (e.target === this.toggleButton.nativeElement || this.toggleButton.nativeElement.contains(e.target));
      const isMenuEl = this.menu?.nativeElement && (e.target === this.menu.nativeElement || this.menu.nativeElement.contains(e.target));
      if (!isToggleButtonEl && !isMenuEl) {
        this.isMenuOpen = false;
      }
    });
  }

  writeValue(values: any[]): void {
    this.values = values;
    this.onChange(values);
    this.onTouched();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }


  getSelectedLabel(): string {
    if (!this.values || this.values.length === 0)
      return 'All';

    return `(${this.values.length}) selected`;
  }

  onSelectOptionClick(value: any) {
    if (this.values.includes(value)) {
      const newValues = this.values.filter(v => v !== value);
      this.writeValue(newValues);
    } else {
      this.writeValue([...this.values, value]);
    }
  }

  onSelectButtonClick() {
    this.isMenuOpen = !this.isMenuOpen;
    this.onTouched();
  }

  onClearAllClick() {
    this.writeValue([]);
  }

  clearFilterText() {
    this.filterText = '';
  }

  getOptions(): IOption<any>[] {
    if (this.showFilter && this.filterText) {
      const filterText = this.filterText.toLowerCase()
      return this.options.filter(o => o.label.toLowerCase().includes(filterText));
    }

    return this.options;
  }
}