var _excluded = ["fileName"];

function _createForOfIteratorHelperLoose(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (it) return (it = it.call(o)).next.bind(it); if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; return function () { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

// Copy-pasted from:
// https://github.com/davidramos-om/zipcelx-on-steroids/blob/master/src/zipcelx.js
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import generateWorkbookXml from './statics/workbook.xml.js';
import generateWorkbookXmlRels from './statics/workbook.xml.rels.js';
import rels from './statics/rels.js';
import contentTypes from './statics/[Content_Types].xml.js';
import { generateSheets } from './writeXlsxFile.common.js';
export default function writeXlsxFile(data) {
  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      fileName = _ref.fileName,
      rest = _objectWithoutProperties(_ref, _excluded);

  return generateXlsxFile(data, rest).then(function (blob) {
    if (fileName) {
      return FileSaver.saveAs(blob, fileName);
    }

    return blob;
  });
}
/**
 * Writes an *.xlsx file into a "blob".
 * https://github.com/egeriis/zipcelx/issues/68
 * "The reason if you want to send the excel file or store it natively on Cordova/capacitor app".
 * @return {Blob}
 */

function generateXlsxFile(data, _ref2) {
  var sheetName = _ref2.sheet,
      sheetNames = _ref2.sheets,
      schema = _ref2.schema,
      columns = _ref2.columns,
      headerStyle = _ref2.headerStyle,
      fontFamily = _ref2.fontFamily,
      fontSize = _ref2.fontSize,
      orientation = _ref2.orientation,
      stickyRowsCount = _ref2.stickyRowsCount,
      stickyColumnsCount = _ref2.stickyColumnsCount,
      showGridLines = _ref2.showGridLines,
      dateFormat = _ref2.dateFormat;
  var zip = new JSZip();
  zip.file('_rels/.rels', rels);
  zip.file('[Content_Types].xml', contentTypes);

  var _generateSheets = generateSheets({
    data: data,
    sheetName: sheetName,
    sheetNames: sheetNames,
    schema: schema,
    columns: columns,
    headerStyle: headerStyle,
    fontFamily: fontFamily,
    fontSize: fontSize,
    orientation: orientation,
    stickyRowsCount: stickyRowsCount,
    stickyColumnsCount: stickyColumnsCount,
    showGridLines: showGridLines,
    dateFormat: dateFormat
  }),
      sheets = _generateSheets.sheets,
      getSharedStringsXml = _generateSheets.getSharedStringsXml,
      getStylesXml = _generateSheets.getStylesXml;

  var xl = zip.folder('xl');
  xl.file('_rels/workbook.xml.rels', generateWorkbookXmlRels({
    sheets: sheets
  }));
  xl.file('workbook.xml', generateWorkbookXml({
    sheets: sheets,
    stickyRowsCount: stickyRowsCount,
    stickyColumnsCount: stickyColumnsCount
  }));
  xl.file('styles.xml', getStylesXml());
  xl.file('sharedStrings.xml', getSharedStringsXml());

  for (var _iterator = _createForOfIteratorHelperLoose(sheets), _step; !(_step = _iterator()).done;) {
    var _step$value = _step.value,
        id = _step$value.id,
        _data = _step$value.data;
    xl.file("worksheets/sheet".concat(id, ".xml"), _data);
  }

  return zip.generateAsync({
    type: 'blob',
    mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  });
}
