<ol
  *ngIf="(projectDetails?.projectId ?? 0) > 0"
  class="items-center sm:flex children:w-full gap-3"
>
  <li
    *ngFor="let phase of phases; let i = index"
    class="relative mb-6 sm:mb-0"
    [id]="'timeline-entry-' + phase.phase"
  >
    <div class="flex items-center gap-2 [&_.done]:bg-brand-blue">
      <div
        class="z-10 flex items-center justify-center w-6 h-6 rounded-full ring-0 ring-white shrink-0"
      >
        <!-- circle -->
        <ng-container *ngIf="!phase.currentStep && !phase.completed">
          <svg
            class="text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 512 512"
          >
            <path
              d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"
            />
          </svg>
        </ng-container>

        <!-- circle with dot -->
        <ng-container *ngIf="phase.currentStep">
          <svg
            class="text-brand-darkBlue"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 512 512"
          >
            <path
              d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256-96a96 96 0 1 1 0 192 96 96 0 1 1 0-192z"
            />
          </svg>
        </ng-container>

        <!-- check -->
        <ng-container *ngIf="phase.completed">
          <svg
            class="text-green-500"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 512 512"
          >
            <path
              d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z"
            />
          </svg>
        </ng-container>
      </div>

      <!-- line -->
      <div
        *ngIf="i !== phases.length - 1"
        class="hidden sm:flex w-full h-0.5 bg-gray-300"
        [class.done]="
          phase.completed || (phase.currentStep && phases[i + 1].currentStep)
        "
      ></div>
    </div>
    <div class="mt-3 sm:pe-8">
      <label
        class="text-lg font-semibold"
        [class.text-brand-darkBlue]="phase.currentStep"
        [class.text-gray-400]="!phase.completed"
        >{{ phase.phase }}</label
      >
      <!-- <time class="block mb-2 text-sm font-normal leading-none text-gray-400">00/00/0000 - 00/00/0000</time> -->
    </div>
  </li>
</ol>
