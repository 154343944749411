import { Component, OnDestroy } from '@angular/core';
import { appConstants } from '../../../shared/constants/app-constants';
import { IUser } from '../../../shared/models/user';
import { AuthService } from '../../../shared/services/auth.service';
import { Subscription } from 'rxjs';
import { UserUtility } from '../../../shared/utilities/user-utility';
import { StatusBadgeColourScheme } from '../../controls/status-badge/status-badge-constants';

interface ITempProjectUpdate {
  projectId: number;
  title: string;
  description: string;
  date: Date;
  phase: string;
}

@Component({
  selector: 'app-project-updates',
  templateUrl: './project-updates.component.html',
  styleUrl: './project-updates.component.scss'
})
export class ProjectUpdatesComponent implements OnDestroy {

  public statusBadgeColourSchemeEnum = StatusBadgeColourScheme;

  canViewProjectUpdates: boolean = false;
  loading: boolean = true;
  subscriptions: Subscription = new Subscription();
  
  currentUser!: IUser;
  isInternalUser: boolean = false;

  projects: ITempProjectUpdate[] = [];
  expectedPhases = ['Drafting', 'Commenting', 'Balloting', 'Public Comment & Balloting'];

  viewProjectsButtonLabel: string = 'View My Projects';
  viewMyProjectsParams: object | null = { [appConstants.queryParams.view]: appConstants.projects.viewTypes.myProjects };

  constructor(
    private _authService: AuthService  
  ) {
    const userSubscription = this._authService.currentUserSubject.subscribe(user => {
      if (user) {
        this.currentUser = user;
        this.initialize();
      }
    });

    this.subscriptions.add(userSubscription);    
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  initialize() {
    this.canViewProjectUpdates = UserUtility.canViewProjectUpdates(this.currentUser);
    if(!this.canViewProjectUpdates){
      return;
    }

    const isInternalUser = UserUtility.isInternalUser(this.currentUser);
    if(!isInternalUser) {
      this.viewProjectsButtonLabel = 'View Projects';
      this.viewMyProjectsParams = null;
    }

    this.createSampleData();
  }

  createSampleData() {
    const projects: ITempProjectUpdate[] = [];
    const n = Math.floor(Math.random() * (10 - 30) + 30);

    for (let i = 0; i < n; i++) {
      let phaseIndex = Math.floor(Math.random() * 5);
      if (phaseIndex >= this.expectedPhases.length) {
        phaseIndex = 0;
      }

      projects.push({
        projectId: i+1,
        title: `Project ${i+1}`,
        description: `Project ${i+1} Description`,
        date: new Date(),
        phase: this.expectedPhases[phaseIndex]
      })
    }

    this.projects = projects;
    this.loading = false;
  }

  getProjectsByPhase(phase: string): number {
    return this.projects.filter(p => p.phase === phase).length;
  }
}

