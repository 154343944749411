import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from '../../shared/guards/auth.guard';
import { BallotsComponent } from './ballots.component';
import { IecDetailsComponent } from './iec/iec-details/iec-details.component';
import { IecComponent } from './iec/iec.component';
import { ballotsPageGuard } from '../../shared/guards/role.guard';

const routes: Routes = [
  {
    path: 'ballots',
    component: BallotsComponent,
    canActivate: [authGuard, ballotsPageGuard],
    children: [
      {
        path: 'iec',
        component: IecComponent,
        // canActivate can be used here as well, iec ballot specific guards can be added here.
        //   ex: all iec related features can onyl be used by internal users
        children: [
          // {
          //   path: 'create',
          // },
          {
            path: ':id',
            component: IecDetailsComponent
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BallotRoutingModule { }
