<div class="bg-grey-200 page-padding-y pb-6">
    <div class="page-padding-x mb-3">
        <h1 id="h-projects-page">Projects</h1>
    </div>
    <app-recently-viewed-projects></app-recently-viewed-projects>
</div>

<div class="flex-1 pt-6 bg-grey-100 page-padding-x">
    <div class="">
        <form [formGroup]="searchForm">
            <div class="flex gap-5">
                <div class="flex flex-1 gap-5 children:w-1/2">
                    <app-input formControlName="searchTerm" label="Search Project" [startIcon]="faSearch"
                        placeholder="Search a project title or designation" id="input-search-term-filter"></app-input>

                    <app-radio-group formControlName="viewType" label="Projects" name="viewType"
                        [options]="viewTypeOptions" id="radio-view-type-filter"></app-radio-group>
                </div>    
    
                <div class="flex justify-end items-center pt-3">
                    <app-button-export-to-excel 
                        *ngIf="!loading"
                        fileName="Project Search"
                        [schema]="exportSchema" 
                        [data]="tableComponent.dataSource.filteredData"
                        id="btn-export"
                    ></app-button-export-to-excel>
                </div>
            </div>
            <div class="flex gap-5">
                <div class="flex flex-1 gap-5 children:w-1/3">                    
                    <app-select-multiple-committee formControlName="committeeCodes" id="select-committee-code-filter" #committeeCodesFilter></app-select-multiple-committee>
    
                    <app-select-multiple-project-phase formControlName="phases" id="select-phase-filter"></app-select-multiple-project-phase>
    
                    <app-select-multiple-project-status formControlName="projectStatuses" [id]="'select-project-status-filter'"></app-select-multiple-project-status>                     
                </div>
    
                <div class="flex justify-end items-center pt-3">
                    <button type="button" (click)="onResetFilterClickEvent()" class="btn-neutral" id="btn-clear-filters">
                        <fa-icon [icon]="faX"></fa-icon>Clear All Filters
                    </button>
                </div>
            </div>
        </form>
        <div class="py-6">
            <app-table [loading]="loading" [data]="tableData" [columns]="tableColumns" [filters]="tableFilters" id="table-projectSearchTable" />
        </div>
    </div>
</div>