import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../shared/services/auth.service';
import { IRecentlyViewedCommittee } from '../shared/models/recently-viewed-committee';
import { CommitteeUserService } from '../shared/services/committee-user.service';

@Component({
  selector: 'app-recently-viewed-committtees',
  templateUrl: './recently-viewed-committtees.component.html',
  styleUrl: './recently-viewed-committtees.component.scss'
})
export class RecentlyViewedCommittteesComponent implements OnDestroy {

  loading: boolean = true;
  subscriptions: Subscription = new Subscription();

  committees: IRecentlyViewedCommittee[] | null = null;

  constructor(
    private _authService: AuthService,
    private _committeeUserService: CommitteeUserService
  ) {
    const userSubscription = this._authService.currentUserSubject.subscribe(user => {
      if (user) {
        this.initialize();
      }
    });

    this.subscriptions.add(userSubscription);
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  initialize() {
    this.getRecentlyViewedCommittees();
  }

  getRecentlyViewedCommittees() {
    const recentlyViewedCommittees = this._committeeUserService.getRecentlyViewedCommittees().subscribe(response => {
      if (response.success) {
        this.committees = response.content;
        this.loading = false;
      }
    });

    this.subscriptions.add(recentlyViewedCommittees);
  }
}
