<div class="page-padding-s">
  <div *ngIf="title" class="text-gray-500 mb-2">
    {{ title }} <app-badge>{{ updateCount }}</app-badge>
  </div>
  <div
    class="overflow-x-auto flex gap-5 pb-6 scrollbar"
    style="width: calc(99vw - 6rem)"
  >
    <ng-content></ng-content>
  </div>
</div>
