<div class="bg-grey-200 page-padding-y pb-6">
    <div class="page-padding-x mb-3">
        <h1 id="h-committees-page">Committees</h1>
    </div>
    <app-recently-viewed-committtees></app-recently-viewed-committtees>
</div>

<div class="flex-1 pt-6 bg-grey-100 page-padding-x">
    <div class="">
        <form [formGroup]="searchForm">
            <div class="flex gap-5">
                <div class="flex flex-1 gap-5 children:w-1/2">
                    <app-input formControlName="searchTerm" label="Search Committee" [startIcon]="faSearch"
                        placeholder="Committee Title or Code" id="input-search-term-filter"></app-input>

                    <app-radio-group formControlName="viewType" label="Committees" name="viewType"
                        [options]="viewTypeOptions" id="radio-view-type-filter"></app-radio-group>
                </div>    
    
                <div class="flex justify-end items-center pt-3">
                    <app-button-export-to-excel 
                        *ngIf="!loading && canExport"
                        fileName="Committee Search"
                        [schema]="exportSchema" 
                        [data]="tableComponent.dataSource.filteredData"
                        id="btn-export"
                    ></app-button-export-to-excel>
                </div>
            </div>
            <div class="flex gap-5">
                <div class="flex flex-1 gap-5 children:w-1/3">
                    <app-select-multiple 
                        formControlName="internationalParticipations" 
                        id="select-international-participation-filter"
                        [label]="'International Participation'"
                        [options]="internationalParticipationOptions"
                        [loading]="loading"
                    ></app-select-multiple>

                    <app-select-multiple-sector formControlName="sectors" id="select-sector-filter"></app-select-multiple-sector>

                    <app-select-multiple-committee-status formControlName="statuses" id="select-committee-status-filter"></app-select-multiple-committee-status>
                </div>
    
                <div class="flex justify-end items-center pt-3">
                    <button type="button" (click)="onResetFilterClickEvent()" class="btn-neutral" id="btn-clear-filters">
                        <fa-icon [icon]="faX"></fa-icon>Clear All Filters
                    </button>
                </div>
            </div>
        </form>
        <div class="py-6">
            <app-table [loading]="loading" [data]="tableData" [columns]="tableColumns" [filters]="tableFilters" id="table-committeeSearchTable" />
        </div>
    </div>
</div>