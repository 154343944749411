<div class="page-padding-x page-padding-y bg-white">
  <div class="pb-4">
      <h2 class="underline-yellow">Reports</h2>
  </div>
</div>
<hr class="custom-hr"/>
<div class="page-padding-x pt-4">
    <h3>Select from the below options to review Reports</h3>
    <div class="pt-2 reports-container">
      <ng-container *ngFor="let report of reports">
        <app-reports-item
        id="{{report.id}}"
        cardTitle="{{report.cardTitle}}"
        cardDescription="{{report.cardDescription}}"
        routerLink="{{report.routerLink}}">
      </app-reports-item>
      </ng-container>
    </div>
</div>
