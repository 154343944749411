<div *ngIf="loading" class="h-11 w-36 flex justify-center items-center">
    <app-loading-indicator [size]="16"></app-loading-indicator>
</div>

<ng-container *ngIf="!loading && mailToHref">
    <a [class.hidden]="displayAsButton" href="{{mailToHref}}" (click)="$event.stopPropagation()" 
        class="text-sm text-brand-darkBlue flex items-center gap-2"
    >
        <span class="ml-2">
            <fa-icon [icon]="faEnvelope"></fa-icon>
        </span>
        Email all members
    </a>

    <button *ngIf="displayAsButton" (click)="onClickLink($event)" class="btn btn-primary" [attr.id]="'btn-email-members-' + committeeCode">
        <fa-icon [icon]="faEnvelope"></fa-icon>Email all members
    </button>
</ng-container>