import { inject } from '@angular/core';
import { map } from 'rxjs';
import { AuthService } from '../services/auth.service';

export const authGuard = () => {

  const authService = inject(AuthService);
  
  return authService.isLoggedIn.pipe(
    map((isLoggedIn) => {
      return isLoggedIn;
    })
  );
};
