<div>
    <div *ngIf="loading" class="flex justify-center items-center h-60">
        <mat-progress-spinner mode="indeterminate" diameter="50"></mat-progress-spinner>
    </div>
    
    <div [hidden]="loading">
        <table mat-table [dataSource]="dataSource" matSort class="bg-white" [attr.id]="id">
            <ng-container *ngFor="let column of columns">
                <ng-container matColumnDef="{{column.name}}">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="column.isSortable !== true"
                        [ngStyle]="{ width: column.headerWidth}" [attr.id]="id + '-th-' + column.name">
                        {{ column.displayName}}
                        <span *ngIf="column.isSortable" class="ml-2">
                            <fa-icon [icon]="faSort" class="none"></fa-icon>
                            <fa-icon [icon]="faCaretUp" class="asc"></fa-icon>
                            <fa-icon [icon]="faCaretDown" class="desc"></fa-icon>                            
                        </span>
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index" [ngSwitch]="column.type" [attr.id]="id + '-th-' + column.name + '-' + i">

                        <!-- do other column types here if needed -->

                        <ng-container *ngSwitchCase="columnType.link">
                            <a *ngIf="column.getLink" [routerLink]="column.getLink(element)"
                                class="text-blue-500 hover:underline">
                                {{ getDisplayValue(element,column) }}
                            </a>
                            <ng-container *ngIf="!column.getLink">
                                {{ getDisplayValue(element, column) }}
                            </ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="columnType.arrayString">
                            <ng-container *ngFor="let str of element[column.name]">
                                {{str}} <br/>
                            </ng-container>
                        </ng-container>

                        <ng-container *ngSwitchDefault>
                            {{ getDisplayValue(element, column) }}
                        </ng-container>
                    </td>
                </ng-container>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" [attr.colspan]="displayColumns.length" class="text-center py-3">
                    {{dataSource.filter == '' ? 'No records found.' : 'No records found matching the filter'}}
                </td>
            </tr>
        </table>
        
        <mat-paginator 
            [length]="dataSource.data.length" 
            [pageSize]="pageSize" 
            [pageSizeOptions]="pageSizeOptions"
            class="hidden"
        ></mat-paginator>
        <div *ngIf="dataSource.data.length" class="flex justify-end items-center gap-3 mt-6" name="paginator">
            <div *ngIf="dataSource && dataSource.filteredData.length > 0">
                {{ (paginator.pageIndex * paginator.pageSize) + 1 }} - {{ ((paginator.pageIndex + 1) * paginator.pageSize) > paginator.length ? paginator.length: (paginator.pageIndex + 1) * paginator.pageSize  }} of {{ paginator.length }} items
            </div>
            <div class="flex">
                <button 
                    class="mt-1 mb-1 ml-2 bg-white py-2 px-3 rounded-md border border-solid border-gray-500 text-sm w-[34.5px]
                        disabled:border-gray-300 disabled:text-gray-300 disabled:pointer-events-none" 
                    aria-label="Previous Page"
                    [disabled]="paginator._previousButtonsDisabled()"
                    (click)="paginator.previousPage()"
                    [attr.id]="id + '-paginator-btn-previous'"
                >
                    <fa-icon [icon]="faAngleLeft"></fa-icon>
                </button>
                <button 
                    class="mt-1 mb-1 ml-2 bg-white py-2 px-3 rounded-md border border-solid border-gray-500 text-sm w-[34.5px] 
                        disabled:border-gray-300 disabled:text-gray-300 disabled:pointer-events-none" 
                    aria-label="Next Page"
                    [disabled]="paginator._nextButtonsDisabled()"
                    (click)="paginator.nextPage()"
                    [attr.id]="id + '-paginator-btn-next'"
                >
                    <fa-icon [icon]="faAngleRight"></fa-icon>
                </button>
            </div>
        </div>
    </div>
</div>