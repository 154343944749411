import { Component, OnDestroy, OnInit } from '@angular/core';
import { ConfigurationService } from '../../shared/services/configuration.service';
import { IDocumentSearchRequest } from './shared/models/document-search-request';
import { DocumentService } from './shared/services/document.service';
import { combineLatest, of, Subscription, switchMap } from 'rxjs';
import { IDocumentSearchResult } from './shared/models/document-search-result';
import { AuthService } from '../../shared/services/auth.service';
import { IApiResponse } from '../../shared/models/api-response';
import { IUser } from '../../shared/models/user';
import { deepCopy } from '../../shared/utilities/object-utility';
import { IDocumentSearchResultItem } from './shared/models/document-search-result-item';
import { UserUtility } from '../../shared/utilities/user-utility';
import { appConstants } from '../../shared/constants/app-constants';

@Component({
  selector: 'app-sa-library',
  templateUrl: './sa-library.component.html',
  styleUrl: './sa-library.component.scss'
})
export class SaLibraryComponent implements OnInit, OnDestroy {

  searchResults: IDocumentSearchResult | null = null;
  subscriptions: Subscription = new Subscription();
  loading: boolean = false;
  currentUser: IUser | null = null;

  constructor(
    private _authService: AuthService,
    private _documentService: DocumentService
  ) { }


  ngOnInit(): void {
    this.searchDocuments();
  }
  
  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  searchDocuments(): void {
    this.loading = true;

    const searchResults$ = this._authService.currentUserSubject.pipe(
      switchMap(user => {
        this.currentUser = user;
        
        if (user) {
          const searchRequest = this.buildSearchRequest();

          return combineLatest([of<IUser>(user), this._documentService.documentSearch(searchRequest)]);
        }

        return of<[IUser | null, IApiResponse<IDocumentSearchResult> | null]>([null, null]);
      })
    );

     const searchResultSubscription = searchResults$.subscribe({
        next: ([user, apiResponse]) => {
          if (user && apiResponse && apiResponse.success) {
            this.searchResults = apiResponse.content;

            console.log(this.searchResults);
          }
          else {
            this.searchResults = null;
            console.log(apiResponse?.message);
          }

          this.loading = false;
        },
        error: (error) => {
          this.loading = false;
        }
      });

      this.subscriptions.add(searchResultSubscription);
  }

  buildSearchRequest() : IDocumentSearchRequest {
    const searchRequest: IDocumentSearchRequest = {
      pageNumber: '1',
      pageSize: '20',
      sort: 'dateDesc',
      query: '',
      fromDate: '',
      toDate: '',
      term: '',
      type: '',
      download: 'no',
      draw: '',
      clearAll: '',
      showAll: '',
      uncheck: ''
    };

    return searchRequest;
  }


}
