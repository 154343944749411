<div class="py-6">
  <app-table
      [loading]="loading"
      [data]="tableData"
      [columns]="tableColumns"
      [filters]="tableFilters"
      [pageSize]="50"
      [internalScrolling]="true"
      id="table-committeePublicationTable" />
</div>
