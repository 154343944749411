export const environment = {
	servicesUrl: {
		authentication: 'https://it-uat-connect-authentication.standards-t.com/',
		ballot: 'https://it-uat-connect-ballot.standards-t.com/',
		committee: 'https://it-uat-connect-committee.standards-t.com/',
		document: 'https://it-uat-connect-document.standards-t.com/',
		project: 'https://it-uat-connect-project.standards-t.com/',
	},
  internalSIMUrl: "https://sim-uat-niso-az.standards.org.au/viewdocument/metadata/",
	salesForceAppLauncherUrl: 'https://standards--uat.sandbox.my.salesforce-sites.com/lightning/lightning.out.js',
};
