import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../../../shared/services/auth.service';
import { ICommittee } from '../../../../committees/shared/models/committee';
import { CommitteeService } from '../../../../committees/shared/services/committee.service';
import { appConstants } from '../../../../../shared/constants/app-constants';
import { UserUtility } from '../../../../../shared/utilities/user-utility';
import { IUser, User } from '../../../../../shared/models/user';

@Component({
  selector: 'app-project-details-overview-project-details',
  templateUrl: './project-details-overview-project-details.component.html',
  styleUrl: './project-details-overview-project-details.component.scss'
})
export class ProjectDetailsOverviewProjectDetailsComponent implements OnDestroy, OnChanges {
  @Input() committeeCode: string | null = null;

  loading: boolean = true;
  subscriptions: Subscription = new Subscription();

  currentUser: IUser | null = null;
  canViewAllCommitteeDetails: boolean = false;

  committee: ICommittee | null = null;  

  constructor(
    private _authService: AuthService,
    private _commtteeService: CommitteeService,
  ) {    
  }

  ngOnChanges(changes: SimpleChanges): void {
    const committeeCode = changes['committeeCode'];    
    if(!committeeCode?.currentValue){
      return;
    }

    this.committeeCode = committeeCode.currentValue;

    const userSubscription = this._authService.currentUserSubject.subscribe(user => {
      if (user) {
        this.currentUser = user;
        this.initialize();
      }
    });

    this.subscriptions.add(userSubscription);
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  initialize() {
    if (!this.committeeCode) {
      this.loading = false;
      return;
    }

    this.getCommittee();

    if(this.currentUser){
      this.canViewAllCommitteeDetails = UserUtility.isInternalUser(this.currentUser) 
        || UserUtility.isCommitteeMember(this.currentUser)
        || UserUtility.userIsOnCommittee(this.currentUser, this.committeeCode);
    }    
  }

  getCommittee() {    
    if (!this.committeeCode) {
      return;
    }

    const getCommitteeSubscription = this._commtteeService
      .getCommittee(this.committeeCode)
      .subscribe({
        next: response => {
          if (response.success) {
            this.committee = response.content;
          }

          this.loading = false;
        },
        error: _ => {
          this.loading = false;
        },
      });

    this.subscriptions.add(getCommitteeSubscription);
  }
}