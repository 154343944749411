import { CustomTableLayout } from "pdfmake/interfaces";

export const reportLayoutNames = {
  defaultTable: 'defaultTable',
  ballotSummaryTable: 'ballotSummaryTable',
  outerBordersTable: 'outerBordersTable'
}

export const reportLayouts: { [name: string]: CustomTableLayout } = {
  [reportLayoutNames.defaultTable]: {
    hLineWidth: function () {
      return 1;
    },
    vLineWidth: function (i, node) {
      return (i === 0 || i === node?.table?.widths?.length) ? 1 : 0;
    }
  },
  [reportLayoutNames.ballotSummaryTable]: {
    hLineWidth: function (i) {
      return i === 0 ? 0 : 1;
    },
    vLineWidth: function () {
      return 0;
    },
    hLineColor: function () {
      return 'lightgray';
    },
    paddingTop: function () {
      return 4;
    },
    paddingBottom: function () {
      return 4;
    }
  },
  [reportLayoutNames.outerBordersTable]: {
    hLineWidth: function () {
      return 1;
    },
    vLineWidth: function (i, node) {
      return (i === 0 || i === node?.table?.widths?.length) ? 1 : 0;
    },
    hLineColor: function (i, node) {
      return i <= 1 || i === node.table.body.length ? 'black' : 'lightgray';
    },
  }
}