import { Component, Input,SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { AuthService } from '../../../../shared/services/auth.service';
import { IProjectSearchResult } from '../../../projects/shared/models/project-search-result';
import { ITableColumn, TableColumnType } from '../../../controls/table/table-column';
import { ITableFilter } from '../../../controls/table/table-filter';
import { IUser } from '../../../../shared/models/user';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-committee-details-project',
  templateUrl: './committee-details-project.component.html',
  styleUrl: './committee-details-project.component.scss',
})
export class CommitteeDetailsProjectComponent{
  @Input() projects: IProjectSearchResult[] | null = null;
  tableData: IProjectSearchResult[] = [];
  tableColumns: ITableColumn<IProjectSearchResult>[] = [];
  tableFilters: ITableFilter[] = [];
  loading: boolean = true;
  currentUser: IUser | null = null;
  subscriptions: Subscription = new Subscription();

  constructor(
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    const committee = changes['committee'];
    this.initialize();
  }

  initialize() {
    this.loading = true;

    if (!this.projects) {
      this.loading = false;
      return;
    }
    this.initializeTableData();
    this.initializeTableColumns();
    this.loading = false;
  }

  initializeTableData() {
    if(this.projects){
      this.tableData = this.projects;
    }

  }
  initializeTableColumns() {
    let columns = [
      {
        name: 'designation',
        displayName: 'Designation',
        type: TableColumnType.routerLink,
        isSortable: true,
        getLink: (row: IProjectSearchResult) => `/projects/${row.projectId}`,
        headerWidth: '250px'
      },
      {
        name: 'projectTitle',
        displayName: 'Project Title',
        isSortable: true,
        headerWidth: '35%'
      },
      {
        name: 'phase',
        displayName: 'Phase',
        isSortable: true
      },
      {
        name: 'phaseStart',
        displayName: 'Phase Start',
        type: TableColumnType.date,
        isSortable: true,
      },
      {
        name: 'phaseEnd',
        displayName: 'Phase End',
        type: TableColumnType.date,
        isSortable: true,
      },
      {
        name: 'status',
        displayName: 'Project Status',
        isSortable: true
      }
    ]
    this.tableColumns = columns;
  }

}
