import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { appConstants } from '../../shared/constants/app-constants';
import { AuthService } from '../../shared/services/auth.service';

@Component({
  selector: 'app-authorisation',
  templateUrl: './authorisation.component.html',
  styleUrl: './authorisation.component.scss'
})
export class AuthorisationComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit() {
    const token = this.route.snapshot.paramMap.get('token');
    const landingPage = this.route.snapshot.queryParamMap.get('landingPage');

    if (!token) {
      this.authService.logout();
      return;
    }

    localStorage.setItem(appConstants.localStorageKey.token, token);
    this.authService.getTokens()
      .subscribe(response => {
        if(!response.success) {
          this.authService.logout();
        }

        const url = `/${landingPage}`;    
        this.router.navigate([url]);
      });
  }
}
