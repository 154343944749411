import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SaLibraryComponent } from './sa-library.component';
import { authGuard } from '../../shared/guards/auth.guard';
import { DocumentDetailsComponent } from './document-details/document-details.component';

const routes: Routes = [
  {
    path: 'sa-library',
    component: SaLibraryComponent,
    canActivate: [authGuard]
  },
  {
    path: 'sa-library/:id',
    component: DocumentDetailsComponent,
    canActivate: [authGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SaLibraryRoutingModule { }
