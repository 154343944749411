<div
  *ngIf="projectDetails"
  class="flex justify-between items-stretch gap-12 children:w-1/2 children:flex-1 children:children:h-full"
>
  <app-card>
    <app-project-details-overview-project-details
      [committeeCode]="projectDetails.committeeCode"
    ></app-project-details-overview-project-details>
  </app-card>

  <app-card>
    <div class="flex flex-col h-full [&_nav>a]:pt-0 [&_nav>a]:text-[1.74rem]">
      <app-tab
        id="tab-public-comments-and-ballot"
        #appTab
        [tabs]="hasAccessToBallotsTab ? [
          { id: 'publicComments', label: 'Public Comments' },
          { id: 'ballot', label: 'Ballot' }
        ] : [
          { id: 'publicComments', label: 'Public Comments' }
        ]"
        (onTabChange)="onTabChange($event)">
      >
      </app-tab>

      <div
        [class.hidden]="selectedTab !== 'publicComments'"
        class="flex-grow flex"
      >
        <app-project-details-overview-public-comments
          class="flex-grow flex"
          [projectDetails]="projectDetails"
        ></app-project-details-overview-public-comments>
      </div>

      <div
        [class.hidden]="appTab.selectedTab !== 'ballot'"
        class="flex-grow flex"
      >
        <app-project-details-overview-ballot
          class="flex-grow flex"
          [projectDetails]="projectDetails"
        ></app-project-details-overview-ballot>
      </div>
    </div>
  </app-card>
</div>
