function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

// import Integer, { isInteger } from '../types/Integer.js'
// import URL, { isURL } from '../types/URL.js'
// import Email, { isEmail } from '../types/Email.js'
import $text from '../xml/sanitizeText.js';
import getAttributesString from '../xml/getAttributesString.js';
import generateCellNumber from './generateCellNumber.js';
import convertDateToExcelSerial from './convertDateToExcelSerial.js';
export default function generateCell(rowNumber, columnIndex, value, type, cellStyleId, getSharedString) {
  // Empty cells could be skipped completely,
  // if they don't have a style applied to them,
  // like border or background color.
  if (value === null) {
    if (!cellStyleId) {
      return '';
    }
  }

  var cellAttributes = {
    r: generateCellNumber(columnIndex, rowNumber)
  }; // Available formatting style IDs (built-in in Excel):
  // https://xlsxwriter.readthedocs.io/format.html#format-set-num-format
  // `2` — 0.00
  // `3` —  #,##0

  if (cellStyleId) {
    // From the attribute s="12" we know that the cell's formatting is stored at the 13th (zero-based index) <xf> within the <cellXfs>
    cellAttributes.s = cellStyleId;
  }

  if (value === null) {
    return "<c".concat(getAttributesString(cellAttributes), "/>");
  } // Validate date format.


  if (type === Date && !cellStyleId) {
    throw new Error('No "format" has been specified for a Date cell');
  }

  value = getXlsxValue(type, value, getSharedString);
  type = getXlsxType(type); // The default value for `t` is `"n"` (a number or a date).

  if (type) {
    cellAttributes.t = type;
  }

  var _getOpeningAndClosing = getOpeningAndClosingTags(type),
      _getOpeningAndClosing2 = _slicedToArray(_getOpeningAndClosing, 2),
      openingTags = _getOpeningAndClosing2[0],
      closingTags = _getOpeningAndClosing2[1];

  return "<c".concat(getAttributesString(cellAttributes), ">") + openingTags + value + closingTags + '</c>';
}

function getXlsxType(type) {
  // Available Excel cell types:
  // https://github.com/SheetJS/sheetjs/blob/19620da30be2a7d7b9801938a0b9b1fd3c4c4b00/docbits/52_datatype.md
  //
  // Some other document (seems to be old):
  // http://webapp.docx4java.org/OnlineDemo/ecma376/SpreadsheetML/ST_CellType.html
  //
  switch (type) {
    case String:
      // case Email:
      // case URL:
      return 's';
    // // "inlineStr" type is used instead of "s" to avoid creating a "shared strings" index.
    // return 'inlineStr'

    case Number:
      // case Integer:
      // `n` is the default cell type (if no `t` has been specified).
      // return 'n'
      return;

    case Date:
      // `n` is the default cell type (if no `t` has been specified).
      // return 'n'
      return;

    case Boolean:
      return 'b';

    case 'Formula':
      return 'f';

    default:
      throw new Error("Unknown schema type: ".concat(type && type.name || type));
  }
}

function getXlsxValue(type, value, getSharedString) {
  // Available Excel cell types:
  // https://github.com/SheetJS/sheetjs/blob/19620da30be2a7d7b9801938a0b9b1fd3c4c4b00/docbits/52_datatype.md
  //
  // Some other document (seems to be old):
  // http://webapp.docx4java.org/OnlineDemo/ecma376/SpreadsheetML/ST_CellType.html
  //
  switch (type) {
    case String:
      // case Email:
      // case URL:
      if (typeof value !== 'string') {
        throw new Error("Invalid cell value: ".concat(value, ". Expected a string"));
      } // if (type === Email && !isEmail(value)) {
      //   throw new Error(`Invalid cell value: ${value}. Expected an Email`)
      // }
      // if (type === URL && !isURL(value)) {
      //   throw new Error(`Invalid cell value: ${value}. Expected a URL`)
      // }


      return getSharedString(value);

    case Number:
      // case Integer:
      if (typeof value !== 'number') {
        throw new Error("Invalid cell value: ".concat(value, ". Expected a number"));
      } // if (type === Integer && !isInteger(value)) {
      //   throw new Error(`Invalid cell value: ${value}. Expected an Integer`)
      // }


      return String(value);

    case Date:
      if (!(value instanceof Date)) {
        throw new Error("Invalid cell value: ".concat(value, ". Expected a Date"));
      } // "d" type doesn't seem to work.
      // return value.toISOString()


      return String(convertDateToExcelSerial(value));

    case Boolean:
      if (typeof value !== 'boolean') {
        throw new Error("Invalid cell value: ".concat(value, ". Expected a boolean"));
      }

      return value ? '1' : '0';

    case 'Formula':
      if (typeof value !== 'string') {
        throw new Error("Invalid cell value: ".concat(value, ". Expected a string"));
      }

      return $text(value);

    default:
      throw new Error("Unknown schema type: ".concat(type && type.name || type));
  }
}

var TAG_BRACKET_LEFT_REGEXP = /</g;

function getOpeningAndClosingTags(xlsxType) {
  var openingTags = getOpeningTags(xlsxType);
  var closingTags = openingTags.replace(TAG_BRACKET_LEFT_REGEXP, '</');
  return [openingTags, closingTags];
}

function getOpeningTags(xlsxType) {
  switch (xlsxType) {
    case 'inlineStr':
      return '<is><t>';

    case 'f':
      return '<f>';

    default:
      return '<v>';
  }
}
