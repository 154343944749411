import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-update-scroller',
  templateUrl: './update-scroller.component.html',
  styleUrl: './update-scroller.component.scss'
})
export class UpdateScrollerComponent {
  @Input() title: string = '';
  @Input() updateCount: number = 0;
}
