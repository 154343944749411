import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { Observable, of, Subscription, switchMap } from 'rxjs';
import { IApiResponse } from '../../../shared/models/api-response';
import { IUser } from '../../../shared/models/user';
import { AuthService } from '../../../shared/services/auth.service';
import { IProjectSearchResult } from '../../projects/shared/models/project-search-result';
import { ProjectService } from '../../projects/shared/services/project.service';
import { DocumentService } from '../../projects/shared/services/document.service';
import { ICommittee } from '../shared/models/committee';
import { CommitteeService } from '../shared/services/committee.service';
import { UserUtility } from '../../../shared/utilities/user-utility';
import { ITab } from '../../controls/tab/tab.component';
import { ISubCommittee } from '../shared/models/sub-committee';
import { appConstants } from '../../../shared/constants/app-constants';
import { ISIMPublishedDocument, ISIMPublishedDocumentByCommitteeResult } from '../shared/models/committee-published-document';
@Component({
  selector: 'app-committee-details',
  templateUrl: './committee-details.component.html',
  styleUrl: './committee-details.component.scss'
})
export class CommitteeDetailsComponent implements OnDestroy {
  faDownload = faDownload;

  currentUser!: IUser;


  loading: boolean = true;
  subscriptions: Subscription = new Subscription();

  committee: ICommittee | null = null;
  reloadRecentlyViewedCommittees: number = 0;
  selectedTab: string = '';
  hasAccessToDeclarationOfInterest: boolean = false;
  subCommittees: ISubCommittee[] = [];
  isShowingSubCommittees: boolean = false;
  projects: IProjectSearchResult[] | null = null;
  publications: any;
  hasLoadingPublications: boolean = false;

  tabIds = {
    overview: 'overview',
    internationalParticipation: 'internationalParticipation',
    committeeMembership: 'committeeMembership',
    projects: 'projects',
    publications: 'publications',
    subCommitteesAndWorkingGroups: 'subCommitteesAndWorkingGroups',
    declarationOfInterest: 'declarationOfInterest'
  }
  tabs: ITab[] = [
    { id: this.tabIds.overview, label: 'Overview' },
    { id: this.tabIds.internationalParticipation, label: 'International Participation' },
    { id: this.tabIds.committeeMembership, label: 'Committee Membership' },
    { id: this.tabIds.projects, label: 'Projects' },
    { id: this.tabIds.publications, label: 'Publications' },
    { id: this.tabIds.subCommitteesAndWorkingGroups, label: 'Sub-Committees and Working Groups'}
  ];

  constructor(
    private _authService: AuthService,
    private _committeeService: CommitteeService,
    private _projectService: ProjectService,
    private _documentService: DocumentService,
    private _activatedRoute: ActivatedRoute
  ) {
    const userSubscription = this._authService.currentUserSubject.subscribe(user => {
      if (user) {
        this.currentUser = user;
        this.initialize();
      }
    });

    const paramMapSubscription =
      this._activatedRoute.paramMap.pipe(
        switchMap((params: ParamMap) =>  {
          this.reloadRecentlyViewedCommittees++;
          return of(this.reloadRecentlyViewedCommittees);
      })
    ).subscribe();

    this.subscriptions.add(userSubscription);
    this.subscriptions.add(paramMapSubscription);
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
    this.isShowingSubCommittees = false;
    this.hasLoadingPublications = false;
  }

  initialize() {
    // getCommittee should be triggered once recent viewed committee has finish loading - so that it won't be shown as recently viewed in the page yet
      this.getCommitteeProjects();
      this.getCommitteePublishedDocuments();
      this.processDeclarationOfInterest();
  }

  onRecentlyViewedCommitteeFinishedLoading(){
    this.getCommittee();
  }

  getCommittee() {
    const committeeCode = this._activatedRoute.snapshot.paramMap.get('code');
    if (!committeeCode) {
      this.loading = false;
      return;
    }

    const committeeSubscription = this._committeeService
      .getCommittee(committeeCode, true)
      .subscribe({
        next: (response: IApiResponse<ICommittee>) => {
          if (response.success) {
            this.committee = response.content;
            const subCommittees = this.committee?.subCommittees;
            if(subCommittees && subCommittees?.length > 0) {
              const activeCommittees = subCommittees.filter(committee =>
                committee.committeeStatus.toLowerCase() == appConstants.committeeStatus.active
              );
              this.subCommittees = activeCommittees;
              this.isShowingSubCommittees = activeCommittees && activeCommittees.length > 0;
            }
          }

          this.loading = false;
        },
        error: _ => {
          this.loading = false;
        },
      });

    this.subscriptions.add(committeeSubscription);
  }

  processDeclarationOfInterest() {
    this.hasAccessToDeclarationOfInterest = !UserUtility.isMemberOfPublicOnly(this.currentUser);
    if(this.hasAccessToDeclarationOfInterest && !this.tabs.find(x => x.id === this.tabIds.declarationOfInterest)) {
      this.tabs.push({ id: this.tabIds.declarationOfInterest, label: 'Declaration of Interest' });
    }
  }

  onTabChange(tabId: string) {
    this.selectedTab = tabId;
  }

  getCommitteeProjects() {
    const committeeCode = this._activatedRoute.snapshot.paramMap.get('code');
    if (!committeeCode) {
      return;
    }

    const projectSearchSubscription = this._projectService
      .searchProjects({ committees: [committeeCode], page: 1, limit: 10000 })
      .subscribe({
        next: response => {
          this.projects = response.content ?? [];
        },
        error: _ => {
          this.projects = [];
        },
      });
    this.subscriptions.add(projectSearchSubscription);
  }

  getCommitteePublishedDocuments() {
    const committeeCode = this._activatedRoute.snapshot.paramMap.get('code');
    if (!committeeCode) {
      return;
    }
    const SIMDocumentSearchSubscription = this._documentService
      .getSIMPublishedDocuments(committeeCode)
      .subscribe({
        next: (response: IApiResponse<string>)  => {
          if (response.success) {
            const values = response.content;
            const result: ISIMPublishedDocumentByCommitteeResult = JSON.parse(response.content);
            if(result && result.standards) {
              this.publications = result.standards.standard
              .filter(doc => doc["db-meta"]["publication-status"] === appConstants.SIMPublicationStatus.Current
                || doc["db-meta"]["publication-status"] === appConstants.SIMPublicationStatus.PendingRevision)
              .sort((a, b) => +new Date(b['nat-meta']['pub-date'].content) - +new Date(a['nat-meta']['pub-date'].content));
            } else {
              this.publications = null;
            }
            if (this.publications) {
              this.publications.forEach((doc: any) => {
                doc.title = doc["nat-meta"]["title-wrap"].full
              });
            }
          }
          this.hasLoadingPublications = true;
        },
        error: _ => {
          this.publications = [];
          this.hasLoadingPublications = true;
        }

      });
    this.subscriptions.add(SIMDocumentSearchSubscription);
  }

  getTabs(){
    if (!this.isShowingSubCommittees) {
      this.tabs = this.tabs.filter(tab => tab.id !== this.tabIds.subCommitteesAndWorkingGroups);
    }
    return this.tabs;
  }
}
