import { Component } from '@angular/core';

@Component({
  selector: 'app-sa-logo',
  templateUrl: './sa-logo.component.html',
  styleUrl: './sa-logo.component.scss'
})
export class SaLogoComponent {

}
