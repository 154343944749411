<div class="header-container w-full bg-brand-darkBlue text-white flex justify-between items-stretch px-10 pt-2">
    <app-sa-logo></app-sa-logo>

    <ng-container *ngIf="currentUser">
        <div class="nav">
            <a [routerLink]="['/dashboard']" [ngClass]="{ 'active': isDashboardActive }" id="a-dashboard-tab">Dashboard</a>
            <a [routerLink]="['/projects']" routerLinkActive="active" id="a-project-search-tab">Projects</a>

            <a *ngIf="canAccessNormOrgPage" [routerLink]="['/nominating-organisations']" routerLinkActive="active" id="a-header-nom-org">
                Nom Org
            </a>

            <a *ngIf="canAccessStandardsLibraryPage" [routerLink]="['/sa-library']" routerLinkActive="active" id="a-connect-sim-tab">
                SA Library
            </a>
            <a *ngIf="canAccessStandardsLibrarySimPage && appUrls" href="{{appUrls.simUrl}}" target="_blank" id="a-external-sim-tab">
                SA Library
            </a>

            <a [routerLink]="['/committees']" routerLinkActive="active" id="a-committee-search-tab">Committees</a>

            <a *ngIf="canAccessBallotsPage" [routerLink]="['/ballots']" routerLinkActive="active" id="a-ballots-tab">Ballots</a>
        </div>

        <div class="nav-apps flex justify-end items-center">

            <div *ngIf="!(appUrls && isSalesforceJsLoaded)" class="flex justify-center items-center flex-grow">
                <mat-progress-spinner mode="indeterminate" diameter="20"></mat-progress-spinner>
            </div>
            <ng-container *ngIf="appUrls">
                <a target="_blank"
                    href="{{isInternalUser ? appUrls.committeeWorkspaceUrlInternal : appUrls.committeeWorkspaceUrlExternal}}"
                    id="a-committee-workspace-tab"
                    *ngIf="canAccessCommitteeWorkspacePage"
                >
                    Workspace
                </a>
                <a (click)="handleOpenAppLauncher()" id="a-header-apps">
                    <ng-container *ngIf="isSalesforceJsLoaded">
                        Apps
                    </ng-container>
                    <mat-progress-spinner *ngIf="!isSalesforceJsLoaded" mode="indeterminate"
                        diameter="24"></mat-progress-spinner>
                </a>
            </ng-container>

            <div>
                <button mat-button [matMenuTriggerFor]="userMenu" class="text-white text-2xl" id="a-user=menu">
                    <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.25 2.04134C6.22496 2.04134 4.58333 3.68296 4.58333 5.70801C4.58333 7.73302 6.22496 9.37467 8.25 9.37467C10.275 9.37467 11.9167 7.73302 11.9167 5.70801C11.9167 3.68296 10.275 2.04134 8.25 2.04134ZM2.75 5.70801C2.75 2.67044 5.21243 0.208008 8.25 0.208008C11.2876 0.208008 13.75 2.67044 13.75 5.70801C13.75 8.74557 11.2876 11.208 8.25 11.208C5.21243 11.208 2.75 8.74557 2.75 5.70801ZM4.58333 14.8747C3.06455 14.8747 1.83333 16.1059 1.83333 17.6247C1.83333 18.1309 1.42292 18.5413 0.916667 18.5413C0.41041 18.5413 0 18.1309 0 17.6247C0 15.0934 2.05203 13.0413 4.58333 13.0413H11.9167C14.448 13.0413 16.5 15.0934 16.5 17.6247C16.5 18.1309 16.0896 18.5413 15.5833 18.5413C15.0771 18.5413 14.6667 18.1309 14.6667 17.6247C14.6667 16.1059 13.4355 14.8747 11.9167 14.8747H4.58333Z" fill="white" />
                    </svg>
                </button>
                <mat-menu #userMenu="matMenu" class="bg-white">
                    <span mat-menu-item class="p-3 bg-white cursor-default" id="a-user-menu-option-name">
                        <b>{{currentUser.firstName}} {{ currentUser.lastName}}</b>
                    </span>

                    <a *ngIf="appUrls" mat-menu-item class="hover:bg-gray-200 text-black"
                        href="{{appUrls.iamProfileUrl}}/{{currentUser.userId}}" target="_blank"
                        id="a-user-menu-option-profile-link"
                    >
                        Profile
                    </a>

                    <a mat-menu-item class="hover:bg-gray-200text-black" [routerLink]="['/settings']" id="a-user-menu-option-settings-link">
                        Settings
                    </a>

                    <a mat-menu-item class="hover:bg-gray-200 text-black" (click)="handleLogout()" id="a-user-menu-option-logout-link">
                        Logout
                    </a>
                </mat-menu>
            </div>
        </div>
    </ng-container>
</div>

<div id="{{sfAppLauncherContainerId}}"></div>
