import { Component } from '@angular/core';
import { IUser } from '../../../shared/models/user';
import { AuthService } from '../../../shared/services/auth.service';
import { map, Subscription } from 'rxjs';
import { IConnectLinkItem } from '../shared/models/connect-link-item';
import { IBlobOverview } from '../../../shared/models/blob-overview';
import { GuidesAndVideosService } from '../shared/services/guides-and-videos.service';
import { IBlobLinkItem } from '../shared/models/blob-link-item';
import { IApiResponse } from '../../../shared/models/api-response';
import { IBlobFile } from '../../../shared/models/blob-file';
import { BlobDownloadUtility } from '../../../shared/utilities/blob-download-utility';
import FileDownload from 'js-file-download';

@Component({
  selector: 'app-guides-and-videos',
  templateUrl: './guides-and-videos.component.html',
  styleUrl: './guides-and-videos.component.scss'
})
export class GuidesAndVideosComponent {

  currentUser: IUser | null = null;
  subscription: Subscription = new Subscription();

  committeeWorkspaceLinks: IBlobLinkItem[] = [];
  systematicReviewLinks: IBlobLinkItem[] = [];

  constructor(
    private _authService: AuthService,
    private _guidesAndVideosService: GuidesAndVideosService
  ) { }

  ngOnInit(): void {
    this.initialise();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }


  initialise() {
    this.subscription = this._authService.currentUserSubject.subscribe({
      next: (user) => {
        this.currentUser = user;
      },
      error: (error) => {
        // Log to sumologic
      }
    });

    const blobOverviewSubscription = this._guidesAndVideosService.GetUserGuideAndVideoContainerContents().subscribe({
      next: (response) => {
        if (response.success) {
          if (response.content && response.content.length > 0) {
            this.committeeWorkspaceLinks = this.getCommitteeWorkspaceLinks(response.content);
            this.systematicReviewLinks = this.getSystematicReviewLinks(response.content);
          } else {
            this.committeeWorkspaceLinks = [];
            this.systematicReviewLinks = [];
          }
        } else {
          // Log to sumologic
        }
      },
      error: (error) => {
        // Log to sumologic
      }
    });

    this.subscription.add(blobOverviewSubscription);
  }

  getCommitteeWorkspaceLinks(blobOverviews: IBlobOverview[]): IBlobLinkItem[] {
    const committeeWorkspaceLinks: IBlobLinkItem[] = [
      {
        id: 'btn-training-guide-for-committee-members',
        displayText: 'Training Guide for Committee Members - PDF',
        href: '',
        routerLink: '',
        action: true,
        show: true,
        blobOverview: blobOverviews.find(x => x.blobName.toLowerCase() === 'CommitteeWorkspaceUserGuide_01.pdf'.toLowerCase()) || null
      },
      {
        id: 'btn-overview',
        displayText: 'Overview - Video',
        href: '',
        routerLink: '',
        action: true,
        show: true,
        blobOverview: blobOverviews.find(x => x.blobName.toLowerCase() === 'CommitteeWorkspaceUserVideo_01.mp4'.toLowerCase()) || null
      },
      {
        id: 'btn-login-logout',
        displayText: 'Log In Log Out - Video',
        href: '',
        routerLink: '',
        action: true,
        show: true,
        blobOverview: blobOverviews.find(x => x.blobName.toLowerCase() === 'CommitteeWorkspaceUserVideo_02.mp4'.toLowerCase()) || null
      },
      {
        id: 'btn-using-filters',
        displayText: 'Using Filters - Video',
        href: '',
        routerLink: '',
        action: true,
        show: true,
        blobOverview: blobOverviews.find(x => x.blobName.toLowerCase() === 'CommitteeWorkspaceUserVideo_03.mp4'.toLowerCase()) || null
      },
      {
        id: 'btn-uploading-document-to-members-area-folder',
        displayText: "Uploading a Document to the Member's Area Folder - Video",
        href: '',
        routerLink: '',
        action: true,
        show: true,
        blobOverview: blobOverviews.find(x => x.blobName.toLowerCase() === 'CommitteeWorkspaceUserVideo_04.mp4'.toLowerCase()) || null
      },
      {
        id: 'btn-adding-folders-to-favourites',
        displayText: 'Adding Folders to Favourites - Video',
        href: '',
        routerLink: '',
        action: true,
        show: true,
        blobOverview: blobOverviews.find(x => x.blobName.toLowerCase() === 'CommitteeWorkspaceUserVideo_05.mp4'.toLowerCase()) || null
      },
      {
        id: 'btn-adding-documents-to-favourites',
        displayText: 'Adding Documents to Favourites - Video',
        href: '',
        routerLink: '',
        action: true,
        show: true,
        blobOverview: blobOverviews.find(x => x.blobName.toLowerCase() === 'CommitteeWorkspaceUserVideo_06.mp4'.toLowerCase()) || null
      },
      {
        id: 'btn-downloading-a-document',
        displayText: 'Downloading a Document - Video',
        href: '',
        routerLink: '',
        action: true,
        show: true,
        blobOverview: blobOverviews.find(x => x.blobName.toLowerCase() === 'CommitteeWorkspaceUserVideo_07.mp4'.toLowerCase()) || null
      },
      {
        id: 'btn-adding-notes',
        displayText: 'Adding Notes - Video',
        href: '',
        routerLink: '',
        action: true,
        show: true,
        blobOverview: blobOverviews.find(x => x.blobName.toLowerCase() === 'CommitteeWorkspaceUserVideo_08.mp4'.toLowerCase()) || null
      },
      {
        id: 'btn-printing-documents',
        displayText: 'Printing Documents - Video',
        href: '',
        routerLink: '',
        action: true,
        show: true,
        blobOverview: blobOverviews.find(x => x.blobName.toLowerCase() === 'CommitteeWorkspaceUserVideo_09.mp4'.toLowerCase()) || null
      },
    ];
    
    return committeeWorkspaceLinks;
  }

  private getSystematicReviewLinks(blobOverviews: IBlobOverview[]) : IBlobLinkItem[] {
    const srsLinks : IBlobLinkItem[] = [
      {
        id: 'btn-srs-guide',
        displayText: 'GU 635a - Systematic Review Help Guide for Committee Members v1 - PDF',
        href: '',
        routerLink: '',
        action: true,
        show: true,
        blobOverview: blobOverviews.find(x => x.blobName.toLowerCase() === 'SrsUserGuide_01.pdf'.toLowerCase()) || null
      },
    ];

    return srsLinks;
  }


  public onActionClicked(linkItem: IConnectLinkItem) : void {
    const allLinks = this.committeeWorkspaceLinks.concat(this.systematicReviewLinks);
    const blobOverview =  allLinks.findIndex(x => x.id === linkItem.id) > -1 ?
                             allLinks.filter(x => x.id === linkItem.id)[0].blobOverview :
                             null;

    if (blobOverview) {
      const subscription = this._guidesAndVideosService.DownloadUserGuideAndVideo(blobOverview.containerName, blobOverview.blobName)
        .subscribe({
          next: (response: IApiResponse<IBlobFile>) => {
            if(response.success && response.content) {
              const blobFile = response.content;
              BlobDownloadUtility.downloadBlobToBrowser(blobFile);
            }
          },
          error: (error) => {
            // Log to sumologic
          }
      });

      this.subscription.add(subscription);
    }
  }
}

