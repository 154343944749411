import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { Observable, map } from 'rxjs';
import { IApiResponse } from '../../../../shared/models/api-response';
import { ISIMPublishedDocumentByCommitteeResult } from '../../../committees/shared/models/committee-published-document';

@Injectable({
  providedIn: 'root'
})

export class DocumentService {

  baseUrl: string = `${environment.servicesUrl.document}api/Document`;

  constructor(
    private http: HttpClient,
  ) { }


  getSIMPublishedDocuments(committeeCode: string): Observable<IApiResponse<string>> {
    return this.http
      .get<IApiResponse<string>>(`${this.baseUrl}/get-published-documents-by-committee/${committeeCode}`)
  }

}
