import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../shared/services/auth.service';
import { IRecentlyViewedProject } from '../shared/models/recently-viewed-project';
import { ProjectService } from '../shared/services/project.service';
import { StatusBadgeColourScheme } from '../../controls/status-badge/status-badge-constants';

@Component({
  selector: 'app-recently-viewed-projects',
  templateUrl: './recently-viewed-projects.component.html',
  styleUrl: './recently-viewed-projects.component.scss'
})
export class RecentlyViewedProjectsComponent implements OnDestroy {

  public statusBadgeColourSchemeEnum = StatusBadgeColourScheme;
  loading: boolean = true;
  subscriptions: Subscription = new Subscription();

  projects: IRecentlyViewedProject[] | null = null;

  constructor(
    private _authService: AuthService,
    private _projectService: ProjectService,
  ) {
    const userSubscription = this._authService.currentUserSubject.subscribe(user => {
      if (user) {
        this.initialize();
      }
    });

    this.subscriptions.add(userSubscription);
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  initialize() {
    this.getRecentlyViewedProjects();
  }

  getRecentlyViewedProjects() {
    const recentlyViewedProjects = this._projectService.getRecentlyViewedProjects().subscribe(response => {
      if (response.success) {
        this.projects = response.content;
        this.loading = false;
      }
    });

    this.subscriptions.add(recentlyViewedProjects);
  }
}
