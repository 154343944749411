import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { StatusBadgeColourScheme } from '../../../../controls/status-badge/status-badge-constants';
import { PcmsDraftStatus } from '../../../../../shared/constants/pcms-constants';

@Component({
  selector: 'app-public-commenting-status-badge',
  templateUrl: './public-commenting-status-badge.component.html',
  styleUrl: './public-commenting-status-badge.component.scss'
})
export class PublicCommentingStatusBadgeComponent implements OnChanges {
  @Input() pcmsDraftStatus: string = '';
  statusText: string = '';
  public statusBadgeColourSchemeEnum: StatusBadgeColourScheme = StatusBadgeColourScheme.Green;
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['pcmsDraftStatus']) {
      this.statusText = this.getStatusText(this.pcmsDraftStatus);
      this.statusBadgeColourSchemeEnum = this.getColourScheme(this.pcmsDraftStatus);
    }
  }
  getColourScheme(pcmsDraftStatus: string): StatusBadgeColourScheme {
    switch (pcmsDraftStatus) {
      case PcmsDraftStatus.Open:
        return StatusBadgeColourScheme.Green;
      default:
        return StatusBadgeColourScheme.Red;
    }
  }

  getStatusText(draftStatus: string): string {
    switch (draftStatus) {
      case undefined:
      case null:
      case '':
      case PcmsDraftStatus.NotOpen:
        return 'Not Started';
      case PcmsDraftStatus.Open:
        return 'Open';
      default:
        return 'Closed';
    }
  }
}

