import { Component, Input } from '@angular/core';
import { ISubCommittee } from '../shared/models/sub-committee';
import { ITableColumn, TableColumnType} from '../../controls/table/table-column';
@Component({
  selector: 'app-sub-committees',
  templateUrl: './sub-committees.component.html',
  styleUrl: './sub-committees.component.scss'
})
export class SubCommitteesComponent {
  @Input() subCommittees: ISubCommittee[] = [];
  tableColumns: ITableColumn<ISubCommittee>[] = [
    {
      name: 'committeeCodeAndName',
      displayName: 'Committee',
      isSortable: true,
      type: TableColumnType.routerLink,
      getLink: (row: ISubCommittee) => `/committees/${row.committeeCode}`,
      headerWidth: '600px'
    },
    {
      name: 'committeeType',
      displayName: 'Group',
      isSortable: true,
    },
    {
      name: 'committeeStatus',
      displayName: 'Committee Status',
      isSortable: true
    }
  ];
}
