import { Injectable, NgZone } from '@angular/core';
import { IPieChartDataItem } from '../models/pie-chart-data-item';
import { Chart, DoughnutController , ArcElement, registerables } from 'chart.js';

@Injectable({
  providedIn: 'root'
})
export class ChartjsService {

  constructor(
    public ngZone: NgZone
  ) { }

  public renderDoughnutChart(
    canvas: HTMLCanvasElement,
    dataItems: IPieChartDataItem[]) : void {
    
    const centerTextPlugin = {
        id: 'centerTextPlugin',
        afterDatasetsDraw: (chart : any, args: any, options: any) => {
            const ctx = chart.ctx;
            const data = chart.data;
            const total = data.datasets[0].data.reduce((acc: any, val:any) => acc + val, 0);
    
            const midX = chart.chartArea.left + (chart.chartArea.right - chart.chartArea.left) / 2;
            const midY = chart.chartArea.top + (chart.chartArea.bottom - chart.chartArea.top) / 2;
    
            ctx.save();

            // Use relative font sizes
            const bigFontSize = Math.min(chart.chartArea.width, chart.chartArea.height) * 0.25; // 10% of the smaller dimension
            const smallFontSize = bigFontSize * 0.3; // 20% of the big number font size
            const positionOffset =  Math.min(chart.chartArea.width, chart.chartArea.height) * 0.04;

            // Draw the big number
            ctx.fillStyle = 'black'; // Customize text color
            ctx.font = `bold  ${bigFontSize}px Arial`; // Customize font size for the big number
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillText(`${total}`, midX, midY -  positionOffset); // Position the big number
    
            // Draw "Comments" text below the big number
            ctx.font = `bold ${smallFontSize}px Arial`; // Customize font size for "Comments"
            ctx.fillText('Comments', midX, midY + positionOffset*3); // Position "Comments" text below
    
            ctx.restore();
        }
    };


    Chart.register(DoughnutController, ArcElement, centerTextPlugin, ...registerables);

    this.ngZone.runOutsideAngular(() => {
        const canvasContext = canvas?.getContext('2d');
        if (!canvasContext) {
            return;
        }

        const chart = new Chart(
            canvasContext,
            {
                type: 'doughnut',
                options: {
                    animation: true,
                    responsive: true,
                    maintainAspectRatio: false,
                    cutout: '80%',
                    elements: {
                        arc: {
                            backgroundColor: dataItems.map(row => row.color)
                        }
                    },
                    plugins: {
                        legend: {
                            display: false
                        },
                        tooltip: {
                            enabled: false
                        }
                    }
                },
                data: {
                    labels: dataItems.map(row => row.label),
                    datasets: [
                        {
                            label: 'Any Label',
                            data: dataItems.map(row => row.count),
                        }
                    ],
                }
            }
        );
        
    });
    
  };

}
