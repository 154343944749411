import { Component, Input } from '@angular/core';
import { AuthService } from '../../../shared/services/auth.service';
import { IUser } from '../../../shared/models/user';
import { IConnectLinkItem } from '../shared/models/connect-link-item';
import { combineLatest, Subscription } from 'rxjs';
import { ConfigurationService } from '../../../shared/services/configuration.service';
import { IAppUrls } from '../../../shared/models/app-urls';
import { UserUtility } from '../../../shared/utilities/user-utility';

@Component({
  selector: 'app-connect-links',
  templateUrl: './connect-links.component.html',
  styleUrl: './connect-links.component.scss'
})
export class ConnectLinksComponent {

  @Input() id: string = '';

  connectLinkItems: IConnectLinkItem[] = [];
  subscriptions: Subscription = new Subscription();
  

  constructor(
    public _authService: AuthService,
    private _configService: ConfigurationService,
  ) {
  }

  ngOnDestroy(): void {
    if(this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.initialize();
  }

  initialize() {
    const userSubscription = 
      combineLatest([
        this._authService.currentUserSubject,
        this._configService.getAppUrls()
      ])
      .subscribe({
        next: (result: [IUser | null, IAppUrls]) => {
          const user = result[0];
          const appUrls = result[1];
          if(user && appUrls) {
            this.connectLinkItems = this.getConnectLinks(user, appUrls);
          } else {
            this.connectLinkItems = [];
          }
        },
        error: (error) => {
          // TODO: Log to SumoLogic;
        }
      });

      
    this.subscriptions.add(userSubscription);
  }

  getConnectLinks(currentUser: IUser, appUrls: IAppUrls): IConnectLinkItem[] {
    const isInternalUser = UserUtility.isInternalUser(currentUser);
    const userIsAboveMoP = UserUtility.userIsAboveMoP(currentUser);

    const connectLinkItems: IConnectLinkItem[] = [
      {
        id: 'link-public-comment',
        displayText: 'Drafts Open for Public Comment',
        href: '',
        routerLink: '/projects',
        action: false,
        show: true,
      },
      {
        id: 'link-international-participation-platform',
        displayText: 'International Participation Platform (Meetings)',
        href: appUrls.internationalParticipationPlatformUrl ?? '',
        routerLink: '',
        action: false,
        show: userIsAboveMoP
      },
      {
        id: 'link-isolutions',
        displayText: 'ISOlutions',
        href: 'https://isolutions.iso.org/portal/',
        routerLink: '',
        action: false,
        show: userIsAboveMoP
      },
      {
        id: 'link-iso',
        displayText: 'International Organization for Standardization',
        href: 'https://www.iso.org/home.html',
        routerLink: '',
        action: false,
        show: true
      },
      {
        id: 'link-iec',
        displayText: 'International Electrotechnical Commission',
        href: 'https://www.iec.ch/homepage',
        routerLink: '',
        action: false,
        show: true
      },
      {
        id: 'link-committee-member-view-of-sim',
        displayText: "Committee Member's View of SIM",
        href: '',
        routerLink: '/sa-library',
        action: false,
        show: isInternalUser
      },
      {
        id: 'link-standards-academy',
        displayText: 'Standards Academy',
        href: appUrls.standardsAcademy ?? '',
        routerLink: '',
        action: false,
        show: userIsAboveMoP
      },
      {
        id: 'link-sa-website',
        displayText: 'Standards Australia Website',
        href: 'https://www.standards.org.au',
        routerLink: '',
        action: false,
        show: true
      },
      {
        id: 'link-construction-dictionary',
        displayText: 'Construction Dictionary',
        href: 'https://www.constructiondictionary.com.au/',
        routerLink: '',
        action: false,
        show: true
      },
      {
        id: 'link-reports',
        displayText: 'Reports',
        href: '',
        routerLink: '/reports',
        action: false,
        show: true
      },
      {
        id: 'link-guides-and-videos',
        displayText: 'User Guides and Videos',
        href: '',
        routerLink: '/guides-and-videos',
        action: false,
        show: true
      },
      {
        id: 'link-submit-proposal',
        displayText: 'Submit a Proposal',
        href: appUrls.projectProposalPlatform?? '',
        routerLink: '',
        action: false,
        show: true
      },
    ];
    return connectLinkItems;
  }
}
