import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { addHours, addMinutes, format } from 'date-fns';
import { Attendee, EventAttributes, createEvent } from 'ics';
import { Subscription } from 'rxjs';
import { IUser } from '../../../shared/models/user';
import { AuthService } from '../../../shared/services/auth.service';
import { UserUtility } from '../../../shared/utilities/user-utility';
import { ICommittee } from '../shared/models/committee';

@Component({
  selector: 'app-button-schedule-committee-meeting',
  templateUrl: './button-schedule-committee-meeting.component.html',
  styleUrl: './button-schedule-committee-meeting.component.scss'
})
export class ButtonScheduleCommitteeMeetingComponent implements OnChanges, OnDestroy {

  faClock = faClock;

  @Input() committee!: ICommittee;

  loading: boolean = true;
  subscriptions: Subscription = new Subscription();
  authorized: boolean = false;
  currentUser: IUser | null = null;

  constructor(
    private _authService: AuthService,
  ) {
    const userSubscription = this._authService.currentUserSubject.subscribe(user => {
      if (user) {
        this.currentUser = user;
      }
    });

    this.subscriptions.add(userSubscription);
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const committee = changes['committee']?.currentValue;
    if (!committee || !this.currentUser) {
      return;
    }

    this.loading = true;
    if (UserUtility.isInternalUser(this.currentUser) || UserUtility.userIsOnCommittee(this.currentUser, committee.committeeCode)) {
      this.authorized = true;
    }

    this.loading = false;
  }

  private downloadCalendar(cal: string) {
    var blob = new Blob([cal], { type: "text/calendar" });
    var url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = 'schedule.ics';
    link.click();
  }

  getEventAttributes(){    
    let attendees: Attendee[] = [
      {
        email: "committee.meetings@standards.org.au",
        name: "Committee Meetings",
        rsvp: false,
        role: "OPT-PARTICIPANT"
      }
    ];

    this.committee.membershipDetails
      ?.filter(x => x.memberEmail && x.memberFullName)
      ?.forEach(x => {
        attendees.push({
          email: x.memberEmail,
          name: x.memberFullName,
          rsvp: true,
        });
      });

    const startDate = new Date();
    const start = format(startDate, 'yyyy-M-d-h-m').split("-").map(x => parseInt(x));

    let endDate = addHours(startDate, 2);
    endDate = addMinutes(endDate, 30);
    const end = format(endDate, 'yyyy-M-d-h-m').split("-").map(x => parseInt(x));

    return {
      start: start,
      end: end,
      title: " ",
      description: "",
      attendees: attendees,

    } as EventAttributes;
  }

  onDownloadCalendar(e: MouseEvent) {
    e.stopPropagation();

    const event = this.getEventAttributes();

    createEvent(event, (error, content) => {
      if (error) {
        console.log(error)
        return error;
      }

      this.downloadCalendar(content);
      return content;
    });
  }
}
