import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import orderBy from 'lodash/orderBy';
import pdfMake from 'pdfmake/build/pdfmake';
import { Content, TDocumentDefinitions } from 'pdfmake/interfaces';
import { Subscription } from 'rxjs';
import { pdfLogoBlob } from '../../../shared/constants/pdf-logo-blob';
import { AuthService } from '../../../shared/services/auth.service';
import { getContentFromHtmlString } from '../../../shared/utilities/html-utility';
import { UserUtility } from '../../../shared/utilities/user-utility';
import { IProjectSearchResult } from '../../projects/shared/models/project-search-result';
import { ICommittee } from '../shared/models/committee';
import { IUser } from '../../../shared/models/user';

@Component({
  selector: 'app-button-export-committee-details',
  templateUrl: './button-export-committee-details.component.html',
  styleUrl: './button-export-committee-details.component.scss'
})
export class ButtonExportCommitteeDetailsComponent implements OnChanges, OnDestroy {

  faDownload = faDownload;

  @Input() committee!: ICommittee;
  @Input() projects: IProjectSearchResult[] = [];


  loading: boolean = true;
  subscriptions: Subscription = new Subscription();

  currentUser: IUser | null = null;
  includeLiasonInformation = false;

  documentSpacer = { text: `\n` };
  documentTitle: string = '';
  documentDefinitions: TDocumentDefinitions | null = null;

  constructor(
    private _authService: AuthService
  ) {
    const userSubscription = this._authService.currentUserSubject.subscribe(user => {
      if(user){
        this.currentUser = user;
      }
    });

    this.subscriptions.add(userSubscription);
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const committee = changes['committee']?.currentValue;
    if (!committee || !this.currentUser) {
      return;
    }

    this.loading = true;
    this.includeLiasonInformation = UserUtility.isInternalUser(this.currentUser) || UserUtility.userIsOnCommittee(this.currentUser, committee.committeeCode);

    this.documentTitle = (`${committee.committeeCode} ${committee.committeeTitle ?? ''}`).trim();
    this.createDocumentDefinition();
  }

  createDocumentDefinition() {
    if (!this.committee)
      return;

    let documentDefinitions: TDocumentDefinitions = {
      header: [
        {
          image: pdfLogoBlob,
          height: 60,
          width: 160,
          absolutePosition: { x: 425, y: 10 }
        }
      ],
      pageSize: 'A4',
      pageMargins: [75, 80, 75, 80],
      defaultStyle: {
        fontSize: 11
      },
      styles: {
        header: {
          bold: true,
        },
        tableHeader: {
          bold: true,
          alignment: 'center'
        },
        link: {
          color: 'blue',
          decoration: 'underline'
        },
        textCenter: {
          alignment: 'center'
        }
      },
      content: [
        { text: `Report Exported: ${format(new Date(), 'dd/MM/yyyy')}.` },
        { ...this.documentSpacer },
        {
          text: [
            { text: 'For up-to-date information, view the Committee details on Connect ' },
            {
              text: 'here',
              link: `${window.location.origin}/#/committees/${this.committee.committeeId}`,
              style: 'link'
            },
            { text: '.' },
          ]
        },
        { ...this.documentSpacer },
        {
          text: `Committee: ${this.committee.committeeId} ${this.committee.committeeTitle ?? ''}`,
          style: {
            fontSize: 22,
            bold: true
          }
        },
        { ...this.documentSpacer }
      ] as Content[]
    }

    this.appendSectorName(documentDefinitions);
    this.appendTermsOfReference(documentDefinitions);
    this.appendLiasonDetails(documentDefinitions);
    this.appendInternationalMirrorCommittees(documentDefinitions);
    this.appendMembershipDetails(documentDefinitions);
    this.appendProjects(documentDefinitions);

    this.documentDefinitions = documentDefinitions;
    this.loading = false;
  }

  appendSectorName(documentDefinitions: TDocumentDefinitions) {
    if (this.committee.sectorDetails.sectorName) {
      (documentDefinitions.content as Content[]).push({ text: 'Sector:', style: 'header' });
      (documentDefinitions.content as Content[]).push({ text: this.committee.sectorDetails.sectorName });
      (documentDefinitions.content as Content[]).push({ ...this.documentSpacer });
    }
  }

  appendTermsOfReference(documentDefinitions: TDocumentDefinitions) {
    let termsOfReferenceArr = getContentFromHtmlString(this.committee.termOfReference);
    if (termsOfReferenceArr) {
      (documentDefinitions.content as Content[]).push({ text: 'Scope (Area of Activity):', style: 'header' });
      documentDefinitions.content = (documentDefinitions.content as Content[]).concat(termsOfReferenceArr);
      (documentDefinitions.content as Content[]).push({ ...this.documentSpacer });
    }
  }

  appendLiasonDetails(documentDefinitions: TDocumentDefinitions) {
    if (this.committee.liaisonDetails && this.includeLiasonInformation) {
      let liason = this.committee.liaisonDetails.filter(x => x.liaisonType.toLowerCase() === 'to');
      if (liason.length > 0) {
        (documentDefinitions.content as Content[]).push({
          text: 'Liaison from this committee to:',
          style: 'header'
        });
        (documentDefinitions.content as Content[]).push({
          table: {
            body: [
              ...liason
                .map(x => {
                  return [
                    { text: x.commiteeCode },
                    { text: x.commiteeTitle }
                  ]
                })
            ],
            widths: ['20%', '*']
          }
        } as any);
        (documentDefinitions.content as Content[]).push({ ...this.documentSpacer });
      }

      liason = this.committee.liaisonDetails.filter(x => x.liaisonType.toLowerCase() === 'from');
      if (liason.length > 0) {
        (documentDefinitions.content as Content[]).push({
          text: 'Liaison to this committee from:',
          style: 'header'
        });
        (documentDefinitions.content as Content[]).push({
          table: {
            body: [
              ...liason
                .map(x => {
                  return [
                    { text: x.commiteeCode },
                    { text: x.commiteeTitle }
                  ]
                })
            ],
            widths: ['20%', '*']
          }
        } as any);
        (documentDefinitions.content as Content[]).push({ ...this.documentSpacer });
      }
    }
  }

  appendInternationalMirrorCommittees(docDefinition: TDocumentDefinitions) {
    if (this.committee.intmirrorcommitees && this.committee.intmirrorcommitees.length > 0) {
      (docDefinition.content as Content[]).push({
        text: 'International Participation:',
        style: 'header'
      });
      (docDefinition.content as Content[]).push({
        table: {
          body: [
            ...this.committee.intmirrorcommitees
              .map(x => {
                return [
                  { text: x.internationalCommitteeName },
                  { text: x.membershipStatus }
                ]
              })
          ],
          widths: ['80%', '*']
        }
      } as any);
      (docDefinition.content as Content[]).push({ ...this.documentSpacer });
    }
  }

  appendMembershipDetails(documentDefinitions: TDocumentDefinitions) {
    if (this.committee.membershipDetails) {
      let nomOrgs = this.committee.membershipDetails.map(x => x.membershipNomOrg).filter(x => (x?.length ?? 0) > 0);
      if (nomOrgs.length > 0) {
        (documentDefinitions.content as Content[]).push({
          text: 'Nominating Organisation:',
          style: 'header'
        });
        (documentDefinitions.content as Content[]).push({
          table: {
            body: [
              ...nomOrgs
                .map(x => {
                  return [
                    { text: x }
                  ]
                })
            ],
            widths: ['*']
          }
        } as any);
        (documentDefinitions.content as Content[]).push({ ...this.documentSpacer });
      }
    }
  }

  appendProjects(documentDefinitions: TDocumentDefinitions) {
    if (this.projects && this.projects.length > 0) {
      (documentDefinitions.content as Content[]).push({
        text: 'Projects:',
        style: 'header'
      });
      (documentDefinitions.content as Content[]).push({
        table: {
          body: [
            [
              { text: 'Designation', style: 'tableHeader' },
              { text: 'Title', style: 'tableHeader' },
              { text: 'Phase', style: 'tableHeader' },
              { text: 'Status', style: 'tableHeader' },
            ],
            ...orderBy(this.projects, ['designation', 'projectTitle'], ['asc', 'asc'])
              .map(x => {
                return [
                  {
                    text: x.designation,
                    link: `${window.location.origin}/#/project/${x.projectId}`,
                    style: 'link'
                  },
                  { text: x.projectTitle },
                  { text: x.phase, style: 'textCenter' },
                  { text: x.status, style: 'textCenter' },
                ]
              })
          ],
          widths: ['25%', '45%', '15%', '*']
        }
      } as any);
      (documentDefinitions.content as Content[]).push({ ...this.documentSpacer });
    }
  }

  export(e: MouseEvent) {
    e.stopPropagation();

    if (!this.documentDefinitions) {
      return;
    }

    pdfMake
      .createPdf(this.documentDefinitions)
      .download(`${this.documentTitle}.pdf`);
  }
}
